import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useMemo, useState } from 'react';
import { styled, theme } from '../../config/Theme';
import UserGroup from '../../models/UserGroup';
import IconButton from '../Button/IconButton';
import Link from '../Link/Link';
import ActionContainer from '../Spacing/ActionContainer';
import Flex from '../Spacing/Flex';
import RowWrapper from '../Spacing/RowWrapper';
import { Ellipsized, I, Span } from '../Typography';
import Card from './Card';
import UserRole from '../../enums/UserRole';
import User from '../../models/User';
import useAPI from '../../hooks/useAPI';

export type UserGroupCardProps = {
  group?: UserGroup;
  removeGroup: (id?: string) => void;
  me?: User;
} & React.HTMLAttributes<HTMLDivElement>;

const UserGroupCardStyled = styled(Card)`
  padding: 0;
  width: 100%;
  margin-top: ${(props) => props.theme.space.medium};
  margin-right: ${(props) => props.theme.space.small};
`;

const CardHeader = styled.div`
  width: calc(100% - ${(props) => props.theme.space.small} * 2);
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const CardBodyStyled = styled.div`
  width: calc(100% - ${(props) => props.theme.space.small} * 2);
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  background-color: ${(props) => props.theme.color.gray1};
`;

const UserBlock = styled.div`
  display: inline-block;
  margin-right: ${(props) => props.theme.space.tiny};
  margin-top: ${(props) => props.theme.space.half};
  max-width: calc(50% - ${(props) => props.theme.space.tiny});
`;

const OverflowBadge = styled.div`
  display: flex;
  width: ${(props) => props.theme.space.small};
  height: ${(props) => props.theme.space.small};
  background-color: ${(props) => props.theme.color.secondary};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.white};
  cursor: pointer;
`;

const OverflowWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template: 1fr / 94% 1fr;
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: ${(props) => props.theme.space.small};
`;

class GroupMember {
  constructor(public email?: string, public type?: 'user' | 'address') {}
}

const CardBody = (props: UserGroupCardProps) => {
  // Sorting of email is expensive
  const members = useMemo(() => {
    let members: GroupMember[] = [];
    if (props.group?.users) {
      members = members.concat(props.group.users.map((u) => new GroupMember(u.email, 'user')));
    }
    if (props.group?.addresses) {
      members = members.concat(props.group.addresses.map((a) => new GroupMember(a, 'address')));
    }

    return members.sort((a, b) => {
      if (!a.email || !b.email) return 0;
      else return a.email?.localeCompare(b.email, 'en', { numeric: true });
    });
  }, [props.group?.users, props.group?.addresses]);

  const [expanded, setExpanded] = useState<boolean>(() => !(members && members.length > 2));

  if (!members || members.length <= 0) {
    return (
      <CardBodyStyled>
        <I>Nincsenek felvett felhasználók</I>
      </CardBodyStyled>
    );
  } else if (!expanded) {
    return (
      <CardBodyStyled>
        <OverflowWrapper>
          <RowWrapper justify='flex-start'>
            {members.slice(0, 2).map((member) => {
              return (
                <UserBlock key={member.email}>
                  {member.type == 'address' ? (
                    <Ellipsized style={{ color: theme.color.gray3 }}>{member.email}</Ellipsized>
                  ) : (
                    <Ellipsized>{member.email}</Ellipsized>
                  )}
                </UserBlock>
              );
            })}
          </RowWrapper>
          <RowWrapper justify='flex-end'>
            <OverflowBadge onClick={(e) => setExpanded(true)}>
              <Span>+{members.length - 2}</Span>
            </OverflowBadge>
          </RowWrapper>
        </OverflowWrapper>
      </CardBodyStyled>
    );
  } else {
    return (
      <CardBodyStyled>
        {members.map((member) => {
          return (
            <UserBlock key={member.email}>
              <Flex row align='center'>
                {member.type == 'address' ? (
                  <Ellipsized style={{ color: theme.color.gray3 }}>{member.email}</Ellipsized>
                ) : (
                  <Ellipsized>{member.email}</Ellipsized>
                )}
              </Flex>
            </UserBlock>
          );
        })}
        {members.length > 2 && (
          <CardFooter>
            <Link onClick={() => setExpanded(false)}>Bezárás</Link>
          </CardFooter>
        )}
      </CardBodyStyled>
    );
  }
};

const UserGroupCard = (props: UserGroupCardProps): JSX.Element => {
  const [getGroupResponse, getGroup] = useAPI(`/groups/${props.group?.id}`, { manual: true });

  const handleGroupEdit = async () => {
    const group = await getGroup();
    if (group && group.data) {
      location.href = `/groups/${props.group?.id}`;
    }
  };

  return (
    <UserGroupCardStyled>
      <CardHeader>
        <RowWrapper justify='space-between'>
          <ActionContainer>{props.group?.name || 'Névtelen csoport'}</ActionContainer>
          <ActionContainer alignment='end'>
            {(props.me?.id === props.group?.creatorId ||
              props.me?.role === UserRole.ADMIN ||
              props.me?.role === UserRole.SUPERADMIN) && (
              <>
                <IconButton
                  icon={faEdit}
                  color={theme.color.white}
                  hoverColor={theme.color.gray2}
                  onClick={handleGroupEdit}
                />
                <IconButton
                  icon={faTrash}
                  color={theme.color.white}
                  hoverColor={theme.color.gray2}
                  onClick={() => props.removeGroup(props.group?.id)}
                />
              </>
            )}
          </ActionContainer>
        </RowWrapper>
      </CardHeader>

      <CardBody {...props} />
    </UserGroupCardStyled>
  );
};

export default UserGroupCard;
