import React from 'react';
import { CreativeTypeNames } from '../../enums/CreativeType';
import UserEventAction from '../../enums/UserEventAction';
import * as LogParams from '../../models/LogParams';
import UserEvent from '../../models/UserEvent';
import { Ellipsized, Log } from '../Typography';

const parseNameLogParams = (param: string) => {
  switch (param) {
    case 'active':
      return 'Aktív';
    case 'inactive':
      return 'Inaktív';
    case 'scheduled':
      return 'Előkészítve';
    case 'inprogress':
      return 'Folyamatban';
    case 'closed':
      return 'Lezárva';
    default:
      return param;
  }
};

const ActionLogCell = (props: { event: UserEvent }): JSX.Element => {
  if (!props.event.action && props.event.action !== 0) return <>-</>;

  const actionDescription = Object.values(UserEventAction)[props.event.action];

  let detailsString = '';

  if (props.event.details) {
    let params = null;

    switch (Object.values(UserEventAction)[props.event.action]) {
      case UserEventAction.GROUP_RENAMED:
      case UserEventAction.MEDIUM_RENAMED:
      case UserEventAction.CAMPAIGN_RENAMED:
      case UserEventAction.MEDIUM_STATUS_CHANGED:
      case UserEventAction.CAMPAIGN_STATUS_CHANGED:
        params = JSON.parse(props.event.details) as LogParams.NameLogParams;
        detailsString = `${parseNameLogParams(params.from)} -> ${parseNameLogParams(params.to)}`;
        break;

      case UserEventAction.BANNER_UPLOADED:
        // TODO: Fix logs
        detailsString = 'Új fájl';
        break;

      case UserEventAction.CREATIVE_PARAMS_ADDED:
        detailsString = `${props.event.details || 'Üres paraméter'}`;
        break;

      case UserEventAction.URL_ADDED:
        params = JSON.parse(props.event.details) as LogParams.UrlLogParams;
        detailsString = params.newUrl || 'Hibás URL';
        break;

      case UserEventAction.URL_REMOVED:
        params = JSON.parse(props.event.details) as LogParams.UrlLogParams;
        detailsString = params.oldUrl || 'Hibás URL';
        break;

      case UserEventAction.DATE_CHANGED:
        params = JSON.parse(props.event.details) as LogParams.DateLogParams;
        if (params.newRanges) {
          detailsString = `${params.oldRanges ? params.oldRanges + ' -> ' : ''}${params.newRanges}`;
        } else {
          detailsString = 'Hibás dátum';
        }
        break;

      case UserEventAction.GROUP_USER_ADDED:
        params = JSON.parse(props.event.details) as LogParams.GroupLogParams;
        detailsString = params.added || 'Hibás email cím';
        break;

      case UserEventAction.GROUP_USER_REMOVED:
        params = JSON.parse(props.event.details) as LogParams.GroupLogParams;
        detailsString = params.removed || 'Hibás email cím';
        break;

      case UserEventAction.CREATIVE_PLATFORM_CHANGED:
        params = JSON.parse(props.event.details) as LogParams.PlatformLogParams;
        detailsString = `${params.oldPlatform} -> ${params.newPlatform}`;
        break;

      case UserEventAction.RESOLUTION_CHANGED:
        params = JSON.parse(props.event.details) as LogParams.ResolutionLogParams;
        detailsString = `${params.oldWidth}x${params.oldHeight} -> ${params.newWidth}x${params.newHeight}`;
        break;

      case UserEventAction.CREATIVE_TYPE_CHANGED:
        params = JSON.parse(props.event.details) as LogParams.CreativeTypeLogParams;
        detailsString = `${CreativeTypeNames.get(params.oldType)} -> ${CreativeTypeNames.get(params.newType)}`;
    }
  }

  return (
    <Log title={actionDescription + '\n' + detailsString}>
      {Object.values(UserEventAction)[props.event.action]}
      {detailsString && (
        <>
          <br />
          <Ellipsized>{detailsString}</Ellipsized>
        </>
      )}
    </Log>
  );
};

export default ActionLogCell;
