import React, { FC, useEffect, useMemo, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../config/Theme';
import Toaster from '../utils/Toaster';

import UserRole from '../enums/UserRole';
import Campaign from '../models/Campaign';
import User from '../models/User';
import { DuplicateBodyType } from '../enums/Duplication';
import Actions from '../enums/DropdownActions';
import { DropdownOption } from '../components/Editable/Dropdown';

import useAPI from '../hooks/useAPI';
import useCampaignColumns from '../hooks/useCampaignColumns';
import useModal from '../hooks/useModal';
import { useMatcherModalContext } from '../components/Provider/MatcherErrorModalProvider';

import MatcherErrorsModal from '../components/Layout/MatcherErrorsModal';
import MainButton from '../components/Button/MainButton';
import DeleteModalContent from '../components/Cards/DeleteModalContent';
import Modal from '../components/Cards/Modal';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import TableWrapper from '../components/Layout/TableWrapper';
import Title from '../components/Layout/Title';
import ActionContainer from '../components/Spacing/ActionContainer';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import FilterRow from '../components/Table/FilterRow';
import NewTable from '../components/Table/NewTable';
import PaginationContainer from '../components/Table/PaginationContainer';
import CampaignDuplicationModal from '../components/Layout/CampaignDuplicationModal';
import { H1, H2 } from '../components/Typography';
import CrudModal from '../components/Layout/CrudModalContext';
import CampaignModal from '../components/Layout/CampaignModal';
import ScreenshotDownloadModal from '../components/Layout/ScreenshotDownloadModal';

const OverviewPage: FC = () => {
  const [, createCampaign] = useAPI({ url: '/campaigns', method: 'POST' }, { manual: true });
  const [, duplicateCampaign] = useAPI({ url: '/duplicate', method: 'POST' }, { manual: true });
  const [, getCampaigns] = useAPI({ url: '/campaigns', method: 'GET' }, { manual: true });
  const [, getMediums] = useAPI({ url: '/mediums', method: 'GET' }, { manual: true });
  const [, getMe] = useAPI({ url: '/me', method: 'GET' }, { manual: true });
  const [, deleteCampaign] = useAPI({ method: 'DELETE' }, { manual: true });

  const [isModalOpen, setModalOpen] = useModal();
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useModal();
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const [me, setMe] = useState<User>();
  const [isCampainModalOpen, setIsCampaignModalOpen] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [campaignToDuplicate, setCampaignToDuplicate] = useState<Campaign>({});

  const [totalCount, setTotalCount] = useState<number>(0);
  const [toDelete, setToDelete] = useState<Campaign>();

  const [filterCampaignWithErrors, setFilterCampaignWithErrors] = useState<boolean>(false);
  const [showEveryCampaign, setShowEveryCampaign] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const [selectedCampaignId, setSelectedCampaignId] = useState<string | undefined>();
  const { setModalTitle, setIsMatcherModalOpen, isMatcherModalOpen, setMatcherErrorsType } = useMatcherModalContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageCount = useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const fetchCampaigns = async (
    offset: number,
    limit: number,
    nameFilterValue: string,
    showEveryCampaign: boolean,
    onlyErrors: boolean,
    sortBy?: {
      property: string;
      direction: string;
    }
  ) => {
    const response = await getCampaigns({
      params: { offset: offset * limit, limit, nameFilterValue, showEveryCampaign, onlyErrors, sortBy },
    });

    if (!response || !response.data) return;
    setCampaigns(response.data[0]);
    setTotalCount(response.data[1]);
  };

  const newCampaign = async () => {
    setIsCampaignModalOpen(true);
  };

  const handleDelete = async () => {
    const deleteResponse = await deleteCampaign({ url: `/campaigns/${toDelete?.id}` });

    if (deleteResponse.status === 204) {
      const remainingCampaigns = campaigns.filter((campaign) => campaign.id !== toDelete?.id);

      setModalOpen(false);
      setToDelete(undefined);

      if (remainingCampaigns.length === 0) {
        setPageIndex((prev) => --prev);
      } else {
        fetchCampaigns(pageIndex, pageSize, searchValue, showEveryCampaign, filterCampaignWithErrors);
      }

      Toaster.success('Sikeres törlés!');
    } else {
      Toaster.error('Sikertelen törlés!');
    }
  };

  const handleCampaignDuplication = async (duplicationRequestBody: DuplicateBodyType) => {
    setIsLoading(true);
    const response = await duplicateCampaign({ data: duplicationRequestBody });

    setIsLoading(false);

    if (response.status !== StatusCodes.OK) {
      Toaster.error('Sikertelen duplikálás!');
      return;
    }

    Toaster.success('Sikeres duplikálás!');
    fetchCampaigns(pageIndex, pageSize, searchValue, showEveryCampaign, filterCampaignWithErrors);
    setIsDuplicationModalOpen(false);
  };

  const handleSorting = (property: string, direction: 'ASC' | 'DESC') => {
    fetchCampaigns(pageIndex, pageSize, searchValue, showEveryCampaign, filterCampaignWithErrors, {
      property,
      direction,
    });
  };

  const handleSelectedDropdownOption = (option: DropdownOption<Actions>, campaign: Campaign) => {
    switch (option.value) {
      case Actions.DOWNLOAD:
        setSelectedCampaignId(campaign.id);
        setIsDownloadModalOpen(true);
        break;
      case Actions.DUPLICATE:
        setCampaignToDuplicate(campaign);
        setIsDuplicationModalOpen(true);
        break;

      case Actions.DELETE:
        setModalOpen(true);
        setToDelete(campaign);
        break;
    }
  };

  const filterErrorCampaigns = (val: boolean) => {
    setFilterCampaignWithErrors(val);
  };

  const filterCampaignsByStatus = (val: boolean) => {
    setShowEveryCampaign(val);
  };

  const filterCampaignsByName = (value: string) => {
    if (typeof value !== 'undefined') {
      setSearchValue(value);
    }
  };

  const handleSetMe = async () => {
    const response = await getMe();
    setMe(response.data);
  };

  const openMatcherErrorModal = async (campaign: Campaign) => {
    const avTypedMediumsOfCampaign = await getMediums({
      url: `/mediums?campaignId=${campaign.id}`,
      params: {
        offset: 0,
        limit: 1,
        type: 'av',
      },
    });
    const gotAvMediums = avTypedMediumsOfCampaign.data[0] && avTypedMediumsOfCampaign.data[0].length;
    const matcherErrorType = gotAvMediums ? 'av' : 'time';

    setMatcherErrorsType(matcherErrorType || 'time');
    setModalTitle('Hibalista / ' + campaign.name);
    setSelectedCampaignId(campaign.id);
    setIsMatcherModalOpen(true);
  };

  useEffect(() => {
    handleSetMe();
  }, []);

  // Handle page change
  useEffect(() => {
    fetchCampaigns(pageIndex, pageSize, searchValue, showEveryCampaign, filterCampaignWithErrors);
  }, [pageIndex]);

  // Handle filter and page size change
  useEffect(() => {
    setPageIndex(0);
    fetchCampaigns(0, pageSize, searchValue, showEveryCampaign, filterCampaignWithErrors);
  }, [searchValue, showEveryCampaign, filterCampaignWithErrors, pageSize]);

  const columns = useCampaignColumns({
    openMatcherErrorModal,
    handleSorting,
    handleSelectedDropdownOption,
  });

  return (
    <>
      <CrudModal
        isOpen={isCampainModalOpen}
        toggle={() => {
          setIsCampaignModalOpen((prev) => !prev);
        }}
        headerText='Új kampány'
      >
        <CampaignModal />
      </CrudModal>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header withLogs={me && (me.role === UserRole.ADMIN || me.role === UserRole.SUPERADMIN)} />

        <ContentWrapper>
          <NavHeader />

          <Title>
            <H1>Áttekintés</H1>
            <ActionContainer alignment='end'>
              <MainButton secondary icon={faUsers} onClick={(e) => (location.href = '/groups')}>
                Felhasználói csoportok
              </MainButton>
              <MainButton icon={faPlus} onClick={() => newCampaign()}>
                Új kampány
              </MainButton>
            </ActionContainer>
          </Title>

          <TableWrapper>
            <NewTable<Campaign>
              columns={columns}
              data={campaigns}
              onRowClick={(c: Campaign) => (location.href = `/campaigns/${c.id}`)}
              idKey={'id'}
              filterRow={
                <FilterRow
                  onlyShowTheOnesWithError={filterCampaignWithErrors}
                  showEveryCampaign={showEveryCampaign}
                  handleCheck={filterErrorCampaigns}
                  handleSearch={filterCampaignsByName}
                  handleCampaignFilterByStatus={filterCampaignsByStatus}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  name='kampány'
                  setPageSize={(size: number) => {
                    setPageSize(size);
                    setPageIndex(0);
                  }}
                />
              }
            />
          </TableWrapper>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
        {pageCount > 1 && (
          <PaginationContainer pageCount={pageCount} setPageIndex={setPageIndex} pageIndex={pageIndex} />
        )}
      </Footer>

      {isDuplicationModalOpen && (
        <CampaignDuplicationModal
          toggleModal={setIsDuplicationModalOpen}
          campaign={campaignToDuplicate}
          onSave={handleCampaignDuplication}
          isLoading={isLoading}
        />
      )}

      {isDownloadModalOpen && (
        <CrudModal
          isOpen={isDownloadModalOpen}
          toggle={() => {
            setIsDownloadModalOpen((prev) => !prev);
          }}
          headerText='Screenshotok letöltése'
        >
          <ScreenshotDownloadModal campaignId={selectedCampaignId} />
        </CrudModal>
      )}

      {isMatcherModalOpen && selectedCampaignId && <MatcherErrorsModal campaignId={selectedCampaignId} />}
      <Modal isOpen={isModalOpen} toggle={setModalOpen}>
        <DeleteModalContent onDelete={handleDelete} onCancel={() => setModalOpen(false)}>
          <H2>Biztosan törölni szeretné a kampányt?</H2>
        </DeleteModalContent>
      </Modal>
    </>
  );
};

export default OverviewPage;
