import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { styled } from '../config/Theme';

const Container = styled(ToastContainer)`
  .Toastify__toast {
    font-size: ${(props) => props.theme.fontsize.small};
    font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    background-color: ${(props) => props.theme.color.gray1};
    color: ${(props) => props.theme.color.gray3};
    border-radius: 5px;
  }
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.color.danger};
    color: ${(props) => props.theme.color.white};
  }
  .Toastify__toast--warning {
    background-color: ${(props) => props.theme.color.warning};
    color: ${(props) => props.theme.color.white};
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.color.success};
    color: ${(props) => props.theme.color.white};
  }
`;

const StyledToastContainer = () => {
  return (
    <Container
      position='top-center'
      autoClose={2000}
      hideProgressBar
      pauseOnFocusLoss
      pauseOnHover
      transition={Slide}
    />
  );
};

export default StyledToastContainer;
