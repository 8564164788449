import React, { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { styled, theme } from '../../config/Theme';
import Link from '../Link/Link';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';

const DropdownStyled = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: ${(props) => props.theme.space.small};
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  background-color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.tiny};
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 2px solid ${(props) => props.theme.color.gray2};
`;

type DropDownProps<T> = { options: DropdownOption<T>[]; onSelected: (o: DropdownOption<T>) => void };

export type DropdownOption<T> = {
  value: T;
  label: string;
  selected?: boolean;
  divider?: boolean;
  icon?: ReactNode;
  textColor?: string;
};

const Dropdown = function <T>(props: HTMLAttributes<HTMLDivElement> & DropDownProps<T>): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    if (isOpen) setOpen(false);
  });

  return (
    <Flex
      column
      justify='center'
      align='center'
      style={{ position: 'relative' }}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!isOpen);
      }}
    >
      {props.children}
      <DropdownStyled ref={dropdownRef} open={isOpen}>
        <Flex column justify='flex-start'>
          {props.options.map((option, i) => (
            <div key={i} style={{ width: '100%' }}>
              <Link
                style={{ margin: `${theme.space.half} 0`, display: 'flex' }}
                onClick={() => props.onSelected(option)}
              >
                {option.icon && <div style={{ marginRight: '0.5rem', minWidth: '20px' }}>{option.icon}</div>}
                {option.label && (
                  <Span color={option.textColor ? option.textColor : theme.color.primary}>{option.label}</Span>
                )}
              </Link>

              {option.divider && <Divider />}
            </div>
          ))}
        </Flex>
      </DropdownStyled>
    </Flex>
  );
};

export default Dropdown;
