import { faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import React, { ReactPortal, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { styled, theme } from '../../config/Theme';
import CreativeType from '../../enums/CreativeType';
import Platform, { PlatformNames } from '../../enums/Platform';
import useAPI from '../../hooks/useAPI';
import { useGetFile } from '../../hooks/useUpload';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import SimpleDropdown, { SimpleDropdownOption } from '../Editable/SimpleDropdown';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';
import RemoteImage from './RemoteImage';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { ErrorMsg } from '../Form/Form';
import Daterange from '../../models/Daterange';

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: 50%;
  min-width: 600px;
  max-width: 90%;
  min-height: 80vh;
  max-height: 90vh;
  position: fixed;
  z-index: 1060;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
`;

const ModalBody = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100px;
  padding: ${(props) => props.theme.space.small};
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
`;

const ModalFooter = styled.div`
  margin: ${(props) => props.theme.space.small};

  > div {
    padding-top: ${(props) => props.theme.space.small};
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.color.gray3};
    justify-content: flex-end;

    button {
      margin-left: ${(props) => props.theme.space.small};
    }
  }
`;

const Row = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 5.5rem;
  margin-bottom: 20px;
  gap: 1.5rem;
`;

const Thumbnail = styled.div`
  width: 10rem;
  background-color: ${(props) => props.theme.color.gray2};
  padding: ${(props) => props.theme.space.half};
  border-radius: 5px;
`;

export const DropdownWrapper = styled.div`
  flex: 1;
`;

const ImageWrapper = styled.div`
  height: 3rem;
  width: 10rem;
`;

const ImageName = styled.p`
  font-size: ${(props) => props.theme.fontsize.tiny};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-top: ${(props) => props.theme.space.half};
`;

type IconWrapperProps = {
  visible: boolean;
};
const IconWrapper = styled.div<IconWrapperProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const UploadRowContainer = styled.div`
  width: 100%;
`;

type FileItem = {
  file: File;
  creativeId: string;
  uploading: boolean;
  uploadProgress: number;
  width?: number;
  height?: number;
};

type UploadRowProps = {
  item: FileItem;
  creatives: Creative[];
  platform: Platform | undefined;
  errorMessage?: string;
  onImageLoaded: (width: number, height: number) => void;
  onChangeCreative: (creativeId: string) => void;
  onChangePlatform: (platformId: string) => void;
  onDelete: () => void;
};
const UploadRow = (props: UploadRowProps): JSX.Element => {
  const [options, setOptions] = useState<SimpleDropdownOption[]>([]);

  useEffect(() => {
    const newOptions: SimpleDropdownOption[] = [];
    let hasDefault = false;
    props.creatives.forEach((c) => {
      if (c.width !== props.item.width || c.height != props.item.height) return;
      const option: SimpleDropdownOption = {
        id: c.id || '-',
        default: c.id === props.item.creativeId,
        label: `${c.width}x${c.height} / ${c.type} / 
                ${c.start ? moment(c.start).format('YYYY.MM.DD.') + ' - ' + moment(c.end).format('YYYY.MM.DD.') : ''}`,
      };
      hasDefault = hasDefault || c.id === props.item.creativeId;
      newOptions.push(option);
    });
    newOptions.push({
      id: 'new_creative',
      label: 'Új kreatív',
      default: !hasDefault,
    });

    setOptions(newOptions);
  }, [props]);

  const platformOptions = [
    ...Object.values(Platform).map((value: Platform) => {
      return {
        label: PlatformNames.get(value) || undefined,
        id: value,
        default: value === props.platform,
      };
    }),
  ] as SimpleDropdownOption[];

  return (
    <Row>
      <Thumbnail>
        <ImageWrapper>
          <RemoteImage alt={props.item.file.name} file={props.item.file} onGotSize={props.onImageLoaded} />
        </ImageWrapper>
        <ImageName>{props.item.file.name}</ImageName>
      </Thumbnail>
      <DropdownWrapper>
        <SimpleDropdown
          options={options}
          onChange={(e: SimpleDropdownOption) => {
            props.onChangeCreative(e.id);
          }}
        />
        <div style={{ marginTop: '15px' }}>
          <SimpleDropdown
            placeholder='Válassz platformot'
            options={platformOptions}
            onChange={(e: SimpleDropdownOption) => {
              props.onChangePlatform(e.id);
            }}
          />
          <ErrorMsg>{props.errorMessage}</ErrorMsg>
        </div>
      </DropdownWrapper>
      <Progress percent={props.item.uploadProgress}></Progress>
      <IconWrapper visible={!props.item.uploading}>
        <IconButton
          size='lg'
          icon={faTrashAlt}
          color={theme.color.gray3}
          hoverColor={theme.color.danger}
          onClick={() => !props.item.uploading && props.onDelete()}
        />
      </IconWrapper>
    </Row>
  );
};

export type MissingMetaTagProps = {
  visible: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const MissingMetaTagMessageComponent = styled.div<MissingMetaTagProps>`
  color: #f44336;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  margin: -10px 0 10px 0;
`;

export const MissingMetaTagMessage = (props: MissingMetaTagProps): JSX.Element => {
  return <MissingMetaTagMessageComponent visible={props.visible}>{props.children}</MissingMetaTagMessageComponent>;
};

type ProgressProps = {
  percent: number;
};
const ProgressComponent = styled.div<ProgressProps>`
  width: 100%;
  max-width: 10rem;
  background-color: ${(props) => props.theme.color.gray2};
  padding: 0 ${(props) => props.theme.space.half};
  height: 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;

  span {
    flex: 0;
    font-size: 0.8rem;
    padding-right: ${(props) => props.theme.space.half};
  }

  > div {
    flex: 1;
    border-radius: 5px;
    height: 5px;
    width: 100%;
    background-color: ${(props) => props.theme.color.white};

    > div {
      background-color: ${(props) => props.theme.color.gray3};
      border-radius: 5px;
      height: 5px;
      width: ${(props) => props.percent}%;
    }
  }
`;

const Progress = (props: ProgressProps): JSX.Element => {
  return (
    <ProgressComponent percent={props.percent}>
      <span>{props.percent}%</span>
      <div>
        <div></div>
      </div>
    </ProgressComponent>
  );
};

export type BulkUploadModalProps = {
  files: File[];
  cancel: () => void;
  onFinish: () => void;
  creatives: Creative[];
} & React.HTMLAttributes<HTMLDivElement>;

type MetaData = {
  width: number;
  height: number;
  duration: number;
};

type ItemsWithMetaData = {
  [key: string]: MetaData;
};

type MetaDataResponseType = {
  fileName: string;
  width: number;
  height: number;
  duration: number;
};

const schema = z.object({
  items: z.array(
    z.object({
      platform: z.nativeEnum(Platform, { required_error: 'Kötelező kiválasztani' }),
    })
  ),
});

export type CreativeValidateValues = z.infer<typeof schema>;

type CreativeFormValues = { items: { platform: Platform | undefined }[] };

const BulkUploadModal = (props: BulkUploadModalProps): ReactPortal | null => {
  const { mediumId } = useParams<{ mediumId: string }>();

  const [, uploadFile] = useAPI<CreativeVariation>({ method: 'POST' }, { manual: true });

  const [, postVariation] = useAPI<CreativeVariation>({ method: 'POST' }, { manual: true });

  const [, postCreative] = useAPI<Creative>({ method: 'POST' }, { manual: true });

  const [, checkCreativeMetadata] = useAPI<Creative>({ method: 'POST' }, { manual: true });

  const [finished, setFinished] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const form = useForm<CreativeFormValues>({
    resolver: zodResolver(schema),
  });

  const formFieldArray = useFieldArray({
    control: form.control,
    name: 'items',
  });

  const {
    formState: { errors, isValid },
  } = form;

  const addItems = (files: File[]) => {
    const names = items.map((item) => item.file.name);
    const filteredFiles = files.filter((f) => !names.includes(f.name));

    const newItems: FileItem[] = [...items];

    filteredFiles.forEach((file: File) => {
      const newItem: FileItem = {
        file,
        creativeId: 'new_creative',
        uploading: false,
        uploadProgress: 0,
      };

      newItems.push(newItem);
      formFieldArray.append({ platform: undefined });
    });

    setItems(newItems);
  };

  const addFiles = useGetFile({
    multiple: true,
    accept: ['.jpg', '.png', '.jpeg', '.mp4', '.html', '.zip'],
    callback: (newFiles: File[]) => {
      addItems(newFiles);
      checkNotImageMetaDatas(newFiles);
    },
  });

  const [items, setItems] = useState<FileItem[]>([]);
  const [wrongMetaItems, setWrongMetaItems] = useState<string[]>([]);
  const [metaDatas, setMetadatas] = useState<ItemsWithMetaData | null>(null);

  useEffect(() => {
    addItems(props.files);

    if (props.files.length > 0) {
      checkNotImageMetaDatas(props.files);
    }
  }, [props.files]);

  const startUpload = async () => {
    setUploading(true);
    let index = 0;
    for (const item of items) {
      await uploadItem(item, index);
      index++;
    }
    setFinished(true);
    props.onFinish();
  };

  const uploadProgressChange = (item: FileItem, progress: number) => {
    const newItems: FileItem[] = [...items];
    const i = newItems.find((i) => i.file.name === item.file.name);
    if (i) {
      i.uploading = true;
      i.uploadProgress = progress;
    }

    setItems(newItems);
  };

  const uploadItem = async (item: FileItem, index: number) => {
    let creativeId = item.creativeId;
    const fileType = item.file.type.split('/')[1];
    let typeOfCreative = CreativeType.STATIC;

    if (fileType === 'zip' || fileType === 'html') {
      typeOfCreative = CreativeType.DYNAMIC;
    } else if (fileType === 'mp4') {
      typeOfCreative = CreativeType.VIDEO;
    }

    if (item.creativeId === 'new_creative') {
      let width, height, duration;

      if (metaDatas && metaDatas[item.file.name]) {
        width = metaDatas[item.file.name].width;
        height = metaDatas[item.file.name].height;
        duration = metaDatas[item.file.name].duration;
      }

      const creative = new Creative();
      creative.mediumId = mediumId;
      creative.type = typeOfCreative;
      creative.platform = formFieldArray.fields[index].platform;
      creative.width = item.width ? item.width : width;
      creative.height = item.height ? item.height : height;
      creative.duration = duration;

      const response = await postCreative({
        url: `/creatives`,
        data: creative,
      });
      if (response && response.data.id) {
        creativeId = response.data.id;
      }
    }

    const variation = new CreativeVariation();
    const existingCreative = props.creatives.find((c) => c.id === creativeId);

    if (existingCreative && existingCreative.variations && existingCreative.variations[0]) {
      variation.dateRanges = existingCreative.variations[0].dateRanges?.map(
        (dt) => new Daterange(undefined, moment(dt.start), moment(dt.end))
      );
      variation.start = existingCreative.variations[0].start;
      variation.end = existingCreative.variations[0].end;
    }
    const response = await postVariation({
      url: `/creatives/${creativeId}/variations`,
      data: variation,
    });

    const data = new FormData();
    data.append('file', item.file, item.file.name);

    const uploadResponse = await uploadFile({
      url: `/creatives/${creativeId}/variations/${response.data.id}/upload`,
      onUploadProgress: (e) => {
        uploadProgressChange(item, Math.ceil(e.loaded / (e.total / 100)));
      },
      data,
    });
  };

  const onImageLoaded = (item: FileItem, width: number, height: number) => {
    const newItems: FileItem[] = [...items];
    const i = newItems.find((i) => i.file.name === item.file.name);
    if (i) {
      i.width = width;
      i.height = height;
      i.uploadProgress = 0;

      const creative = props.creatives.find((c) => c.width === i.width && c.height === i.height);
      if (creative) {
        i.creativeId = creative.id ?? 'new_creative';
      }
    }

    setItems(newItems);
  };

  const onChangeCreative = (item: FileItem, creativeId: string) => {
    const newItems: FileItem[] = [...items];
    const i = newItems.find((i) => i.file.name === item.file.name);
    if (i) {
      i.creativeId = creativeId;
    }

    setItems(newItems);
  };

  const sendMetaDataChecking = async (item: FileItem) => {
    const data = new FormData();
    data.append('file', item.file, item.file.name);

    const checkResponse = await checkCreativeMetadata({
      url: `/meta`,
      data: data,
    });

    return checkResponse;
  };

  const checkNotImageMetaDatas = async (files: File[]) => {
    const names = items
      .filter(
        (item) =>
          item.file.type.split('/')[1] === 'mp4' ||
          item.file.type.split('/')[1] === 'html' ||
          item.file.type.split('/')[1] === 'zip'
      )
      .map((item) => item.file.name);
    const filteredFiles = files.filter((f) => !names.includes(f.name));
    let newNotImageItems = [...items];

    newNotImageItems = newNotImageItems.filter((item) => {
      const fileType = item.file.type.split('/')[1];

      return fileType === 'mp4' || fileType === 'zip' || fileType === 'html';
    });

    const notImageFiles = filteredFiles.filter((file) => {
      const fileType = file.type.split('/')[1];

      return fileType === 'zip' || fileType === 'html' || fileType === 'mp4';
    });

    notImageFiles.forEach((file: File) => {
      const newItem: FileItem = {
        file,
        creativeId: 'new_creative',
        uploading: false,
        uploadProgress: 0,
      };

      newNotImageItems.push(newItem);
    });

    if (newNotImageItems.length > 0) {
      const responseWrongItems: string[] = [];

      const meta: ItemsWithMetaData = {};
      for (const item of newNotImageItems) {
        const response = await sendMetaDataChecking(item);

        if (!response.data) {
          responseWrongItems.push(item.file.name);
        } else if (response.data) {
          const data = response.data as MetaDataResponseType;

          meta[data.fileName] = {
            width: data.width,
            height: data.height,
            duration: data.duration,
          };
        }
      }
      setMetadatas(meta);

      setWrongMetaItems(responseWrongItems);
    }
  };

  const onDelete = (item: FileItem) => {
    const removeId = items.findIndex((it) => it.file.name === item.file.name);
    if (removeId) formFieldArray.remove(removeId);
    setItems(items.filter((i) => i.file.name !== item.file.name));
    setWrongMetaItems(wrongMetaItems.filter((i) => i !== item.file.name));
  };

  const closeModal = () => {
    props.cancel();
    setItems([]);
    setUploading(false);
    formFieldArray.remove();
  };

  return items.length > 0
    ? createPortal(
        <ModalWrapper>
          <ModalOverlay onClick={closeModal} />
          <ModalStyled className='modal'>
            <ModalHeader>
              <Span color={theme.color.gray3}>{items.length} feltöltendő fájl</Span>
              <IconButton
                size='lg'
                icon={faTimesCircle}
                color={theme.color.black}
                hoverColor={theme.color.gray3}
                onClick={closeModal}
              />
            </ModalHeader>

            <form
              onSubmit={form.handleSubmit((e) => {
                startUpload();
              })}
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ModalBody>
                {items.map((item: FileItem, idx: number) => (
                  <UploadRowContainer key={item.file.name}>
                    <UploadRow
                      errorMessage={errors.items?.[idx]?.platform?.message}
                      key={item.file.name}
                      item={item}
                      creatives={props.creatives}
                      platform={formFieldArray.fields[idx].platform}
                      onImageLoaded={(width, height) => onImageLoaded(item, width, height)}
                      onChangeCreative={(creativeId: string) => {
                        onChangeCreative(item, creativeId);
                      }}
                      onChangePlatform={(platformId: string) => {
                        formFieldArray.update(idx, { platform: platformId as Platform });
                        form.trigger('items');
                      }}
                      onDelete={() => {
                        onDelete(item);
                      }}
                    />
                    <MissingMetaTagMessage visible={wrongMetaItems.includes(item.file.name)}>
                      A kreatív nem tartalmazza a &apos;banner-info&apos; információt!
                    </MissingMetaTagMessage>
                  </UploadRowContainer>
                ))}
              </ModalBody>
              <ModalFooter>
                <div>
                  {!uploading && (
                    <MainButton
                      type='button'
                      bgColor={theme.color.gray3}
                      hoverColor={theme.color.secondary}
                      onClick={addFiles}
                    >
                      Új kreatív feltöltése
                    </MainButton>
                  )}
                  {!uploading && (
                    <MainButton disabled={wrongMetaItems.length > 0 || !isValid} variation='create'>
                      Mentés
                    </MainButton>
                  )}
                  {uploading && (
                    <MainButton type='button' disabled={!finished} onClick={closeModal}>
                      Befejezés
                    </MainButton>
                  )}
                </div>
              </ModalFooter>
            </form>
          </ModalStyled>
        </ModalWrapper>,
        document.body
      )
    : null;
};

export default BulkUploadModal;
