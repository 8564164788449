import { theme } from '../config/Theme';

enum MediumStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const MediumStatusNames = new Map<MediumStatus, string>([
  [MediumStatus.ACTIVE, 'Aktív'],
  [MediumStatus.INACTIVE, 'Inaktív'],
]);

export const MediumStatusColors = new Map<MediumStatus, string>([
  [MediumStatus.ACTIVE, theme.color.success],
  [MediumStatus.INACTIVE, theme.color.warning],
]);

export default MediumStatus;
