import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Config from '../../config/Config';
import { styled, theme } from '../../config/Theme';
import UserRole from '../../enums/UserRole';
import useAPI from '../../hooks/useAPI';
import User from '../../models/User';
import Toaster from '../../utils/Toaster';
import { Token } from '../../utils/TokenHelper';
import MainButton from '../Button/MainButton';
import SmallButton from '../Button/SmallButton';
import Link from '../Link/Link';
import ActionContainer from '../Spacing/ActionContainer';
import { H2 } from '../Typography';
import RenderErrorSearchModal from './RenderErrorSearchModal';
import SignoutIcon from '../Icons/SignoutIcon';
import { Button } from '@mui/material';

const HeaderWrapper = styled.header`
  width: calc(100% - ${theme.space.small} * 2);
  height: ${(props) => props.theme.fontsize.medium};
  background-color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.small};
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = ({
  withLogs,
  canLogout = true,
  toggleErrorModal,
}: {
  withLogs?: boolean;
  canLogout?: boolean;
  toggleErrorModal?: () => void;
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [, getMe] = useAPI({ url: '/me', method: 'GET' }, { manual: true });
  const [, getStatistics] = useAPI({ url: '/statistics/daily', method: 'GET' }, { manual: true });
  const [me, setMe] = useState<User>();

  useEffect(() => {
    getMe()
      .then((res) => setMe(res.data))
      .catch((err) => console.error(err));
  }, []);

  const generateExcelReport = async () => {
    setLoading(true);
    const startOfDay = moment().startOf('day').format('YYYY-MM-DD');
    const endOfDay = moment().endOf('day').format('YYYY-MM-DD');

    const response = await getStatistics();

    if (response.status === 200) {
      Toaster.success('Sikeres report készítés.');
    } else {
      Toaster.error('Sikertelen report készítés!');
    }

    setLoading(false);
  };

  const openErrorSearchModal = (): void => {
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const doLogout = () => {
    if (canLogout) {
      localStorage.removeItem(Token.ACCESS);
      localStorage.removeItem(Token.REFRESH);
      location.href = Config.loginUrl;
    } else {
      if (toggleErrorModal) toggleErrorModal();
    }
  };

  return (
    <HeaderWrapper>
      <H2 style={{ cursor: 'pointer' }} onClick={(e) => (location.href = '/overview')}>
        Seeker
      </H2>
      <ActionContainer alignment='end'>
        {me && me.role === UserRole.SUPERADMIN && (
          <>
            <MainButton disabled={isLoading} onClick={generateExcelReport}>
              Excel report
            </MainButton>
            <MainButton onClick={() => openErrorSearchModal()}>Hibakeresés</MainButton>
            <MainButton onClick={() => (location.href = `/system-logs`)}>Logok</MainButton>
          </>
        )}

        {withLogs && <Link onClick={() => (location.href = '/logs')}>Tevékenység napló</Link>}

        <Button
          variant='outlined'
          onClick={doLogout}
          sx={{
            marginLeft: 2,
            border: '1px solid #061354',
            color: '#061354',
            fontWeight: 'bold',
            textTransform: 'none',

            ':hover': {
              borderColor: theme.color.primary,
              color: theme.color.primary,
            },
          }}
        >
          <SignoutIcon />
          <span style={{ marginLeft: 5 }}>Kilépés</span>
        </Button>
      </ActionContainer>
      {isModalOpen && <RenderErrorSearchModal toggle={toggleModal} />}
    </HeaderWrapper>
  );
};

export default Header;
