import React from 'react';
import { styled } from '../../../../config/Theme';
import Link from '../../../Link/Link';
import { H1 } from '../../../Typography';
import { EditableDisplayTextProps } from '../EditableText';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;

  a {
    margin-left: ${(props) => props.theme.space.tiny};
  }
`;

const HeaderWithLink = (props: EditableDisplayTextProps): JSX.Element => {
  return (
    <Container>
      <H1>{props.text}</H1>
      <Link onClick={() => props.startEditing()}>Átnevezés</Link>
    </Container>
  );
};

export default HeaderWithLink;
