import Entity from '../interfaces/IEntity';
import Creative from './Creative';
import Daterange from './Daterange';
import Screenshot from './Screenshot';

export default class CreativeVariation implements Entity {
  constructor(
    public id?: string,
    public created?: Date,
    public modified?: Date,
    public filename?: string,
    public bannerUploaded?: boolean,
    public originalFilename?: string,
    public matchNumber?: number,
    public articleTitle?: string,
    public size?: number,
    public duration?: number,
    public mimetype?: string,
    public creative?: Creative,
    public dateRanges?: Daterange[],
    public start?: Date,
    public end?: Date,
    public screenshots?: Screenshot[],
    public creativeId?: string
  ) {}
}
