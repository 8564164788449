import { styled } from '../../config/Theme';

export interface PageWrapperProps {
  bgColor: string;
}
export default styled.div<PageWrapperProps>`
  background-color: ${(props) => props.bgColor || props.theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
