import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const InnerMarker = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; disabled: boolean }>`
  width: ${(props) => props.theme.space.half};
  height: ${(props) => props.theme.space.half};
  border-radius: 3px;
  border: 2px solid ${(props) => (props.disabled ? props.theme.color.gray3 : props.theme.color.primary)};
  transition: all 150ms;
  padding: 2px;
  margin-right: ${(props) => props.theme.space.half};
  cursor: pointer;

  ${InnerMarker} {
    visibility: ${(props) => (props.disabled ? 'visible' : props.checked ? 'visible' : 'hidden')};
    background-color: ${(props) => (props.disabled ? props.theme.color.gray3 : props.theme.color.primary)};
  }
`;

const Checkbox = (props: HTMLAttributes<HTMLDivElement> & { checked?: boolean; disabled?: boolean }): JSX.Element => {
  return (
    <CheckboxContainer {...props}>
      <StyledCheckbox checked={!!props.checked} disabled={!!props.disabled}>
        <InnerMarker />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
