import React, { useMemo } from 'react';
import Medium from '../models/Medium';
import MediumStatus from '../enums/MediumStatus';
import Actions from '../enums/DropdownActions';

import { DropdownOption } from '../components/Editable/Dropdown';
import getMediumColumns from '../components/Columns/getMediumColumns';
import Column from '../components/Table/Column';

type useMediumColumnsHookProps = {
  setStatus: (status: MediumStatus, mediumId: string) => void;
  handleActionSelected: (option: DropdownOption<Actions>, medium: Medium) => void;
  updateMedium: (medium: Medium) => void;
  handleSorting: (property: string, direction: 'ASC' | 'DESC') => void;
  openMatcherErrorModal: (medium: Medium) => void;
};

const useMediumColumns = ({
  setStatus,
  handleActionSelected,
  updateMedium,
  handleSorting,
  openMatcherErrorModal,
}: useMediumColumnsHookProps) => {
  const columns: Column<Medium>[] = useMemo(() => {
    return getMediumColumns({ setStatus, handleActionSelected, updateMedium, handleSorting, openMatcherErrorModal });
  }, [setStatus, handleActionSelected, updateMedium, handleSorting, openMatcherErrorModal]);

  return columns;
};

export default useMediumColumns;
