/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../config/Theme';
import CreativeType from '../../../../enums/CreativeType';
import Creative from '../../../../models/Creative';
import { ImageContainer } from '../../../Layout/CreativeVariationItem';
import RemoteImage from '../../../Layout/RemoteImage';
import Flex from '../../../Spacing/Flex';
import { Ellipsized } from '../../../Typography';

const CreativeTableVariations = (props: { creative: Creative }): JSX.Element => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [mimeTypes, setMimeTypes] = useState<string[]>([]);

  useEffect(() => {
    if (!props.creative?.variations || props.creative.variations.length === 0) {
      setImageUrls([]);
    } else {
      const imageUrls = props.creative?.variations
        ?.filter((v) => Boolean(v.id) && v.bannerUploaded)
        ?.map((v) => `/creatives/${props.creative.id}/variations/${v.id}/binary`);

      const mimeTypeList: string[] = [];

      props.creative.variations.forEach((variation) => {
        if (!variation.mimetype) {
          setMimeTypes([]);
        } else {
          mimeTypeList.push(variation.mimetype);

          setMimeTypes(mimeTypeList);
        }
      });

      setImageUrls(imageUrls);
    }
  }, [props.creative]);

  let content: JSX.Element;

  if (props.creative.type === CreativeType.ARTICLE) {
    const title = (props.creative.variations && props.creative.variations[0]?.articleTitle) ?? '-';
    content = (
      <Ellipsized center title={title === '-' ? undefined : title}>
        {title}
      </Ellipsized>
    );
  } else if (imageUrls.length === 0) {
    content = <Ellipsized center>Nincs banner</Ellipsized>;
  } else if (imageUrls.length === 1) {
    content = (
      <ImageContainer width='60%' style={{ marginRight: 0 }}>
        <Flex column height={theme.space.large}>
          <RemoteImage alt='Feldolgozás alatt...' source={imageUrls[0]} mimeType={mimeTypes[0]} />
        </Flex>
        <Ellipsized center>
          {props.creative?.variations ? props.creative?.variations[0]?.originalFilename : 'Hiányzó fájlnév'}
        </Ellipsized>
      </ImageContainer>
    );
  } else {
    content = (
      <ImageContainer width='60%' style={{ marginRight: 0 }}>
        {(props.creative?.width ?? 0) > (props.creative?.height ?? 0) ? (
          <Flex column height={theme.space.large} align='center' justify='space-between'>
            <RemoteImage
              alt='Feldolgozás alatt...'
              style={{ height: '40%' }}
              source={imageUrls[0]}
              mimeType={mimeTypes[0]}
            />
            <RemoteImage
              alt='Feldolgozás alatt...'
              style={{ height: '40%' }}
              source={imageUrls[1]}
              mimeType={mimeTypes[0]}
            />
          </Flex>
        ) : (
          <Flex row height={theme.space.large} align='center' justify='space-between'>
            <RemoteImage
              alt='Feldolgozás alatt...'
              style={{ width: '40%' }}
              source={imageUrls[0]}
              mimeType={mimeTypes[0]}
            />
            <RemoteImage
              alt='Feldolgozás alatt...'
              style={{ width: '40%' }}
              source={imageUrls[1]}
              mimeType={mimeTypes[0]}
            />
          </Flex>
        )}
        <Ellipsized center>{imageUrls.length} variáció</Ellipsized>
      </ImageContainer>
    );
  }

  return (
    <Flex
      justify='center'
      align='center'
      width='100%'
      style={{ marginTop: theme.space.half, marginBottom: theme.space.half }}
    >
      {content}
    </Flex>
  );
};

export default CreativeTableVariations;
