import React from 'react';
import { styled } from '../../config/Theme';
import DeepUrl from '../../models/DeepUrl';
import { Ellipsized } from '../Typography';
import Card from './Card';

export type UrlCardProps = {
  urls: DeepUrl[];
};

const UrlHeader = styled.div`
  margin-bottom: ${(props) => props.theme.space.half};
`;

const UrlCard = styled(Card)`
  width: 75%;
`;

const UrlMuted = styled.span`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.color.gray3};
`;

const UrlCardComponent = (props: UrlCardProps): JSX.Element => {
  return (
    <UrlCard>
      <UrlHeader>URL</UrlHeader>
      {props.urls && props.urls.length > 0 ? (
        props.urls.map((url) => (
          <UrlMuted key={url.id}>
            <Ellipsized>{url.rootUrl}</Ellipsized>
          </UrlMuted>
        ))
      ) : (
        <UrlMuted>Nincsenek felvett URL-ek</UrlMuted>
      )}
    </UrlCard>
  );
};

export default UrlCardComponent;
