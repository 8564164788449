/* eslint-disable react/prop-types */
import React from 'react';
import { theme } from '../../config/Theme';
import Flex from '../Spacing/Flex';
import Badge from './Badge';

const SwitchBadge = (props: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  return (
    <Badge padding='0px' style={{ width: props.width ?? 'max-content' }} bgColor={theme.color.gray2}>
      <Flex row justify='center' align='center'>
        <Badge bgColor={theme.color.gray2}>{props.children}</Badge>
      </Flex>
    </Badge>
  );
};
export default SwitchBadge;
