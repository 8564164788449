import { styled } from '../../../config/Theme';
import { H1Style } from '../../Typography';
import EditorBase from './EditorBase';

const HeaderEditor = styled.input`
  ${H1Style}
  ${EditorBase}
`;

export default HeaderEditor;
