import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { styled, theme } from '../../config/Theme';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';
import Badge from './Badge';

export type DateBadgeProps = {
  start?: Date;
  end?: Date;
  splitLine?: boolean;
  wide?: boolean;
  onClick?: () => void;
};

const DateBadgeStyled = styled(Badge)<{ wide: boolean; maxWidth?: string }>`
  background-color: ${(props) => props.theme.color.gray2};
  padding: ${(props) => (props.wide ? `${props.theme.space.half} ${props.theme.space.quarter}` : '0.3 rem')};

  span {
    white-space: nowrap;
  }
`;

const DateBadge = (props: DateBadgeProps): JSX.Element => (
  <Flex row justify='center' align='center'>
    <DateBadgeStyled wide={props.wide ?? false} onClick={props.onClick}>
      <Flex row style={{ padding: `0 ${theme.space.tiny}` }} align='center'>
        {props.start && props.end ? (
          <>
            <FontAwesomeIcon icon={faCalendar} style={{ marginRight: theme.space.half }} />
            <Span>
              {moment(props.start).format('YYYY.MM.DD')}-{props.splitLine && <br />}
              {moment(props.end).format('YYYY.MM.DD')}
            </Span>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCalendar} style={{ marginRight: theme.space.half }} color={theme.color.gray3} />
            <Span color={theme.color.gray3}>Nincs dátum</Span>
          </>
        )}
      </Flex>
    </DateBadgeStyled>
  </Flex>
);

export default DateBadge;
