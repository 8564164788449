import UrlType from '../enums/UrlType';
import Entity from '../interfaces/IEntity';

export default class DeepUrl implements Entity {
  constructor(
    public id?: string,
    public rootUrl?: string,
    public urlType?: UrlType,
    public mediumId?: string,
    public urlList?: string[]
  ) {}
}
