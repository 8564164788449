import { styled } from '../../config/Theme';

export const GroupedRadio = styled.div`
  & > div {
    padding: 0 20px;
    min-height: 41px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.gray3};
  }
`;

export const Label = styled.label`
  display: flex;
  font-size: ${(props) => props.theme.fontsize.form};
  flex-direction: column;
  gap: 10px;
`;
