import React, { FC, useEffect, useState, useMemo } from 'react';
import { theme } from '../config/Theme';
import Toaster from '../utils/Toaster';

import Medium from '../models/Medium';
import MediumStatus from '../enums/MediumStatus';
import Actions from '../enums/DropdownActions';

import useAPI from '../hooks/useAPI';
import useMediumColumns from '../hooks/useMediumColumns';
import useModal from '../hooks/useModal';

import { H2 } from '../components/Typography';
import { DropdownOption } from '../components/Editable/Dropdown';
import Flex from '../components/Spacing/Flex';
import TableWrapper from '../components/Layout/TableWrapper';
import NewTable from '../components/Table/NewTable';
import Modal from '../components/Cards/Modal';
import DeleteModalContent from '../components/Cards/DeleteModalContent';
import PaginationContainer from '../components/Table/PaginationContainer';
import FilterRow from '../components/Table/FilterRow';

import { useMatcherModalContext } from '../components/Provider/MatcherErrorModalProvider';
import MediumDuplicationModal from '../components/Layout/MediumDuplicationModal';
import { DuplicateBodyType } from '../enums/Duplication';
import { StatusCodes } from 'http-status-codes';

type MediumTableProps = {
  campaignId: string;
};

const MediumTable: FC<MediumTableProps> = ({ campaignId }): JSX.Element => {
  const [, getMediums] = useAPI(`/mediums?campaignId=${campaignId}`, { manual: true });
  const [, duplicateMedium] = useAPI({ url: '/duplicate', method: 'POST' }, { manual: true });
  const [, putMedium] = useAPI({ method: 'PUT' }, { manual: true });
  const [, deleteMedium] = useAPI({ method: 'DELETE' }, { manual: true });
  const [, getDownloadUrl] = useAPI({ method: 'GET' }, { manual: true });

  const [isModalOpen, setModalOpen] = useModal();
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useModal();

  const [mediums, setMediums] = useState<Medium[]>([]);
  const [campaignToDuplicate, setCampaignToDuplicate] = useState<Medium>({});
  const [toDelete, setToDelete] = useState<Medium>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterMediumWithErrors, setFilterMediumWithErrors] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setModalTitle, setIsMatcherModalOpen, setMediumId, setMatcherErrorsType } = useMatcherModalContext();

  const openMatcherErrorModal = (medium: Medium) => {
    setMatcherErrorsType(medium.type || '');
    setModalTitle('Hibalista / ' + medium.name);
    setMediumId(medium.id || null);
    setIsMatcherModalOpen(true);
  };

  const pageCount = useMemo(() => {
    if (totalCount === 0) return 1;
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const handlePageIndexChange = (nextPageNumber: number) => {
    if (nextPageNumber < 0 || nextPageNumber > pageCount - 1) return;
    setPageIndex(nextPageNumber);
  };

  const handleSorting = (property: string, direction: 'ASC' | 'DESC') => {
    fetchMediums(pageIndex, pageSize, filterMediumWithErrors, '', { property, direction });
  };

  const setStatus = (status: MediumStatus, mediumId: string): void => {
    if (!status || !mediumId) return;

    const medium = mediums.find((m) => m.id === mediumId);
    if (!medium || medium.status === status) return;

    medium.status = status;
    updateMedium(medium);
  };

  const filterErrorMediums = () => {
    setPageIndex(0);
    fetchMediums(0, pageSize, !filterMediumWithErrors, searchValue);

    setFilterMediumWithErrors((val) => !val);
  };

  const filterMediumsByName = (value: string) => {
    if (typeof value !== 'undefined') {
      setSearchValue(value);
      setPageIndex(0);
      fetchMediums(0, pageSize, filterMediumWithErrors, value);
    }
  };

  const handleActionSelected = async (option: DropdownOption<Actions>, medium: Medium) => {
    switch (option.value) {
      case Actions.VIEW:
        location.href = `/creatives?mediumId=${medium.id}`;
        break;

      case Actions.DOWNLOAD:
        const downloadUrl = await getDownloadUrl({ url: `/screenshots/download?medium_id=${medium.id}` });
        if (downloadUrl && downloadUrl.data && typeof downloadUrl.data === 'string') {
          location.href = downloadUrl.data;
        } else Toaster.unknownError();
        break;

      case Actions.DUPLICATE:
        setCampaignToDuplicate(medium);
        setIsDuplicationModalOpen(true);
        break;

      case Actions.DELETE:
        setToDelete(medium);
        setModalOpen(true);
        break;
    }
  };

  const handleDelete = async (): Promise<void> => {
    const deleteResponse = await deleteMedium({ url: `/mediums/${toDelete?.id}` });

    if (deleteResponse.status === 204) {
      const remainingMediums = mediums.filter((m) => m.id !== toDelete?.id);

      setModalOpen(false);
      setToDelete(undefined);
      setMediums(remainingMediums);

      if (remainingMediums.length === 0) {
        setPageIndex(0);
      }

      Toaster.success('Sikeres törlés!');
    } else {
      Toaster.error('Sikertelen törlés!');
    }
  };

  const handleMediumDuplication = async (duplicationRequestBody: DuplicateBodyType) => {
    setIsLoading(true);
    const response = await duplicateMedium({ data: duplicationRequestBody });

    setIsLoading(false);

    if (response.status !== StatusCodes.OK) {
      Toaster.error('Sikertelen duplikálás!');
      return;
    }

    Toaster.success('Sikeres duplikálás!');
    fetchMediums(pageIndex, pageSize, filterMediumWithErrors);
    setIsDuplicationModalOpen(false);
  };

  const fetchMediums = async (
    offset: number,
    limit: number,
    onlyErrors: boolean,
    nameFilterValue?: string,
    sortBy?: {
      property: string;
      direction: string;
    }
  ): Promise<void> => {
    const mediumsResponse = await getMediums({
      params: { offset: offset * limit, limit, onlyErrors, nameFilterValue, sortBy },
    });
    if (!mediumsResponse || !mediumsResponse.data) return;

    setMediums(mediumsResponse.data[0]);
    setTotalCount(mediumsResponse.data[1]);
  };

  const updateMedium = (updatedMedium: Medium) => {
    putMedium({ url: `/mediums/${updatedMedium.id}`, data: updatedMedium }).then((res) => {
      Object.assign(updatedMedium, res.data);
    });

    // Keeping the original order and only updating the medium that we want to update
    setMediums(mediums.map((m) => (m.id === updatedMedium.id ? updatedMedium : m)));
  };

  useEffect(() => {
    fetchMediums(pageIndex, pageSize, filterMediumWithErrors);
    setPageIndex(pageIndex);
  }, [pageIndex]);

  const columns = useMediumColumns({
    setStatus,
    handleActionSelected,
    updateMedium,
    handleSorting,
    openMatcherErrorModal,
  });

  return (
    <>
      <TableWrapper>
        <NewTable<Medium>
          columns={columns}
          data={mediums}
          idKey={'id'}
          onRowClick={(medium: Medium) => (location.href = `/mediums/${medium.id}`)}
          filterRow={
            <FilterRow
              onlyShowTheOnesWithError={filterMediumWithErrors}
              handleCheck={filterErrorMediums}
              handleSearch={filterMediumsByName}
              totalCount={totalCount}
              pageSize={pageSize}
              name='médium'
              setPageSize={(size: number) => {
                fetchMediums(0, size, filterMediumWithErrors, searchValue);
                setPageSize(size);
                setPageIndex(0);
              }}
            />
          }
        />
      </TableWrapper>

      {pageCount > 1 && (
        <Flex justify='center' style={{ marginTop: theme.space.small }}>
          <PaginationContainer pageIndex={pageIndex} pageCount={pageCount} setPageIndex={handlePageIndexChange} />
        </Flex>
      )}

      {isDuplicationModalOpen && (
        <MediumDuplicationModal
          toggleModal={setIsDuplicationModalOpen}
          medium={campaignToDuplicate}
          onSave={handleMediumDuplication}
          isLoading={isLoading}
        />
      )}

      <Modal isOpen={isModalOpen} toggle={setModalOpen}>
        <DeleteModalContent onDelete={handleDelete} onCancel={() => setModalOpen(false)}>
          <H2>Biztosan törölni szeretné a médiumot?</H2>
        </DeleteModalContent>
      </Modal>
    </>
  );
};

export default MediumTable;
