import React, { FC } from 'react';
import Flex from '../Spacing/Flex';
import { EditDayBadge } from '../Layout/PrArticleModal';
import { Span } from '../Typography';
import { CreativeDate } from '../Layout/CreativeModal';

const CreativeModalDateBadge: FC<CreativeDate> = ({ months, weeks, days }): JSX.Element => {
  return (
    <Flex row justify='space-between' width='100%' title='Egy hónap 30 napot jelent'>
      <EditDayBadge>
        {months}
        <Span className='duration'>hónap</Span>
      </EditDayBadge>
      <EditDayBadge>
        {weeks}
        <Span className='duration'>hét</Span>
      </EditDayBadge>
      <EditDayBadge>
        {days}
        <Span className='duration'>nap</Span>
      </EditDayBadge>
    </Flex>
  );
};

export default CreativeModalDateBadge;
