import React from 'react';
import Select, { GroupTypeBase, Props, Styles, Theme } from 'react-select';
import Creatable from 'react-select/creatable';
import { I } from '../../components/Typography';
import { theme } from '../../config/Theme';
import Size from '../../utils/Size';

export type SelectOption = {
  value: string;
  label: string;
};

const SearchSelect = (props: Props & Size & { isCreatable?: boolean; errorMessage?: string }): JSX.Element => {
  const selectTheme = (base: Theme) => ({
    ...base,
    colors: {
      ...base.colors,
      primary: theme.color.gray3,
      primary25: theme.color.gray1,
    },
  });

  const selectStyle: Partial<
    Styles<
      {
        label: string;
        value: string;
      },
      false,
      GroupTypeBase<{
        label: string;
        value: string;
      }>
    >
  > = {
    container: (base) => ({
      ...base,
      width: props.width || '100%',
      height: props.height || 'auto',
      zIndex: 300,
      fontSize: theme.fontsize.form,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    control: (base) => ({
      ...base,
      flexWrap: 'nowrap',
      margin: 0,
      fontSize: theme.fontsize.form,
      borderColor: props.errorMessage ? theme.color.warningRed : theme.color.gray3,
      backgroundColor: theme.color.gray1,
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flex: 1,
      maxWidth: '175px',
      padding: '12px 10px',
      fontSize: theme.fontsize.form,
    }),
  };

  if (props.isCreatable) {
    return (
      <Creatable
        {...props}
        formatCreateLabel={(input: string) => {
          return (
            <>
              Új cím felvétele:&nbsp;&nbsp;<I>{input}</I>
            </>
          );
        }}
        w
        theme={selectTheme}
        noOptionsMessage={() => 'Nincs találat'}
        isClearable={true}
        styles={selectStyle}
      />
    );
  } else {
    return (
      <Select
        {...props}
        theme={selectTheme}
        isClearable={props.isClearable ?? true}
        noOptionsMessage={() => 'Nincs találat'}
        styles={selectStyle}
      />
    );
  }
};

export default SearchSelect;
