// Maybe we do not need to specify a default value?
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const env: 'production' | 'development' | 'staging' | 'test' =
  (process.env.REACT_APP_ENV as 'production' | 'development' | 'staging' | 'test') || 'development';

const config = {
  base: {
    protocoll: 'http',
    baseUrl: 'localhost',
    port: 6500,
    timeout: 30000,
    loginUrl: '/login',
    refreshTokenExpiry: 86400,
    paginationOptionsCount: 5,
    maxUrlCount: 5,
  },
  development: {
    env: 'development',
  },
  production: {
    port: false,
    env: 'production',
    protocoll: 'https',
    baseUrl: 'api.seeker.click',
  },
  test: {
    env: 'test',
  },
  staging: {
    env: 'staging',
    port: false,
    protocoll: 'https',
    baseUrl: 'api.staging.seeker.click',
  },
};

export default { ...config.base, ...config[env] };
