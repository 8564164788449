import { useMemo } from 'react';

import type { MatcherErrorScreenshot } from '../components/Layout/MatcherErrorsModal';

import getMatcherErrorColumns from '../components/Columns/getMatcherErrorColumns';
import Column from '../components/Table/Column';

const useMatcherErrorColumns = (
  selectAll: boolean,
  excludeAllScreenshot: () => void,
  mediumName: string,
  selectCreative: (creativeId: string) => void,
  excludeScreenshot: (id: string) => void
) => {
  const columns: Column<MatcherErrorScreenshot>[] = useMemo(() => {
    return getMatcherErrorColumns(selectAll, excludeAllScreenshot, mediumName, selectCreative, excludeScreenshot);
  }, [selectAll, excludeAllScreenshot, mediumName, selectCreative, excludeScreenshot]);

  return columns;
};

export default useMatcherErrorColumns;
