import React, { useMemo } from 'react';
import getCampaignColumns from '../components/Columns/getCampaignColumns';
import { DropdownOption } from '../components/Editable/Dropdown';
import Column from '../components/Table/Column';
import Actions from '../enums/DropdownActions';
import Campaign from '../models/Campaign';

type UseCampaignColumnsProps = {
  openMatcherErrorModal: (campaign: Campaign) => void;
  handleSorting: (property: string, direction: 'ASC' | 'DESC') => void;
  handleSelectedDropdownOption: (option: DropdownOption<Actions>, Campaign: Campaign) => void;
};

const useCampaignColumns = ({
  openMatcherErrorModal,
  handleSorting,
  handleSelectedDropdownOption,
}: UseCampaignColumnsProps) => {
  const columns: Column<Campaign>[] = useMemo(() => {
    return getCampaignColumns(openMatcherErrorModal, handleSorting, handleSelectedDropdownOption);
  }, [openMatcherErrorModal, handleSorting, handleSelectedDropdownOption]);
  return columns;
};

export default useCampaignColumns;
