import { css } from 'styled-components';

const EditorBase = css`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  caret-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.secondary};

  &:active &:focus {
    outline: none;
    border: none;
    padding: 0;
  }
`;

export default EditorBase;
