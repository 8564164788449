import React, { useEffect, useMemo, useReducer, useState } from 'react';
import useAPI from '../../hooks/useAPI';
import Medium from '../../models/Medium';
import Checkbox from '../Button/Checkbox';
import { styled, theme } from '../../config/Theme';
import MainButton from '../Button/MainButton';
import { useDownload } from '../../hooks/useDownload';
import Loader from 'react-loader-spinner';

export type ModalProps = { campaignId: string | undefined } & React.HTMLAttributes<HTMLDivElement>;
type MediumScreenshots = {
  sizes: {
    mediumSizes: { [key: string]: number };
    sizeSumm: number;
  };
  mediums: Medium[];
};

const Flex = styled.div<{ bg?: string; justify?: string }>`
  display: flex;
  justify-content: ${(props) => props.justify || 'space-between'};
  gap: 15px;
  width: 100%;
  /* background-color: ${(props) => props.bg}; */
  padding: 10px 0;
  box-sizing: border-box;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.color.gray3};
  padding-bottom: 12px;
  margin-bottom: 10px;
  div {
    margin: 0;
  }
`;

const FlexInner = styled.div`
  display: flex;
  align-items: end;
  flex-direction: row;
  gap: 5px;
  div {
    margin: 0;
  }
  span {
    font-size: ${(props) => props.theme.fontsize.tiny};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: end;
`;

type ActionType = { type: 'ADD' | 'REMOVE' | 'ADD_ALL' | 'REMOVE_ALL'; payload: string };
const fileSize = (size: number) => {
  if (size >= 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024 * 1024)).toPrecision(3)} GB`;
  }
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toPrecision(3)} MB`;
  }
  if (size >= 1024) {
    return `${(size / 1024).toPrecision(3)} KB`;
  }
  if (size < 1024) {
    return `${size.toPrecision(3)} B`;
  }
};

const ScreenshotDownloadModal = (props: ModalProps) => {
  const [, getScreenshots] = useAPI(
    { url: `/campaigns/${props.campaignId}/screenshots`, method: 'GET' },
    { manual: true }
  );

  const [screenShots, setScreenShots] = useState<MediumScreenshots | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const mediumsWithScreenshots = [...(Object.keys(screenShots?.sizes?.mediumSizes || []) || [])];
  const [state, dispatch] = useReducer((state: string[], action: ActionType) => {
    switch (action.type) {
      case 'ADD':
        return [...state, action.payload];
      case 'REMOVE':
        return state.filter((s) => s !== action.payload);
      case 'ADD_ALL':
        return mediumsWithScreenshots;
      case 'REMOVE_ALL':
        return [];
      default:
        return state;
    }
  }, []);

  const downloadScreenshots = useDownload({ url: `/screenshots/download?mediumIds=${state.join(',')}` });

  const toggleAddAllMediums = () => {
    if (state.length !== 0) {
      dispatch({ type: 'REMOVE_ALL', payload: '' });
    } else {
      dispatch({ type: 'ADD_ALL', payload: '' });
    }
  };

  const toggleAddMedium = (mediumId: string) => {
    if (state.includes(mediumId)) {
      dispatch({ type: 'REMOVE', payload: mediumId });
    } else {
      dispatch({ type: 'ADD', payload: mediumId });
    }
  };

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    getScreenshots().then(({ data }) => {
      if (!isCancelled) {
        setScreenShots(data || []);
        setIsLoading(false);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, []);

  const calculatedData = useMemo(
    () => state?.map((v) => screenShots?.sizes.mediumSizes[v])?.reduce((a, b) => a! + b!, 0),
    [state]
  );
  screenShots?.mediums.sort((a, b) => {
    if (a.name! > b.name!) return 1;
    return -1;
  });

  if (isLoading)
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        Betöltés... <Loader type='Rings' color={theme.color.primary}></Loader>
      </div>
    );

  return (
    <>
      <CheckBoxWrapper>
        <div style={{ fontWeight: 400, fontSize: '13px' }}>MÉDIUM</div>
        <Checkbox
          onClick={() => toggleAddAllMediums()}
          disabled={!!!screenShots?.mediums?.length}
          checked={state.length === mediumsWithScreenshots.length && state.length !== 0}
        ></Checkbox>
      </CheckBoxWrapper>
      {(screenShots && !screenShots?.mediums) || screenShots?.mediums?.length === 0 ? (
        <div>Nincs adat!</div>
      ) : (
        screenShots?.mediums?.map((m, i) => (
          <Flex bg={i !== 0 || i % 2 == 0 ? theme.color.gray2 : 'inherit'} key={m.id}>
            <div>{m.name}</div>
            <FlexInner>
              <span>{fileSize(screenShots.sizes.mediumSizes[m.id!]) || `nincs adat`}</span>
              <div>
                <Checkbox
                  disabled={!mediumsWithScreenshots.includes(m.id!)}
                  onClick={() => {
                    if (mediumsWithScreenshots.includes(m.id!)) toggleAddMedium(m.id!);
                  }}
                  checked={state.includes(m.id!)}
                ></Checkbox>
              </div>
            </FlexInner>
          </Flex>
        ))
      )}
      <FooterContainer>
        <FlexInner>
          <ButtonContainer>
            <MainButton
              variation='create'
              onClick={downloadScreenshots}
              style={{ width: '150px', alignSelf: 'end' }}
              disabled={state.length === 0}
            >
              Letöltés
            </MainButton>
            <div style={{ textAlign: 'right', marginTop: '5px' }}>
              <span>
                Kiválasztva: {calculatedData ? fileSize(calculatedData) : 0} /{' '}
                {(screenShots?.sizes && fileSize(screenShots.sizes.sizeSumm)) ?? 0}
              </span>
            </div>
          </ButtonContainer>
        </FlexInner>
      </FooterContainer>
    </>
  );
};

export default ScreenshotDownloadModal;
