import { styled } from '../../config/Theme';

/* Width and height calculated as follows:
  width: (space available) - (2x padding) - (2x border)
  height: (standard height) - (2x border)
 */

export const FormInput = styled.input`
  width: calc(100% - ${(props) => props.theme.space.tiny} * 2 - 2px);
  height: calc(${(props) => props.theme.space.small} - 2px);
  background-color: ${(props) => props.theme.color.gray1};
  color: ${(props) => props.theme.color.gray3};
  border: 1px solid ${(props) => props.theme.color.gray2};
  border-radius: 5px;
  font-family: 'Rubik';
  padding: ${(props) => props.theme.space.tiny};

  &::placeholder {
    color: ${(props) => props.theme.color.gray3};
  }

  &:focus {
    outline: none;
  }
`;

export const FormLabel = styled.label`
  align-self: flex-start;
  color: ${(props) => props.theme.color.primary};
  margin: ${(props) => props.theme.space.half} 0;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: ${(props) => props.theme.fontsize.small};
`;
