import Creative from '../models/Creative';
import CreativeVariation from '../models/CreativeVariation';
import moment from 'moment';

export function getVariationRange(variation: CreativeVariation) {
  if (typeof variation.dateRanges === 'undefined') {
    return { start: undefined, end: undefined };
  }

  const start =
    variation.dateRanges.length === 0
      ? undefined
      : variation.dateRanges
          .sort((a, b) => {
            if (a.start?.toDate().getTime()! > b.start?.toDate().getTime()!) return 1;
            if (b.start?.toDate().getTime()! > a.start?.toDate().getTime()!) return -1;
            return 0;
          })[0]
          .start!.toDate();

  const end =
    variation.dateRanges.length === 0
      ? undefined
      : variation.dateRanges
          .sort((a, b) => {
            if (a.end?.toDate().getTime()! > b.end?.toDate().getTime()!) return -1;
            if (b.end?.toDate().getTime()! > a.end?.toDate().getTime()!) return 1;
            return 0;
          })[0]
          .end!.toDate();

  return { start, end };
}

export function getDaysProposed(creative: Creative | undefined, variation: CreativeVariation) {
  const days = getDays(variation);
  const diff = days.size - (creative?.daysProposed || 0);
  return creative?.daysProposed ? creative.daysProposed + diff : diff;
}

function getDays(variation: CreativeVariation) {
  const days = new Set<string>();
  for (const v of [variation]) {
    for (const dateRange of v.dateRanges!) {
      let currentDate = moment(dateRange.start);

      while (currentDate.isSameOrBefore(moment(dateRange.end))) {
        days.add(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }
    }
  }
  return days;
}
