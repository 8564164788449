import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMsg, FormButton } from '../Form/Form';
import FormInput from '../Form/FormInput';
import MainButton from '../Button/MainButton';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import { styled } from '../../config/Theme';
import UrlCard from '../Cards/UrlCard';
import Medium from '../../models/Medium';
import { useParams } from 'react-router-dom';
import { CampaignParams } from '../../pages/CampaignPage';
import useAPI, { API } from '../../hooks/useAPI';
import { GroupedRadio } from '../Form/GroupedRadio';

const UrlSchema = z.object({
  rootUrl: z.string(),
  urlType: z.string(),
});

const schema = z.object({
  name: z.string({ required_error: 'Ez a mező nem lehet üres' }).min(1, 'Ez a mező nem lehet üres'),
  type: z.string({ required_error: 'Kötelező kiválasztani' }).min(1, 'Kötelező kiválasztani'),
  urls: z.array(UrlSchema).min(1, 'Legalább egy URL-t meg kell adni'),
});

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  box-sizing: 'border-box';
`;

const GroupedName = styled.div`
  flex-grow: 1;
`;

const Label = styled.label`
  display: flex;
  font-size: ${(props) => props.theme.fontsize.form};
  flex-direction: column;
  gap: 10px;
`;

export type MediumFormValues = z.infer<typeof schema>;

const MediumModal = () => {
  const [, postMedium] = useAPI({ url: `/mediums`, method: 'POST' }, { manual: true });
  const formRef = useRef<HTMLFormElement>(null);
  const [medium, setMedium] = useState<Medium>();

  const form = useForm<MediumFormValues>({
    defaultValues: {
      name: '',
      type: '',
      urls: [],
    },
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {
    formState: { errors, isValid },
  } = form;

  const { campaignId } = useParams<CampaignParams>();

  const onSubmit = async (data: MediumFormValues) => {
    if (!medium) return;
    medium.name = data.name;
    medium.type = data.type as CreativeCampaignType;
    medium.campaignId = campaignId;
    const response = await postMedium({ data: medium });

    if (!medium.urls) return;

    await Promise.all(medium.urls?.map((url) => API.post(`/mediums/${response.data.id}/url`, { ...url })));

    location.href = `/mediums/${response.data.id}`;
  };

  return (
    <FormProvider {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
        <Flex>
          <GroupedName>
            <FormInput errorMessage={errors.name?.message} label='Médium neve' {...form.register('name')}></FormInput>
          </GroupedName>
          <div>
            <Label>
              Típus
              <GroupedRadio>
                <RadioGroup row>
                  {Object.values(CreativeCampaignType).map((value, index) => {
                    return (
                      <FormControlLabel
                        key={`type-${index}`}
                        value={value}
                        {...form.register('type')}
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: 14,
                            lineHeight: '14px',
                          },
                        }}
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 16,
                              },
                            }}
                          />
                        }
                        label={CreativeCampaignTypeNames.get(value)}
                      />
                    );
                  })}
                </RadioGroup>
              </GroupedRadio>
            </Label>
            {errors.type && <ErrorMsg>{errors.type.message}</ErrorMsg>}
          </div>
        </Flex>
        <UrlCard isCreateMedium={true} medium={medium || {}} setMedium={setMedium} />
        <FormButton>
          <MainButton variation='create' disabled={!isValid} onClick={(e) => formRef.current?.submit}>
            Létrehozás
          </MainButton>
        </FormButton>
      </form>
    </FormProvider>
  );
};

export default MediumModal;
