import { HTMLAttributes } from 'react';
import { css } from 'styled-components';
import { styled } from '../config/Theme';

export const H1Style = css`
  margin: 0;
  font-size: ${(props) => props.theme.fontsize.large};
  font-weight: 500;
  font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

export const H1 = styled.h1`
  ${H1Style}
`;

export const SpanStyle = css<HTMLAttributes<HTMLSpanElement>>`
  margin: 0;
  font-size: ${(props) => props.theme.fontsize.small};
  font-weight: 400;
  font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  ${(props) => props.color && 'color:' + props.color};
`;

export const Ellipsized = styled.span<{ center?: boolean }>`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => (props.center ? 'center' : 'inherit')};
`;

export const Span = styled.span`
  ${SpanStyle}
`;

export const Small = styled.span`
  ${SpanStyle}
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

export const Log = styled.span`
  ${SpanStyle}
  font-size: ${(props) => props.theme.fontsize.log};
`;

export const H2 = styled.h2`
  margin: 0;
  font-size: ${(props) => props.theme.fontsize.medium};
  font-weight: 500;
`;

export const P = styled.p`
  font-size: ${(props) => props.theme.fontsize.small};
`;

export const B = styled.b`
  font-style: bold;
`;

export const I = styled.i`
  font-style: italic;
`;
export const Muted = styled.span`
  font-size: ${(props) => props.theme.fontsize.tiny};
  color: ${(props) => props.theme.color.gray3};
`;

export const Section = styled.span`
  font-size: ${(props) => props.theme.fontsize.small};
  color: ${(props) => props.theme.color.black};
`;

export const TextContainerWithEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
