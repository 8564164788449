import React, { FC } from 'react';
import { faExclamation, faInfo } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../config/Theme';

import Card from '../Cards/Card';

import IconButton from '../Button/IconButton';
import Flex from '../Spacing/Flex';
import { Muted, Small } from '../Typography';

type LogItemProps = {
  logs: string[] | { date: string; status: string; message: string }[];
};

const LogItems: FC<LogItemProps> = ({ logs }): JSX.Element => {
  return (
    <Flex>
      <Card style={{ boxShadow: 'none', maxWidth: '98%', margin: '0 auto', maxHeight: '300px', overflowY: 'auto' }}>
        {logs.map((logText, i: number) => (
          <Flex key={`log-text-${i}`} style={{ margin: '5px 0' }}>
            {typeof logText === 'object' ? (
              <>
                <IconButton
                  size='1x'
                  icon={logText.status === 'error' ? faExclamation : faInfo}
                  color={logText.status === 'error' ? theme.color.danger : theme.color.warning}
                  style={{ marginRight: '30px' }}
                />
                <Flex column>
                  <Muted>{logText.date}</Muted>
                  <Small>{logText.message}</Small>
                </Flex>
              </>
            ) : (
              <Flex column>
                <Small>{logText}</Small>
              </Flex>
            )}
          </Flex>
        ))}
      </Card>
    </Flex>
  );
};

export default LogItems;
