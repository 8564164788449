import { AxiosResponse } from 'axios';
import { MouseEvent, MouseEventHandler } from 'react';
import { API } from './useAPI';

type UseDownloadConfig = {
  url?: string;
  onError?: (response: AxiosResponse<any>) => void;
};

export const useDownload = (config: UseDownloadConfig): MouseEventHandler<Element> => {
  return async (event: MouseEvent): Promise<void> => {
    if (!config.url) return;

    event.stopPropagation();
    event.preventDefault();

    const response = await API.get<string>(config.url);

    if (response?.data) {
      console.log(`Downloading file from: ${response.data}`);
      location.href = response.data;
    } else if (!response || response.status >= 400) {
      if (config.onError) {
        config.onError(response);
      } else {
        console.error(`Failed to download file, request returned with error code`);
        console.error(response);
      }
    }
  };
};
