import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../config/Theme';
import Medium from '../../models/Medium';
import DeepUrl from '../../models/DeepUrl';
import Creative from '../../models/Creative';
import { MediumStatusColors, MediumStatusNames } from '../../enums/MediumStatus';
import { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import MediumStatus from '../../enums/MediumStatus';
import CreativeCampaignType from '../../enums/CreativeCampaignType';
import CreativeType from '../../enums/CreativeType';
import Actions from '../../enums/DropdownActions';

import { Ellipsized, Span } from '../Typography';
import { DropdownOption } from '../Editable/Dropdown';
import Flex from '../Spacing/Flex';
import RowWrapper from '../Spacing/RowWrapper';
import Badge from '../Badge/Badge';
import InnerBadge from '../Badge/InnerBadge';
import CampaignProgress from '../Badge/CampaignProgress';
import Dropdown from '../Editable/Dropdown';
import Column from '../Table/Column';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import CampaignStatus from '../../enums/CampaignStatus';
import StatusBadge from '../Badge/StatusBadge';

type getMediumColumnsHookProps = {
  setStatus: (status: MediumStatus, mediumId: string) => void;
  handleActionSelected: (option: DropdownOption<Actions>, medium: Medium) => void;
  updateMedium: (medium: Medium) => void;
  handleSorting: (property: string, direction: 'ASC' | 'DESC') => void;
  openMatcherErrorModal: (medium: Medium) => void;
};

const options: DropdownOption<Actions>[] = [
  { value: Actions.VIEW, label: 'Screenshotok megtekintése' },
  { value: Actions.DOWNLOAD, label: 'Screenshotok letöltése', divider: true },
  {
    value: Actions.DUPLICATE,
    label: 'Médium másolása',
    icon: <FontAwesomeIcon icon={faCopy} color={theme.color.primary} />,
    textColor: theme.color.primary,
  },
  {
    value: Actions.DELETE,
    label: 'Médium törlése',
    icon: <FontAwesomeIcon icon={faTrashAlt} color={theme.color.danger} />,
    textColor: theme.color.danger,
  },
];

const getMediumColumns = ({
  setStatus,
  handleActionSelected,
  updateMedium,
  handleSorting,
  openMatcherErrorModal,
}: getMediumColumnsHookProps) => {
  const columns: Column<Medium>[] = [
    {
      id: 'name',
      label: 'Médiumok',
      width: 15,
      sortable: true,
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter({ name, id }: Medium) {
        return <Ellipsized>{name}</Ellipsized>;
      },
    },
    {
      id: 'urls',
      label: 'URL',
      sortable: false,
      width: 17,
      getter({ urls }: Medium) {
        return urls ? (
          urls.map((url: DeepUrl, i: number) => {
            return (
              <Ellipsized title={url.rootUrl} key={i}>
                {url.rootUrl}
              </Ellipsized>
            );
          })
        ) : (
          <></>
        );
      },
    },
    {
      id: 'status',
      label: 'Státusz',
      sortable: true,
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      width: 8,
      getter({ status, id }: Medium) {
        if (!status || !id) return;
        return (
          <Flex justify='center' align='center'>
            <Badge bgColor={theme.color.gray2}>
              {Object.values(MediumStatus).map((enumStatus) => {
                const selected = enumStatus === status;
                return (
                  <InnerBadge
                    key={enumStatus}
                    selected={selected}
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus(enumStatus, id);
                    }}
                  >
                    <Span color={selected ? MediumStatusColors.get(status as MediumStatus) : theme.color.black}>
                      {MediumStatusNames.get(enumStatus as MediumStatus)}
                    </Span>
                  </InnerBadge>
                );
              })}
            </Badge>
          </Flex>
        );
      },
    },
    {
      id: 'creative_sizes',
      label: 'Mutációk',
      sortable: false,
      width: 8,
      getter(mediumRow: any) {
        if (!mediumRow['creatives'] || (mediumRow['creatives'] as Creative[]).length == 0) return '-';
        const mutations = new Set<string>();
        mediumRow['creatives'].forEach((c: Creative) => {
          if (c.type !== CreativeType.ARTICLE) {
            mutations.add(`${c.width}x${c.height}`);
          }
        });

        return Array.from(mutations).map((mutation: string, index: number) => {
          return <p key={index}>{mutation}</p>;
        });
      },
    },
    {
      label: 'Médium kezdete',
      id: 'start',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        const date = moment(campaign.start);
        return date.isValid() ? date.format('YYYY.MM.DD.') : '-';
      },
      width: 9,
      sortable: true,
    },
    {
      label: 'Médium vége',
      id: 'end',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        const date = moment(campaign.end);
        return date.isValid() ? date.format('YYYY.MM.DD.') : '-';
      },
      width: 9,
      sortable: true,
    },
    {
      id: 'type',
      label: 'Típus',
      sortable: true,
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      width: 6,
      getter(medium: Medium) {
        return (
          <RowWrapper>
            <Badge bgColor={theme.color.gray2}>
              <Span>{CreativeCampaignTypeNames.get(medium.type as CreativeCampaignType)}</Span>
            </Badge>
          </RowWrapper>
        );
      },
    },
    {
      id: 'creativeCount',
      label: 'Kreatívok',
      width: 8,
      sortable: false,
      getter(medium) {
        const variationErrors = medium.creatives?.reduce((acc, v) => {
          const errorNumbers =
            Number(v.variations?.length) === 0
              ? 1
              : 0 + Number(v.variationError?.length) === 0 && Number(v.articleVariationError?.length) === 0
              ? 0
              : 1;
          const err = acc + errorNumbers;
          return err;
        }, 0);

        return (
          <Flex justify='center' align='center'>
            <StatusBadge type={variationErrors !== 0 || medium.creatives?.length == 0 ? 'danger' : 'success'}>
              {Number(medium.creatives?.length || 0) - Number(variationErrors)} / {medium.creatives?.length || 0}
            </StatusBadge>
          </Flex>
        );
      },
    },
    {
      id: 'daysElapsed',
      label: 'Eltelt napok',
      width: 8,
      sortable: false,
      getter({ daysElapsed }: Medium) {
        return daysElapsed;
      },
    },
    {
      id: 'progress',
      label: 'Megjelent napok',
      sortable: true,
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      width: 12,
      getter(medium: Medium) {
        return (
          <Flex justify='center' align='center'>
            <CampaignProgress
              errorState={medium.errorState || 'noErrors'}
              totalDays={medium.daysProposed ?? 0}
              daysPassed={medium.successCount ?? 0}
              onClick={(e) => {
                e.stopPropagation();
                openMatcherErrorModal(medium);
              }}
            />
          </Flex>
        );
      },
    },
    {
      id: 'matches',
      label: 'Matchek',
      sortable: true,
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      width: 8,
      getter({ matchNumber }: Medium) {
        return (
          <Flex justify='center' align='center'>
            {matchNumber}
          </Flex>
        );
      },
    },
    {
      id: 'action',
      label: '',
      sortable: false,
      width: 5,
      getter(medium: Medium) {
        if (medium.campaign?.status === CampaignStatus.CLOSED) {
          const index = options.findIndex((option) => option.value === Actions.DELETE);
          if (index > -1) {
            options.splice(index, 1);
          }
        }

        return (
          <Dropdown options={options} onSelected={(o: DropdownOption<Actions>) => handleActionSelected(o, medium)}>
            <FontAwesomeIcon icon={faEllipsisV} color={theme.color.primary} style={{ cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  return columns;
};

export default getMediumColumns;
