/* eslint-disable max-len */
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLProps, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { styled, theme } from '../../config/Theme';
import MainButton from '../Button/MainButton';
import { DropdownOption } from '../Editable/Dropdown';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';
import Badge, { BadgeProps } from './Badge';

const DropdownStyled = styled.div<{ open: boolean; width?: string; wrapContent?: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  width: ${(props) => (props.wrapContent ? 'auto' : props.width ?? props.theme.space.huge)};
  position: absolute;
  top: 100%;
  background-color: ${(props) => props.theme.color.gray2};
  padding: ${(props) => props.theme.space.quarter};
  border-radius: 5px;
  z-index: 10;
`;

export const DDBadge = styled(Badge)<{ wide?: boolean; width?: string; height?: string | number }>`
  white-space: nowrap;
  user-select: none;
  width: ${(props) => (props.width ? props.width : '')};
  height: ${(props) => (props.height ? props.height : '')};

  background-color: ${(props) => props.theme.color.gray2};
  ${(props) => props.wide && `padding: ${props.theme.space.half};`}
`;

const NewOptionButton = styled(MainButton)`
  height: auto;
  padding: ${(props) => props.theme.space.half};
`;

type DropDownBadgeProps<T> = {
  options: DropdownOption<T>[];
  isOpen: boolean;
  toggle: (state?: boolean) => void;
  alt?: string;
  wide?: boolean;
  wrapContent?: boolean;
  creativeParamsDropdown?: boolean;
  width?: string;
} & BadgeProps &
  HTMLProps<HTMLDivElement>;

const DropDownBadge = function <T>(props: DropDownBadgeProps<T>): JSX.Element {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    if (props.isOpen) props.toggle(false);
  });

  const a: { style: any } = { style: null };
  if (props.creativeParamsDropdown) {
    a.style = {
      height: '1.55rem',
      width: `${theme.space.large}`,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
    };
  }

  return (
    <div style={{ position: 'relative' }} ref={dropdownRef}>
      <DDBadge onClick={() => props.toggle()} wide={props.wide} width={props.width} height={props.height} {...a}>
        <Flex row align='center' justify='center'>
          <Span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {props.options?.find((o) => o.selected)?.label ?? props.alt}
          </Span>
          <FontAwesomeIcon
            icon={props.isOpen ? faCaretUp : faCaretDown}
            color={theme.color.gray3}
            style={{ marginLeft: theme.space.half }}
          />
          <DropdownStyled open={props.isOpen} width={props.width} wrapContent={props.wrapContent}>
            <Flex
              column
              align='center'
              style={{ padding: props.padding ?? theme.space.quarter, maxHeight: theme.space.huge, overflowY: 'auto' }}
            >
              {props.children}
            </Flex>
          </DropdownStyled>
        </Flex>
      </DDBadge>
    </div>
  );
};

export default DropDownBadge;
