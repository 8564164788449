import React from 'react';
import ActionModalContent from './ActionModalContent';

export type DeleteModalProps = {
  onDelete: () => Promise<void>;
  onCancel: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const DeleteModalContent = (props: DeleteModalProps): JSX.Element => {
  return (
    <ActionModalContent onPositive={props.onDelete} onNegative={props.onCancel} positiveText='Törlés'>
      {props.children}
    </ActionModalContent>
  );
};

export default DeleteModalContent;
