import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  color: {
    black: '#061354',
    white: '#FFF',
    gray1: '#F9F9F9',
    gray2: '#EEEEEE',
    gray3: '#9499B6',
    hover: '#cdd0dd',
    dark: '#061354',
    primary: '#1F57E6',
    secondary: '#4BB4FF',
    secondaryWithOpacity: 'rgba(75, 180, 255, 0.2)',
    shadow: '#C0CAE1',
    success: '#5CB85C',
    warning: '#F0AD4E',
    danger: '#D9534F',
    splatShadow: 'rgba(0,0,0,0.15)',
    whiteSemiTransparent: 'rgba(255, 255, 255, 0.5)',
    warningRed: '#FF6058',
    warningGrey: '#DDDFEC',
  },
  space: {
    quarter: '0.25rem',
    half: '0.5rem',
    threequarter: '0.75rem',
    tiny: '1rem',
    lsmall: '1.176rem',
    xsmall: '1.5rem',
    small: '2rem',
    medium: '3rem',
    large: '4rem',
    huge: '8rem',
    extra_huge: '10rem',
  },
  fontsize: {
    xtiny: '0.6rem',
    tiny: '0.75rem',
    log: '0.85rem',
    form: '0.824rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
