import React, { FC, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../config/Theme';

import { CreativeDuplicateType, DuplicateBodyType, DuplicateEntity } from '../../enums/Duplication';
import Campaign from '../../models/Campaign';

import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import Flex from '../Spacing/Flex';
import { B, H1, I, Span } from '../Typography';
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InfoText,
  Label,
  RadioInput,
  InfoBlock,
  NameInput,
} from '../Cards/DuplicationModal';

export type CampaignDuplicationModalProps = {
  campaign: Campaign;
  toggleModal: (state?: boolean) => void;
  onSave: (duplicationRequestBody: DuplicateBodyType) => void;
  isLoading: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CampaignDuplicationModal: FC<CampaignDuplicationModalProps> = ({
  toggleModal,
  campaign,
  onSave,
  isLoading,
}): JSX.Element => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [creativeDuplicationType, setCreativeDuplicationType] = useState<CreativeDuplicateType>();
  const [campaignName, setCampaignName] = useState<string>('');

  const handleRadioChangEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreativeDuplicationType(e.target.value as CreativeDuplicateType);
  };

  const handleNameChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignName(e.target.value);
  };

  const handleCampaignDuplicationSave = () => {
    if (isButtonDisabled) return;

    onSave({
      entity: DuplicateEntity.CAMPAIGN,
      entityId: campaign?.id as string,
      names: [campaignName],
      creativeDuplicateOptions: creativeDuplicationType,
    });
  };

  useEffect(() => {
    setIsButtonDisabled(creativeDuplicationType && campaignName ? false : true);
  }, [creativeDuplicationType, campaignName]);

  return (
    <ModalWrapper>
      <ModalOverlay onClick={() => toggleModal(false)} />
      <ModalContainer>
        <ModalHeader justify='flex-start'>
          <InfoText color={theme.color.gray3}>Kampány duplikálása</InfoText>
          <IconButton
            size='lg'
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            icon={faTimesCircle}
            color={theme.color.black}
            hoverColor={theme.color.gray3}
            onClick={() => toggleModal(false)}
          />
        </ModalHeader>
        <ModalBody>
          <H1 style={{ fontSize: theme.fontsize.medium }}>
            Válaszd ki mely értékek legyenek másolva a{' '}
            <I>
              <B>{campaign.name}</B>
            </I>
            -ból
          </H1>

          <H1 style={{ fontSize: theme.fontsize.small, marginTop: theme.space.small }}>Kreatívok másolása:</H1>
          <Flex style={{ marginTop: theme.space.half }} justify='space-between'>
            <Label>
              <RadioInput
                type='radio'
                name='creative_options'
                value={CreativeDuplicateType.DO_NOT_DUPLICATE}
                onChange={handleRadioChangEvent}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Ne másolja</Span>
            </Label>

            <Label>
              <RadioInput
                type='radio'
                name='creative_options'
                value={CreativeDuplicateType.WITH_FILES}
                onChange={handleRadioChangEvent}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Kreatív sorok (fájlokkal)</Span>
            </Label>

            <Label>
              <RadioInput
                type='radio'
                name='creative_options'
                value={CreativeDuplicateType.WITHOUT_FILES}
                onChange={handleRadioChangEvent}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Kreatív sorok (fájl nélkül)</Span>
            </Label>
          </Flex>

          <InfoBlock>
            <FontAwesomeIcon
              icon={faInfoCircle}
              color={theme.color.primary}
              style={{ marginRight: theme.space.tiny }}
            />
            <Span style={{ fontSize: theme.fontsize.log }}>
              A kreatívoknál beállított dátumok nem kerülnek másolásra
            </Span>
          </InfoBlock>

          <H1 style={{ fontSize: theme.fontsize.medium, marginTop: theme.space.medium }}>Kampány elnevezése</H1>

          <NameInput
            type='text'
            placeholder='Kampány elnevezése'
            value={campaignName}
            onChange={handleNameChangeEvent}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ border: `1px solid ${theme.color.dark}`, padding: `${theme.space.half} ${theme.space.tiny}` }}
            bgcolor={theme.color.white}
            color={theme.color.dark}
            onClick={() => toggleModal(false)}
          >
            Mégsem
          </Button>
          <Button
            style={{ padding: `${theme.space.half} ${theme.space.tiny}` }}
            disabled={isButtonDisabled}
            bgcolor={isButtonDisabled ? theme.color.gray2 : theme.color.primary}
            hoverColor={isButtonDisabled ? theme.color.gray1 : theme.color.primary}
            color={theme.color.white}
            onClick={handleCampaignDuplicationSave}
          >
            {isLoading ? <Loader type='ThreeDots' color={theme.color.white} height='auto' width={50} /> : 'Létrehozás'}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default CampaignDuplicationModal;
