import React, { MouseEventHandler, useRef, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useOnClickOutside from 'use-onclickoutside';

import DropdownButtonItems from './DropdownButtonItems';
import MainButton from './MainButton';

type DropdownButtonProps = {
  addCreative: () => void;
  addMultipleCreative: MouseEventHandler<Element>;
  selectCreatives: () => void;
  addArticle: () => void;
};

const DropdownButton = (props: DropdownButtonProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setOpen(false));

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div ref={dropdownRef}>
      <MainButton icon={faPlus} onClick={() => handleClick()}>
        Hozzáadás
      </MainButton>
      {open && <DropdownButtonItems {...props} handleClose={() => setOpen(false)} />}
    </div>
  );
};

export default DropdownButton;
