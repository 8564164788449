import React, { useMemo } from 'react';
import Creative from '../models/Creative';

import getCreativeColumns from '../components/Columns/getCreativeColumns';
import Column from '../components/Table/Column';
import { DropdownOption } from '../components/Editable/Dropdown';
import Actions from '../enums/DropdownActions';
import CampaignStatus from '../enums/CampaignStatus';

type UseCreativeColumnsProps = {
  campaignStatus: CampaignStatus | undefined;
  updateCreative: (creative: Creative) => void;
  openMatcherErrorModal: (creative: Creative) => void;
  handleActionSelected: (option: DropdownOption<Actions>, medium: Creative) => void;
};

const useCreativeColumns = ({
  campaignStatus,
  updateCreative,
  openMatcherErrorModal,
  handleActionSelected,
}: UseCreativeColumnsProps) => {
  const columns: Column<Creative>[] = getCreativeColumns({
    campaignStatus,
    updateCreative,
    openMatcherErrorModal,
    handleActionSelected,
  });

  return columns;
};

export default useCreativeColumns;
