import { styled } from '../../config/Theme';
export type InnerBadgeProps = {
  selected?: boolean;
  selectedColor?: string;
  bgColor?: string;
  color?: string;
  formatBadge?: boolean;
};

export default styled.div<InnerBadgeProps>`
  display: inline-block;
  background-color: ${(props) =>
    props.selected ? props.selectedColor || props.theme.color.white : props.bgColor || props.theme.color.gray2};
  color: ${(props) => props.color || props.theme.color.black};
  border-radius: 5px;
  padding: ${(props) => props.theme.space.quarter};
  box-shadow: ${(props) => props.selected && `0px 1px 2px ${props.theme.color.shadow}`};
  border: ${(props) => props.selected && props.formatBadge && '1px solid black'};

  ${(props) => (props.selected && !props.formatBadge ? 'cursor: default;' : 'cursor: pointer;')};
`;
