import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from '../../config/Theme';

export type IconButtonProps = {
  icon: IconDefinition;
  color?: string;
  hoverColor?: string;
  size?: SizeProp;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const IconButtonStyled = styled.span<IconButtonProps>`
  color: ${(props) => props.color || props.theme.color.gray3};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.hoverColor || props.theme.color.secondary};
  }

  &:active {
    color: ${(props) => props.color || props.theme.color.gray3};
  }
`;

const IconButton = (props: IconButtonProps): JSX.Element => (
  <IconButtonStyled {...props}>
    <FontAwesomeIcon icon={props.icon} size={props.size} />
  </IconButtonStyled>
);

export default IconButton;
