import { styled } from '../../config/Theme';

export default styled.div<{ justify?: 'center' | 'space-between' | 'flex-start' | 'flex-end' | 'space-around' }>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  overflow: hidden;
  justify-content: ${(props) => props.justify || 'center'};
`;
