import React from 'react';
import ActionModalContent from '../Cards/ActionModalContent';
import Modal from '../Cards/Modal';
import { H2 } from '../Typography';
import { Grid } from './Grid';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../config/Theme';

type ErrorModalProps = {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  description: string;
};

const ErrorModal = ({ isOpen, toggle, title, description }: ErrorModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ActionModalContent
        onPositive={toggle}
        positiveText='Beállítok URL-t'
        oneOption={true}
        marginBottom={''}
        marginTop={''}
      >
        <Grid
          style={{
            justifyItems: 'center',
          }}
        >
          <H2>{title}</H2>
          <p>{description}</p>
          <FontAwesomeIcon icon={faTriangleExclamation} color={theme.color.danger} size='4x' />
        </Grid>
      </ActionModalContent>
    </Modal>
  );
};

export default ErrorModal;
