import React, { useCallback, useMemo } from 'react';
import { theme } from '../../config/Theme';
import CreativeType, { CreativeTypeNames } from '../../enums/CreativeType';
import useModal from '../../hooks/useModal';
import Creative from '../../models/Creative';
import Toaster from '../../utils/Toaster';
import CreativeTypeOptionItem from '../Badge/CreativeTypeOptionItem';
import DropDownBadge, { DDBadge } from '../Badge/DropDownBadge';
import Flex from '../Spacing/Flex';
import { DropdownOption } from './Dropdown';

type CreativeTypeDropdownProps = {
  wide?: boolean;
  creative?: Creative;
  updateCreative: (creative: Creative) => void;
};

const CreativeTypeDropdown = (props: CreativeTypeDropdownProps): JSX.Element => {
  const [isOpen, toggle] = useModal();

  const hasUploaded = useMemo<boolean>(
    () => props.creative?.variations?.some((v) => v.bannerUploaded) ?? false,
    [props.creative?.variations]
  );

  const creativeTypeOptions = useMemo(
    () =>
      Object.values(CreativeType)
        ?.filter((t) => t !== 'article')
        .map<DropdownOption<CreativeType>>((t) => {
          return Object.create({
            value: t,
            label: CreativeTypeNames.get(t),
            selected: t === props.creative?.type,
          } as DropdownOption<CreativeType>);
        }),
    [props.creative?.type]
  );

  const handleCreativeTypeSelected = useCallback(
    (e: React.MouseEvent, option: DropdownOption<CreativeType>) => {
      if (!option || !option.value) {
        Toaster.error('Hibás kreatív típus!');
        return;
      }

      props.updateCreative({
        ...props.creative,
        type: option.value,
      });
    },
    [props]
  );

  const renderDropdownBadge = (options: DropdownOption<CreativeType>[]): JSX.Element => {
    return (
      <DropDownBadge
        options={creativeTypeOptions ?? []}
        isOpen={isOpen}
        toggle={toggle}
        wide={props.wide}
        width={theme.space.extra_huge}
        alt={CreativeTypeNames.get(props.creative?.type ?? CreativeType.STATIC)}
      >
        {options?.map((o, i) => (
          <CreativeTypeOptionItem key={i} option={o} onSelected={(e) => handleCreativeTypeSelected(e, o)} />
        ))}
      </DropDownBadge>
    );
  };

  const renderDropdown = (): JSX.Element => {
    if (!props.creative) {
      return (
        <DDBadge bgColor={theme.color.gray2} wide={props.wide} width={theme.space.extra_huge}>
          <Flex row justify='center' align='center'>
            {CreativeTypeNames.get(CreativeType.STATIC)}
          </Flex>
        </DDBadge>
      );
    }

    if (hasUploaded) {
      // Handle static banners
      if (props.creative.type === CreativeType.STATIC) {
        return renderDropdownBadge([
          Object.create({
            value: CreativeType.STATIC,
            label: CreativeTypeNames.get(CreativeType.STATIC),
            selected: true,
          } as DropdownOption<CreativeType>),
          Object.create({
            value: CreativeType.INTERSTITIAL_STATIC,
            label: CreativeTypeNames.get(CreativeType.INTERSTITIAL_STATIC),
            selected: false,
          } as DropdownOption<CreativeType>),
        ]);
      }
      if (props.creative.type === CreativeType.INTERSTITIAL_STATIC) {
        return renderDropdownBadge([
          Object.create({
            value: CreativeType.STATIC,
            label: CreativeTypeNames.get(CreativeType.STATIC),
            selected: false,
          } as DropdownOption<CreativeType>),
          Object.create({
            value: CreativeType.INTERSTITIAL_STATIC,
            label: CreativeTypeNames.get(CreativeType.INTERSTITIAL_STATIC),
            selected: true,
          } as DropdownOption<CreativeType>),
        ]);
      }

      // Handle dynamic banners
      if (props.creative.type === CreativeType.DYNAMIC) {
        return renderDropdownBadge([
          Object.create({
            value: CreativeType.DYNAMIC,
            label: CreativeTypeNames.get(CreativeType.DYNAMIC),
            selected: true,
          } as DropdownOption<CreativeType>),
          Object.create({
            value: CreativeType.INTERSTITIAL_DYNAMIC,
            label: CreativeTypeNames.get(CreativeType.INTERSTITIAL_DYNAMIC),
            selected: false,
          } as DropdownOption<CreativeType>),
        ]);
      }
      if (props.creative.type === CreativeType.INTERSTITIAL_DYNAMIC) {
        return renderDropdownBadge([
          Object.create({
            value: CreativeType.DYNAMIC,
            label: CreativeTypeNames.get(CreativeType.DYNAMIC),
            selected: false,
          } as DropdownOption<CreativeType>),
          Object.create({
            value: CreativeType.INTERSTITIAL_DYNAMIC,
            label: CreativeTypeNames.get(CreativeType.INTERSTITIAL_DYNAMIC),
            selected: true,
          } as DropdownOption<CreativeType>),
        ]);
      }

      return (
        <DDBadge bgColor={theme.color.gray2} wide={props.wide} width={theme.space.extra_huge}>
          <Flex row justify='center' align='center'>
            {CreativeTypeNames.get(props.creative.type ?? CreativeType.STATIC)}
          </Flex>
        </DDBadge>
      );
    } else {
      return renderDropdownBadge(creativeTypeOptions);
    }
  };

  return props.creative?.type === CreativeType.ARTICLE ? (
    <DDBadge bgColor={theme.color.gray2} width={theme.space.medium}>
      <Flex row justify='center' align='center'>
        {CreativeTypeNames.get(props.creative?.type)}
      </Flex>
    </DDBadge>
  ) : (
    renderDropdown()
  );
};

export default CreativeTypeDropdown;
