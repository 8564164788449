import { styled } from '../../config/Theme';
import Card from './Card';

export default styled(Card)`
  width: 20vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
