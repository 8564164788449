import React from 'react';
import { styled, theme } from '../../config/Theme';
import PageWrapper from '../Layout/PageWrapper';

const CardPage = styled(PageWrapper)`
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const CardPageWrapper = (props: any): JSX.Element => (
  <CardPage bgColor={theme.color.primary}>{props.children}</CardPage>
);

export default CardPageWrapper;
