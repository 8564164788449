import { useState } from 'react';

const useDelayed = <T>(callback: (arg: T) => void, delay: number): ((arg: T) => void) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  return (arg: T) => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        callback(arg);
      }, delay)
    );
  };
};
export default useDelayed;
