import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Card from './Card';

const SpacedCardStyled = styled(Card)`
  width: calc(100% - ${(props) => props.theme.space.tiny} * 2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    margin: ${(props) => props.theme.space.tiny} 0;
  }
`;

const SpacedCard = (props: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return <SpacedCardStyled>{props.children}</SpacedCardStyled>;
};

export default SpacedCard;
