import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-with-locales-es6';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Download from '../components/Button/Download';
import HollowButton from '../components/Button/HollowButton';
import GridCard from '../components/Cards/GridCard';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import { Grid } from '../components/Layout/Grid';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import RemoteImage from '../components/Layout/RemoteImage';
import Title from '../components/Layout/Title';
import Link from '../components/Link/Link';
import ActionContainer from '../components/Spacing/ActionContainer';
import ColumnWrapper from '../components/Spacing/ColumnWrapper';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import RowWrapper from '../components/Spacing/RowWrapper';
import { H1, Muted, Small } from '../components/Typography';
import { styled, theme } from '../config/Theme';
import useAPI from '../hooks/useAPI';
import Creative from '../models/Creative';
import Medium from '../models/Medium';
import Screenshot from '../models/Screenshot';
import ScreenshotsLightbox from '../components/Layout/ScreenshotsLightbox';

export const ImageLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
`;

const ScreenshotsPage: FunctionComponent = () => {
  const searchParams = new URLSearchParams(useLocation().search);

  if (!searchParams.has('creative_id')) {
    location.href = '/error';
  }

  const creativeId = searchParams.get('creative_id');

  const [{ data: creative }, getCreative] = useAPI<Creative>(`/creatives/${creativeId}`, { manual: true });

  const [, getScreenshots] = useAPI<Screenshot[]>({}, { manual: true });

  const [screenshots, setScreenshots] = useState<Screenshot[]>([]);

  const [{ data: medium }, getMedium] = useAPI<Medium>({}, { manual: true });

  const [selectedImage, setSelectedImage] = useState<number>(-1);

  const goToNext = () => {
    if (selectedImage < screenshots.length - 1) {
      setSelectedImage(selectedImage + 1);
    } else {
      setSelectedImage(0);
    }
  };

  const goToPrev = () => {
    if (selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    } else {
      setSelectedImage(screenshots.length - 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: creative } = await getCreative();
      if (!creative?.mediumId) location.href = '/error';

      await getMedium({ url: `/mediums/${creative?.mediumId}` });

      const { data: screenshotRaw } = await getScreenshots({
        url: `/screenshots?variation_ids=${creative?.variations?.map((v) => v.id).join(',')}&status=success`,
      });

      const sorted = screenshotRaw.sort((a, b) => moment(b.created).diff(a.created));
      setScreenshots(sorted);
    };

    fetchData();
  }, []);

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <ActionContainer alignment='start'>
              <HollowButton icon={faArrowLeft} onClick={(e) => (location.href = `/creatives?mediumId=${medium?.id}`)}>
                Vissza
              </HollowButton>
            </ActionContainer>
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <H1>
                {medium?.name}
                {!!(creative?.width && creative.height) && ` / ${creative?.width}x${creative?.height}`}
              </H1>
            </ActionContainer>
          </Title>

          <Title>
            <ColumnWrapper>
              <Grid width='20rem' height='17rem'>
                {screenshots &&
                  screenshots.map((s, i) => (
                    <ColumnWrapper key={s.id}>
                      <GridCard width='20rem' height='17rem'>
                        <ColumnWrapper justify='space-between'>
                          <RemoteImage source={`/screenshots/${s.id}/binary`} mimeType={s.mimeType}>
                            <ImageLayer onClick={() => setSelectedImage(i)} />
                          </RemoteImage>

                          <RowWrapper justify='space-between'>
                            <Muted>{moment(s.created).format('YYYY.MM.DD.')}</Muted>

                            <Download url={`/screenshots/${s.id}/download`}>
                              <Link>
                                <Small>Letöltés</Small>
                              </Link>
                            </Download>
                          </RowWrapper>
                        </ColumnWrapper>
                      </GridCard>
                    </ColumnWrapper>
                  ))}
              </Grid>
            </ColumnWrapper>
          </Title>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
      </Footer>

      {selectedImage >= 0 && (
        <ScreenshotsLightbox
          close={() => setSelectedImage(-1)}
          next={goToNext}
          prev={goToPrev}
          open={selectedImage >= 0}
          screenshot={selectedImage >= 0 ? screenshots[selectedImage] : null}
        />
      )}
    </>
  );
};

export default ScreenshotsPage;
