import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import HollowButton from '../components/Button/HollowButton';
import SearchSelect, { SelectOption } from '../components/Editable/SearchSelect';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import { Grid } from '../components/Layout/Grid';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import TableWrapper from '../components/Layout/TableWrapper';
import Title from '../components/Layout/Title';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import Flex from '../components/Spacing/Flex';
import Spinner from '../components/Spinner';
import FilterRow from '../components/Table/FilterRow';
import NewTable from '../components/Table/NewTable';
import PaginationContainer from '../components/Table/PaginationContainer';
import { H1, Small } from '../components/Typography';
import { theme } from '../config/Theme';
import useAPI from '../hooks/useAPI';
import useLogColumns from '../hooks/useLogColumns';
import Campaign from '../models/Campaign';
import Medium from '../models/Medium';
import User from '../models/User';
import UserEvent from '../models/UserEvent';

const LogPage: FunctionComponent = () => {
  const [campaignsResponse, getCampaigns] = useAPI('/campaigns', { manual: true });
  const [mediumsResponse, getMediums] = useAPI('/mediums', { manual: true });
  const [usersResponse, getUsers] = useAPI('/users', { manual: true });

  const [, getLogs] = useAPI('/userevents', { manual: true });
  const [filteredLogs, setFilteredLogs] = useState<UserEvent[]>([]);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [campaignFilter, setCampaignFilter] = useState<SelectOption | null>(null);
  const [mediumFilter, setMediumFilter] = useState<SelectOption | null>(null);
  const [userFilter, setUserFilter] = useState<SelectOption | null>(null);

  const [filteredMediums, setFilteredMediums] = useState<Medium[]>();

  useEffect(() => {
    fetchLogs(pageIndex, pageSize, campaignFilter?.value, mediumFilter?.value, userFilter?.value);
    getCampaigns({ params: { showEveryCampaign: true } });
    getUsers();
  }, [getCampaigns, getLogs, getMediums, getUsers]);

  useEffect(() => {
    fetchLogs(pageIndex, pageSize, campaignFilter?.value, mediumFilter?.value, userFilter?.value);
  }, [pageIndex, pageSize]);

  const fetchLogs = (offset: number, limit: number, campaignId?: string, mediumId?: string, userId?: string) => {
    const params: {
      offset: number;
      limit: number;
      campaign_id?: string;
      medium_id?: string;
      user_id?: string;
    } = { offset: offset * limit, limit };

    if (campaignId) params.campaign_id = campaignId;
    if (mediumId) params.medium_id = mediumId;
    if (userId) params.user_id = userId;

    getLogs({ params })
      .then((res) => {
        if (!res || !res.data) return;

        setTotalCount(res.data[1]);
        setFilteredLogs(res.data[0]);
      })
      .catch((err) => console.error(err));
  };

  const fetchMediums = (campaignId: string) => {
    getMediums({ params: { campaignId } })
      .then((res) => {
        if (!res || !res.data) return;
        setFilteredMediums(res.data[0]);
      })
      .catch((err) => console.error(err));
  };

  const selectSize = useMemo(() => `calc(100% - ${theme.space.small})`, []);

  const pageCount = useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  // Table column definitions
  const columns = useLogColumns();

  const handleCampaignSelect = (option: SelectOption | null) => {
    if (campaignFilter?.value === option?.value) return;

    setCampaignFilter(option);
    fetchLogs(pageIndex, pageSize, option?.value);
    setPageIndex(0);
    setMediumFilter(null);

    if (option) {
      fetchMediums(option.value);
    } else {
      setFilteredMediums([]);
    }
  };

  const handleMediumSelect = (option: SelectOption | null) => {
    if (mediumFilter?.value === option?.value) return;

    setMediumFilter(option);
    fetchLogs(pageIndex, pageSize, campaignFilter?.value, option?.value);
    setPageIndex(0);
  };

  const handleUserSelect = (option: SelectOption | null) => {
    if (userFilter?.value === option?.value) return;

    setUserFilter(option);
    fetchLogs(pageIndex, pageSize, campaignFilter?.value, mediumFilter?.value, option?.value);
    setPageIndex(0);
  };

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <HollowButton icon={faArrowLeft} onClick={() => (location.href = '/overview')}>
              Áttekintés
            </HollowButton>
          </NavHeader>

          <Title>
            <H1>Tevékenység napló</H1>
          </Title>

          <Title>
            <Grid height={theme.space.medium} width='25%'>
              <Flex column justify='center' align='flex-start'>
                <Small style={{ marginBottom: theme.space.half }}>Kampány</Small>
                <SearchSelect
                  width={selectSize}
                  placeholder='Összes'
                  value={campaignFilter}
                  onChange={(e) => handleCampaignSelect(e)}
                  options={
                    campaignsResponse.data &&
                    campaignsResponse.data[0] &&
                    campaignsResponse.data[0].map((c: Campaign) => {
                      return { label: c.name, value: c.id };
                    })
                  }
                />
              </Flex>
              <Flex column justify='center' align='flex-start'>
                <Small style={{ marginBottom: theme.space.half }}>Médium</Small>
                <SearchSelect
                  width={selectSize}
                  placeholder='Összes'
                  value={mediumFilter}
                  isDisabled={!campaignFilter}
                  onChange={(e) => handleMediumSelect(e)}
                  options={
                    filteredMediums
                      ? filteredMediums.map((c: Medium) => {
                          return { label: c.name as string, value: c.id as string };
                        })
                      : []
                  }
                />
              </Flex>
              <Flex column justify='center' align='flex-start'>
                <Small style={{ marginBottom: theme.space.half }}>Felhasználó</Small>
                <SearchSelect
                  width={selectSize}
                  placeholder='Összes'
                  value={userFilter}
                  onChange={(e) => handleUserSelect(e)}
                  options={
                    usersResponse.data &&
                    usersResponse.data.map((c: User) => {
                      return { label: c.email, value: c.id };
                    })
                  }
                />
              </Flex>
            </Grid>
          </Title>

          {(campaignsResponse.loading || mediumsResponse.loading || usersResponse.loading) && (
            <Flex justify='center' align='center'>
              <Spinner />
            </Flex>
          )}

          <TableWrapper>
            <NewTable<UserEvent>
              columns={columns}
              data={filteredLogs}
              idKey={'id'}
              cursor={'default'}
              filterRow={
                <FilterRow
                  totalCount={totalCount}
                  pageSize={pageSize}
                  name='tevékenység'
                  setPageSize={(size: number) => {
                    setPageSize(size);
                    setPageIndex(0);
                  }}
                />
              }
            />
          </TableWrapper>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
        <PaginationContainer pageIndex={pageIndex} pageCount={pageCount} setPageIndex={setPageIndex} />
      </Footer>
    </>
  );
};

export default LogPage;
