import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled, theme } from '../../config/Theme';
import UrlType, { UrlTypeNames } from '../../enums/UrlType';
import DeepUrl from '../../models/DeepUrl';
import Badge, { BadgeProps } from './Badge';

export type UrlBadgeProps = {
  onIconClick?: (e: React.MouseEvent) => void;
  icon?: IconDefinition;
  url?: DeepUrl;
} & BadgeProps;

const UrlBadgeStyled = styled(Badge)`
  max-width: calc(100% - ${(props) => props.theme.space.quarter} * 2);
  margin-left: 0;
  padding: ${(props) => props.theme.space.half} ${(props) => props.theme.space.lsmall};
`;

const UrlText = styled.a`
  font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  text-decoration: underline;
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
  color: inherit;
  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
  }
`;

const Container = styled.div<{ color?: string }>`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  > *:not(:first-child) {
    margin-left: ${(props) => props.theme.space.lsmall};
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  align-self: center;
  cursor: pointer;
`;

const UrlBadge = (props: UrlBadgeProps): JSX.Element => {
  return props.url ? (
    <UrlBadgeStyled bgColor={props.bgColor} border={props.border}>
      <Container color={props.color}>
        <div>
          <UrlText
            title={/* props.url.urlList?.join(', ') ?? */ props.url.rootUrl}
            href={props.url.rootUrl}
            target='_blank'
          >{`${props.url.rootUrl}`}</UrlText>
          <div style={{ fontSize: '12px', color: theme.color.black }}>{`${UrlTypeNames.get(
            props.url.urlType ?? UrlType.ALL
          )}`}</div>
        </div>

        {props.icon && <Icon color={theme.color.danger} icon={props.icon} onClick={props.onIconClick} />}
      </Container>
    </UrlBadgeStyled>
  ) : (
    <></>
  );
};

export default UrlBadge;
