import React, { useMemo } from 'react';
import Creative from '../../models/Creative';

import Flex from '../Spacing/Flex';
import Column from '../Table/Column';
import ResolutionDropdown from '../Editable/ResolutionDropdown';
import DateBadge from '../Badge/DateBadge';
import CreativeTypeDropdown from '../Editable/CreativeTypeDropdown';
import CampaignProgress from '../Badge/CampaignProgress';
import creativeTablePlatformAccessor from '../Provider/Creative/Cells/CreativeTablePlatformAccessor';
import CreativeTableVariations from '../Provider/Creative/Cells/CreativeTableVariations';
import creativeTableCampaignAccessor from '../Provider/Creative/Cells/CreativeTableCampaignAccessor';
import Dropdown, { DropdownOption } from '../Editable/Dropdown';
import Platform, { PlatformNames } from '../../enums/Platform';
import Actions from '../../enums/DropdownActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { theme } from '../../config/Theme';
import { faArrowUpRightFromSquare, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import CampaignStatus from '../../enums/CampaignStatus';
import CreativeCampaignType from '../../enums/CreativeCampaignType';

type GetCreativeColumnsProps = {
  campaignStatus: CampaignStatus | undefined;
  updateCreative: (creative: Creative) => void;
  openMatcherErrorModal: (creative: Creative) => void;
  handleActionSelected: (option: DropdownOption<Actions>, medium: Creative) => void;
};

const options: DropdownOption<Actions>[] = [
  {
    value: Actions.VIEW,
    label: 'Kreatív megtekintése',
    icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={theme.color.primary} />,
  },
  {
    value: Actions.DUPLICATE,
    label: 'Kreatív duplikálása',
    icon: <FontAwesomeIcon icon={faCopy} color={theme.color.primary} />,
    textColor: theme.color.primary,
  },
  {
    value: Actions.DELETE,
    label: 'Kreatív törlése',
    icon: <FontAwesomeIcon icon={faTrashAlt} color={theme.color.danger} />,
    textColor: theme.color.danger,
  },
];

const getCreativeColumns = ({
  campaignStatus,
  updateCreative,
  openMatcherErrorModal,
  handleActionSelected,
}: GetCreativeColumnsProps) => {
  const platformOptions: DropdownOption<Platform>[] = useMemo(
    () => Object.values(Platform).map((p) => ({ value: p, label: PlatformNames.get(p) || p })),
    []
  );

  const setPlatform = (platform: string, creative: Creative) => {
    if (!platform || !creative || creative.platform === (platform as Platform)) return;
    creative.platform = platform as Platform;
    updateCreative(creative);
  };

  const columns: Column<Creative>[] = [
    {
      id: 'size',
      label: 'Méret',
      width: 8,
      sortable: false,
      getter(creative: Creative) {
        return (
          <Flex row align='center' justify='center'>
            <ResolutionDropdown creative={creative} updateCreative={updateCreative} wrapContent={true} />
          </Flex>
        );
      },
    },
    {
      id: 'date',
      label: 'Dátum',
      width: 13,
      sortable: false,
      getter(creative: Creative) {
        return <DateBadge start={creative.start} end={creative.end} splitLine />;
      },
    },
    {
      id: 'variations',
      label: 'Kreatív',
      width: 13,
      sortable: false,
      getter(creative: Creative) {
        return <CreativeTableVariations creative={creative} />;
      },
    },
    {
      id: 'type',
      label: 'Típus',
      width: 11,
      sortable: false,
      getter(creative: Creative) {
        return (
          <Flex row align='center' justify='center'>
            <CreativeTypeDropdown creative={creative} updateCreative={updateCreative} />
          </Flex>
        );
      },
    },
    {
      id: 'campaign',
      label: 'Kampány',
      width: 5,
      sortable: false,
      getter(creative: Creative) {
        return creativeTableCampaignAccessor(creative);
      },
    },
    {
      id: 'platform',
      label: 'Platform',
      width: 8,
      sortable: false,
      getter(creative: Creative) {
        return creativeTablePlatformAccessor(creative, platformOptions, setPlatform);
      },
    },
    {
      id: 'daysElapsed',
      label: 'Eltelt napok',
      width: 11,
      sortable: false,
      getter(creative: Creative) {
        return creative.daysElapsed;
      },
    },
    {
      id: 'progress',
      label: 'Megjelent napok',
      sortable: false,
      width: 10,
      getter(creative: Creative) {
        if (creative.campaign === CreativeCampaignType.TIME && creative.variations && creative.variations?.length > 1) {
          let successVariations = 0;

          creative.variations.forEach((v) => {
            if (v?.matchNumber && v.matchNumber > 0) {
              successVariations++;
            }
          });

          if (successVariations > 0 && creative.errorState !== 'noErrors') {
            return (
              <Flex justify='center' align='center'>
                <CampaignProgress
                  errorState={'errorsSeen'}
                  totalDays={creative.daysProposed ?? 0}
                  daysPassed={creative.successCount ?? 0}
                  onClick={() => openMatcherErrorModal(creative)}
                />
              </Flex>
            );
          }
        }

        return (
          <Flex justify='center' align='center'>
            <CampaignProgress
              errorState={creative.errorState || 'noErrors'}
              totalDays={creative.daysProposed ?? 0}
              daysPassed={creative.successCount ?? 0}
              onClick={() => openMatcherErrorModal(creative)}
            />
          </Flex>
        );
      },
    },
    {
      id: 'matches',
      label: 'Találatok',
      sortable: false,
      width: 10,
      getter(creative: Creative) {
        return (
          <Flex justify='center' align='center'>
            {creative.matchNumber}
          </Flex>
        );
      },
    },
    {
      id: 'action',
      label: '',
      sortable: false,
      width: 10,
      getter(creative: Creative) {
        return (
          <Dropdown
            options={options}
            onSelected={(option: DropdownOption<Actions>) => handleActionSelected(option, creative)}
          >
            <FontAwesomeIcon icon={faEllipsisV} color={theme.color.primary} style={{ cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  return columns;
};

export default getCreativeColumns;
