import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import UrlType, { UrlTypeNames, UrlTypeTooltips } from '../../enums/UrlType';
import IconButton from '../Button/IconButton';

const Tooltip = styled.p`
  text-align: left;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: -85px;
  color: white;
  background-color: ${theme.color.dark};
  padding: 16px;
  border-radius: 5px;
  width: max-content;
  max-width: 300px;
  font-size: 12px;
  z-index: 320;
`;

const ArrowIcon = styled.div`
  content: '';
  position: absolute;
  visibility: hidden;
  top: -15px;
  right: 0%;
  margin-right: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: ${theme.color.dark} transparent transparent transparent;
  z-index: 320;
`;

const Wrapper = styled.div`
  :hover ${Tooltip} {
    visibility: visible;
  }
  :hover ${ArrowIcon} {
    visibility: visible;
  }
`;

const Container = styled.div`
  position: relative;
  gap: 2px;
  display: flex;
`;

type UrlTypeBadgeProps = {
  id: string;
  selected?: boolean;
  handleSelect?: () => void;
  urlType: UrlType;
};

const UrlTypeBadge = ({ id, selected, handleSelect, urlType }: UrlTypeBadgeProps) => {
  return (
    <Container>
      <span>{UrlTypeNames.get(urlType)}</span>
      <Wrapper>
        <IconButton icon={faInfoCircle} />
        <ArrowIcon>
          <Tooltip>{UrlTypeTooltips.get(urlType)}</Tooltip>
        </ArrowIcon>
      </Wrapper>
    </Container>
  );
};

export default UrlTypeBadge;
