import { faArrowLeft, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import HollowButton from '../components/Button/HollowButton';
import MainButton from '../components/Button/MainButton';
import UserGroupCard from '../components/Cards/UserGroupCard';
import SearchBox from '../components/Editable/SearchBox';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import HalfColumn from '../components/Layout/HalfColumn';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import Title from '../components/Layout/Title';
import ActionContainer from '../components/Spacing/ActionContainer';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import { H1 } from '../components/Typography';
import { theme } from '../config/Theme';
import useAPI from '../hooks/useAPI';
import useDelayed from '../hooks/useDelayed';
import User from '../models/User';
import UserGroup from '../models/UserGroup';
import Toaster from '../utils/Toaster';

const UserGroupsPage: FunctionComponent = () => {
  const [groups, setGroups] = useState<UserGroup[]>();

  const [filteredGroups, setFilteredGroups] = useState<UserGroup[]>();

  const [getGroupsResponse, getGroups] = useAPI('/groups', { manual: true });

  const [, getMe] = useAPI({ url: '/me', method: 'GET' }, { manual: true });
  const [me, setMe] = useState<User>();

  const [createGroupReponse, createGroup] = useAPI({ url: '/groups', method: 'POST' }, { manual: true });

  const [deleteResponse, deleteGroup] = useAPI({ method: 'DELETE' }, { manual: true });

  useEffect(() => {
    getGroups()
      .then((res) => {
        setGroups(res.data);
        setFilteredGroups(res.data);
      })
      .catch((err) => console.log(err));

    getMe()
      .then((res) => setMe(res.data))
      .catch((err) => console.error(err));
  }, []);

  const handleRemove = async (id?: string) => {
    if (!id) return;
    deleteGroup({ url: `/groups/${id}` })
      .then((res) => {
        setGroups(groups?.filter((g) => g.id !== id));
        setFilteredGroups(filteredGroups?.filter((g) => g.id !== id));
        Toaster.success('Sikeres törlés!');
      })
      .catch((err) => {
        console.error(err);
        Toaster.error('Sikertelen törlés!');
      });
  };

  const newGroup = async () => {
    const group = new UserGroup();

    if (groups && groups?.filter((g) => g.name === 'Új csoport').length != 0) {
      let iterator = 1;
      while (groups.filter((g) => g.name === 'Új csoport' + iterator).length > 0) iterator += 1;

      group.name = 'Új csoport' + iterator;
    } else {
      group.name = 'Új csoport';
    }

    const res = await createGroup({ data: group });
    location.href = `/groups/${res.data.id}`;
  };

  // Delayed filter to decrease load
  const filterDelayed = useDelayed<string>((term: string) => {
    const filtered = groups?.filter((g) => {
      return g.name?.includes(term) || g.users?.filter((u) => u.email?.includes(term))?.length;
    });

    setFilteredGroups(filtered);
  }, 200);

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <HollowButton icon={faArrowLeft} onClick={(e: any) => (location.href = '/overview')}>
              Áttekintés
            </HollowButton>
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <H1>Felhasználói csoportok</H1>
            </ActionContainer>
            <ActionContainer alignment='end'>
              <MainButton icon={faUserPlus} onClick={(e) => newGroup()}>
                Csoport létrehozása
              </MainButton>
            </ActionContainer>
          </Title>

          <HalfColumn>
            <SearchBox
              placeholder='Csoport, felhasználó'
              bgColor={theme.color.white}
              onChange={(e) => filterDelayed(e.target.value)}
            />
            {filteredGroups?.map((group) => {
              return <UserGroupCard key={group.id} group={group} me={me} removeGroup={handleRemove} />;
            })}
          </HalfColumn>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
      </Footer>
    </>
  );
};

export default UserGroupsPage;
