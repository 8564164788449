/* eslint-disable react/prop-types */
import React from 'react';
import { styled, theme } from '../../config/Theme';
import ButtonComponent from './Button';

const LoginButton = styled(ButtonComponent)`
  width: 100%;
  height: ${(props) => props.theme.space.large};
  padding: ${(props) => props.theme.space.tiny};
  margin-top: ${(props) => props.theme.space.small};
`;

const LoginButtonComponent = (props: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <LoginButton
    onClick={props?.onClick}
    color={theme.color.white}
    bgcolor={theme.color.primary}
    hoverColor={theme.color.secondary}
  >
    {props?.children}
  </LoginButton>
);

export default LoginButtonComponent;
