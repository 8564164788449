import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState, useEffect, ReactNode } from 'react';
import Loader from 'react-loader-spinner';
import { theme } from '../../config/Theme';
import {
  CreativeDuplicateType,
  DuplicateBodyType,
  DuplicateEntity,
  MediumDuplicateType,
} from '../../enums/Duplication';
import Medium from '../../models/Medium';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InfoText,
  Label,
  RadioInput,
  InfoBlock,
  NameInput,
} from '../Cards/DuplicationModal';
import Flex from '../Spacing/Flex';
import { B, H1, I, Span } from '../Typography';

export type MediumDuplicationModalProps = {
  medium: Medium;
  toggleModal: (state?: boolean) => void;
  onSave: (duplicationRequestBody: DuplicateBodyType) => void;
  isLoading: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const MediumDuplicationModal: FC<MediumDuplicationModalProps> = ({
  toggleModal,
  medium,
  onSave,
  isLoading,
}): JSX.Element => {
  const [mediumNameInputs, setMediumNameInputs] = useState<{ value: string }[]>([{ value: '' }]);
  const [mediumDuplicationOptions, setMediumDuplicationOptions] = useState<MediumDuplicateType>({
    params: false,
    urls: false,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [creativeDuplicationType, setCreativeDuplicationType] = useState<CreativeDuplicateType>();
  const [creativeDuplicationAttrFiles, setCreativeDuplicationAttrFiles] = useState<boolean>(false);
  const [creativeDuplicationAttrDates, setCreativeDuplicationAttrDates] = useState<boolean>(false);

  const handleRadioChangEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreativeDuplicationType(e.target.value as CreativeDuplicateType);
  };

  const handleInputFieldAppending = () => {
    setMediumNameInputs((inputs) => [...inputs, { value: '' }]);
  };

  const handleInputRemoval = (index: number) => {
    setMediumNameInputs(mediumNameInputs.filter((inputs, i) => i !== index));
  };

  const handleNameInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newMediumNameInputsCopy = [...mediumNameInputs];
    mediumNameInputs[index].value = e.target.value;
    setMediumNameInputs(newMediumNameInputsCopy);
  };

  const handleMediumDuplicationSave = () => {
    if (isButtonDisabled) return;

    const names = mediumNameInputs.filter((input) => input.value !== '').map((input) => input.value);

    onSave({
      entity: DuplicateEntity.MEDIUM,
      entityId: medium?.id as string,
      names: names,
      creativeDuplicateOptions: creativeDuplicationType,
      mediumDuplicateOptions: mediumDuplicationOptions,
    });
  };

  useEffect(() => {
    setIsButtonDisabled(creativeDuplicationType && mediumNameInputs.some((input) => input.value !== '') ? false : true);
  }, [creativeDuplicationType, mediumNameInputs]);

  useEffect(() => {
    if (creativeDuplicationType && creativeDuplicationType !== CreativeDuplicateType.DO_NOT_DUPLICATE) {
      if (creativeDuplicationAttrFiles && !creativeDuplicationAttrDates) {
        setCreativeDuplicationType(CreativeDuplicateType.WITH_FILES);
      } else if (!creativeDuplicationAttrFiles && creativeDuplicationAttrDates) {
        setCreativeDuplicationType(CreativeDuplicateType.WITH_DATES);
      } else if (creativeDuplicationAttrFiles && creativeDuplicationAttrDates) {
        setCreativeDuplicationType(CreativeDuplicateType.WITH_FILES_AND_DATES);
      } else {
        setCreativeDuplicationType(CreativeDuplicateType.WITHOUT_FILES);
      }
    }
  }, [creativeDuplicationAttrFiles, creativeDuplicationAttrDates]);

  return (
    <ModalWrapper>
      <ModalOverlay onClick={() => toggleModal(false)} />
      <ModalContainer>
        <ModalHeader justify='flex-start'>
          <InfoText color={theme.color.gray3}>Médium másolása</InfoText>
          <IconButton
            size='lg'
            style={{ marginLeft: 'auto' }}
            icon={faTimesCircle}
            color={theme.color.black}
            hoverColor={theme.color.gray3}
            onClick={() => toggleModal(false)}
          />
        </ModalHeader>
        <ModalBody>
          <H1 style={{ fontSize: theme.fontsize.medium }}>
            Válaszd ki mely értékek legyenek másolva a{' '}
            <I>
              <B>{medium.name}</B>
            </I>
            -ból
          </H1>

          <Flex style={{ marginTop: theme.space.tiny }}>
            <Label style={{ marginRight: theme.space.small }}>
              <RadioInput
                type='checkbox'
                checked={mediumDuplicationOptions.params}
                onChange={() =>
                  setMediumDuplicationOptions((prevState) => ({ ...prevState, params: !prevState.params }))
                }
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Leadási paraméterek</Span>
            </Label>

            <Label>
              <RadioInput
                type='checkbox'
                checked={mediumDuplicationOptions.urls}
                onChange={() => setMediumDuplicationOptions((prevState) => ({ ...prevState, urls: !prevState.urls }))}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>URL-ek</Span>
            </Label>
          </Flex>

          <H1 style={{ fontSize: theme.fontsize.small, marginTop: theme.space.small }}>
            Legyenek a kreatívok is átmásolása?
          </H1>

          <Flex style={{ marginTop: theme.space.tiny }}>
            <Label style={{ marginRight: theme.space.medium }}>
              <RadioInput
                type='radio'
                name='creative_options'
                value={CreativeDuplicateType.DO_NOT_DUPLICATE}
                onChange={handleRadioChangEvent}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Nem</Span>
            </Label>

            <Label>
              <RadioInput
                type='radio'
                name='creative_options'
                value={CreativeDuplicateType.WITHOUT_FILES}
                onChange={handleRadioChangEvent}
              />
              <Span style={{ fontSize: theme.fontsize.log }}>Igen</Span>
            </Label>
          </Flex>

          {creativeDuplicationType && creativeDuplicationType !== CreativeDuplicateType.DO_NOT_DUPLICATE && (
            <>
              <H1 style={{ fontSize: theme.fontsize.small, marginTop: theme.space.small }}>
                A kreatívok mely tulajdonságait másoljuk?
              </H1>

              <Flex style={{ marginTop: theme.space.tiny }}>
                <Label style={{ marginRight: theme.space.medium }}>
                  <RadioInput
                    type='checkbox'
                    checked={creativeDuplicationAttrFiles}
                    onChange={() => setCreativeDuplicationAttrFiles((prevState) => !prevState)}
                  />
                  <Span style={{ fontSize: theme.fontsize.log }}>Fájlok</Span>
                </Label>

                <Label>
                  <RadioInput
                    type='checkbox'
                    checked={creativeDuplicationAttrDates}
                    onChange={() => setCreativeDuplicationAttrDates((prevState) => !prevState)}
                  />
                  <Span style={{ fontSize: theme.fontsize.log }}>Dátumok</Span>
                </Label>
              </Flex>
            </>
          )}

          <H1 style={{ fontSize: theme.fontsize.medium, marginTop: theme.space.medium }}>Új médiumok</H1>
          <Flex column style={{ maxHeight: '200px', overflow: 'auto' }}>
            {mediumNameInputs.map((input, i) => (
              <Flex key={i} align='center' style={{ marginTop: theme.space.tiny }}>
                <NameInput
                  type='text'
                  placeholder='Médium megnevezése'
                  style={{ marginTop: 0 }}
                  value={input.value}
                  onChange={(e) => handleNameInputChange(i, e)}
                />
                {i > 0 && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    color={theme.color.gray3}
                    style={{ marginLeft: theme.space.tiny, cursor: 'pointer' }}
                    onClick={() => handleInputRemoval(i)}
                  />
                )}
              </Flex>
            ))}

            <Flex
              align='center'
              style={{ marginTop: theme.space.small, cursor: 'pointer' }}
              onClick={handleInputFieldAppending}
            >
              <FontAwesomeIcon icon={faPlus} color={theme.color.primary} style={{ marginRight: theme.space.tiny }} />
              <Span color={theme.color.primary}>Újabb médium hozzáadása</Span>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ border: `1px solid ${theme.color.dark}`, padding: `${theme.space.half} ${theme.space.tiny}` }}
            bgcolor={theme.color.white}
            color={theme.color.dark}
            onClick={() => toggleModal(false)}
          >
            Mégsem
          </Button>
          <Button
            style={{ padding: `${theme.space.half} ${theme.space.tiny}` }}
            disabled={isButtonDisabled}
            bgcolor={isButtonDisabled ? theme.color.gray2 : theme.color.primary}
            hoverColor={isButtonDisabled ? theme.color.gray1 : theme.color.primary}
            color={theme.color.white}
            onClick={handleMediumDuplicationSave}
          >
            {isLoading ? <Loader type='ThreeDots' color={theme.color.white} height='auto' width={50} /> : 'Létrehozás'}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default MediumDuplicationModal;
