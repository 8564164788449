import React, { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import moment, { Moment } from 'moment';

import useAPI from '../../hooks/useAPI';
import Daterange from '../../models/Daterange';

import Flex from '../Spacing/Flex';
import PaginationContainer from '../Table/PaginationContainer';
import DatePicker from '../Cards/DatePicker';
import { ScreenshotRow } from './ScreenshotRow';

const Button = styled.button<{ color?: string; bgColor?: string }>`
  height: calc(2rem + 0.5rem);
  padding: 0 1rem;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.9;
  color: ${(props) => (props.color ? props.color : '#000')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};

  &:hover {
    opacity: 1;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.75rem;
  height: 100%;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.5);
  }
`;

export type Screenshot = {
  id: string;
  url: string;
  platform: string;
  status: string;
  created: string;
  renderId: string;
  renderFilePath: string;
  campaign: {
    id: string;
    name: string;
    status: string;
  };
  medium: {
    id: string;
    name: string;
    type: string;
  };
  creative: {
    id: string;
    width: number;
    height: number;
    platform: string;
    type: string;
  };
  variation: {
    id: string;
  };
};

const pageSize = 10;
const ScreenshotTab: FC = (): JSX.Element => {
  const [, getScreenshots] = useAPI({ url: '/screenshots/screenshots-log-page', method: 'GET' }, { manual: true });
  const [status, setStatus] = useState<string>('');
  const [screenshots, setScreenshots] = useState<Screenshot[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
  const [idSearch, setIdSearch] = useState<string>('');

  const pageCount = useMemo(() => {
    if (totalCount === 0) return 1;
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const fetchScreenshots = async () => {
    const screenshotResponse = await getScreenshots({
      params: {
        offset: pageIndex,
        status,
        start: startDate ? formatDate(startDate) : undefined,
        end: endDate ? formatDate(endDate) : undefined,
        idSearchValue: idSearch,
      },
    });
    if (!screenshotResponse || !screenshotResponse.data) return;

    const screenshots = screenshotResponse.data[0].filter(
      (screenshot: Screenshot) =>
        screenshot.campaign && screenshot.creative && screenshot.medium && screenshot.variation
    );

    setScreenshots(screenshots);
    setTotalCount(screenshots.length > 0 ? screenshotResponse.data[1] : 0);
  };

  const handlePageIndexChange = (nextPageNumber: number) => {
    if (nextPageNumber < 0 || nextPageNumber > pageSize - 1) return;
    setPageIndex(nextPageNumber);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const toggleDatePicker = () => {
    setDatePickerOpen((prev) => !prev);
  };

  const onDatePickerfinish = (ranges: Daterange[], canceled?: boolean | undefined) => {
    toggleDatePicker();

    if (canceled || ranges.length === 0) return;

    const start = ranges[0].start;
    const end = ranges[ranges.length - 1].end;

    setStartDate(start);
    setEndDate(end);
  };

  const handleIdSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIdSearch(e.target.value);
  };

  const formatDate = (date: Moment | undefined) => {
    return moment(date).format('YYYY-MM-DD');
  };

  useEffect(() => {
    fetchScreenshots();
  }, [pageIndex, status, startDate, endDate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchScreenshots();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [idSearch]);

  return (
    <Flex style={{ flexWrap: 'wrap' }}>
      <Flex style={{ marginBottom: '1rem' }}>
        <Button
          color={status === '' ? theme.color.white : theme.color.primary}
          bgColor={status === '' ? theme.color.primary : theme.color.white}
          onClick={() => handleStatusChange('')}
          style={{ marginRight: '1rem' }}
        >
          All
        </Button>
        <Button
          color={status === 'failed' ? theme.color.white : theme.color.danger}
          bgColor={status === 'failed' ? theme.color.danger : theme.color.white}
          onClick={() => handleStatusChange('failed')}
          style={{ marginRight: '1rem' }}
        >
          Failed
        </Button>
        <Button
          color={status === 'success' ? theme.color.white : theme.color.success}
          bgColor={status === 'success' ? theme.color.success : theme.color.white}
          onClick={() => handleStatusChange('success')}
          style={{ marginRight: '1rem' }}
        >
          Success
        </Button>
        <Button
          color={status === 'waiting_for_match' ? theme.color.white : theme.color.warning}
          bgColor={status === 'waiting_for_match' ? theme.color.warning : theme.color.white}
          onClick={() => handleStatusChange('waiting_for_match')}
          style={{ marginRight: '1rem' }}
        >
          Waiting for match
        </Button>

        <Input placeholder='Screenshot id keresés' value={idSearch} onChange={handleIdSearchChange} />

        <Button onClick={toggleDatePicker} style={{ boxShadow: 'none' }}>
          {startDate && endDate ? `${formatDate(startDate)} / ${formatDate(endDate)}` : 'Válassz dátumot'}
        </Button>
      </Flex>

      {screenshots.length > 0 && (
        <>
          {screenshots.map((screenshot) => (
            <ScreenshotRow key={screenshot.id} screenshot={screenshot} />
          ))}

          <Flex justify='center' style={{ margin: '1rem 0' }}>
            <PaginationContainer pageCount={pageCount} setPageIndex={handlePageIndexChange} pageIndex={pageIndex} />
          </Flex>
        </>
      )}

      {datePickerOpen && (
        <DatePicker
          onFinish={onDatePickerfinish}
          ranges={startDate && endDate ? [{ start: startDate, end: endDate }] : []}
        />
      )}
    </Flex>
  );
};

export { ScreenshotTab };
