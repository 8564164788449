import { useState } from 'react';

const useModal: () => [boolean, (state?: boolean) => void] = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (state?: boolean) => {
    if (typeof state === 'boolean') {
      setIsOpen(state);
    } else {
      setIsOpen(!isOpen);
    }
  };

  return [isOpen, toggle] as [boolean, (state?: boolean) => void];
};

export default useModal;
