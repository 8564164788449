import { styled } from '../../config/Theme';

export type BadgeProps = {
  bgColor?: string;
  border?: string;
  color?: string;
  padding?: string;
};

export default styled.div<BadgeProps>`
  display: inline-block;
  background-color: ${(props) => props.bgColor || props.theme.color.white};
  color: ${(props) => props.color || props.theme.color.black};
  border-radius: 5px;
  padding: ${(props) => props.padding || '0.3rem'};
  cursor: default;
  border: ${(props) => props.border || 'none'};
`;
