import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import React, { ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import useAPI from '../../hooks/useAPI';
import { useDownload } from '../../hooks/useDownload';
import Campaign from '../../models/Campaign';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import Medium from '../../models/Medium';
import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';
import { Widget } from './CreativeModal';

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: 75%;
  max-height: 80%;
  min-height: 80%;
  position: fixed;
  z-index: 1060;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ModalBody = styled.div`
  flex: 1;
  display: flex;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - ${(props) => props.theme.space.large});
`;

const ModalFooter = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;

  > div {
    padding-top: ${(props) => props.theme.space.small};
    display: flex;
    width: 100%;

    button {
      margin-left: ${(props) => props.theme.space.small};
    }
  }
`;

export type RenderErrorSearchModalProps = {
  toggle: () => void;
};

export type PayloadType = {
  campaignId: string;
  mediumId: string;
  creativeId: string;
  dateRanges: string[];
};

const RenderErrorSearchModal = (props: RenderErrorSearchModalProps): ReactPortal | null => {
  const [campaignId, setCampaignId] = useState<string>('');
  const [mediumId, setMediumId] = useState<string>('');
  const [creativeId, setCreativeId] = useState<string>('');
  const [variationId, setVariationId] = useState<string>('');

  const [, getCampaigns] = useAPI<[Campaign[], number]>('/campaigns', { manual: true });
  const [, getMediums] = useAPI<[Medium[], number]>('/mediums', { manual: true });
  const [, getCreatives] = useAPI<[Creative[], number]>({}, { manual: true });
  const [, getVariation] = useAPI<CreativeVariation>({}, { manual: true });

  // const [campaigns, setCampaigns] = useState<SimpleDropdownOption[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  // const [mediums, setMediums] = useState<SimpleDropdownOption[]>([]);
  const [mediums, setMediums] = useState<Medium[]>([]);
  // const [creativeVariations, setCreativeVariations] = useState<SimpleDropdownOption[]>([]);
  const [creativeVariations, setCreativeVariations] = useState<CreativeVariation[]>([]);

  const [variationDates, setVariationDates] = useState<string[]>([]);

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  // eslint-disable-next-line max-len
  const downloadCreatives = useDownload({
    url: `/rendererror/creative/download?campaignId=${campaignId}&mediumId=${mediumId}&creativeId=${creativeId}&dateRanges=${selectedDates.join(
      ','
    )}`,
  });
  // eslint-disable-next-line max-len
  const downloadRender = useDownload({
    url: `/rendererror/render/download?campaignId=${campaignId}&mediumId=${mediumId}&creativeId=${creativeId}&dateRanges=${selectedDates.join(
      ','
    )}`,
  });

  useEffect(() => {
    getCampaigns({ params: { everyWithoutMatcherErrors: true } })
      .then((res) => {
        if (!res || !res.data) return;
        setCampaigns(res.data[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleCampaignSelect = (campaignId: string) => {
    setCampaignId(campaignId);
    getMediums({ params: { everyWithoutMatcherErrors: true, campaignId } })
      .then((res) => {
        if (!res || !res.data) return;
        setMediums(res.data[0]);
      })
      .catch((err) => console.error(err));

    setMediumId('');
    setVariationId('');
    setCreativeVariations([]);
    setSelectedDates([]);
  };

  const handleMediumSelect = (mediumId: string) => {
    const newVariations: CreativeVariation[] = [];
    setMediumId(mediumId);
    setVariationId('');
    setSelectedDates([]);

    getCreatives({ url: `/creatives?mediumId=${mediumId}` }).then((res) => {
      if (!res || !res.data) return;
      res.data[0].forEach((c: Creative) => {
        if (c.variations) {
          c.variations.forEach((v) => {
            newVariations.push(v);
          });
        }
      });
      setCreativeVariations(newVariations);
    });
  };

  const handleVariationSelect = (creativeId: string, variationId: string) => {
    setCreativeId(creativeId);
    setVariationId(variationId);
    setSelectedDates([]);
    getVariation({ url: `creatives/${creativeId}/variations/${variationId}` }).then((res) => {
      const newDates: string[] = [];
      if (res.data && res.data.dateRanges) {
        res.data.dateRanges.forEach((range) => {
          newDates.push(range.start + '|' + range.end);
        });
      }

      setVariationDates(newDates);
    });
  };

  return createPortal(
    <ModalWrapper>
      <ModalOverlay onClick={props.toggle} />
      <ModalStyled className='modal'>
        <ModalHeader>
          <Span color={theme.color.gray3}>Hibakeresés</Span>

          <IconButton
            size='lg'
            icon={faTimesCircle}
            color={theme.color.black}
            hoverColor={theme.color.gray3}
            onClick={props.toggle}
          />
        </ModalHeader>

        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Widget>
              <FormControl fullWidth sx={{ width: '14vw' }}>
                <InputLabel id='campaign'>Kampány</InputLabel>
                <Select
                  labelId='campaign'
                  label='Kampány'
                  value={campaignId}
                  onChange={(e) => handleCampaignSelect(e.target.value)}
                >
                  {campaigns.map((c: Campaign) => (
                    <MenuItem key={c.id} value={c.id} disabled={c?.mediums?.length === 0}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Widget>

            <Widget>
              <FormControl fullWidth sx={{ width: '14vw', visibility: campaignId !== '' ? 'visible' : 'hidden' }}>
                <InputLabel id='medium'>Médium</InputLabel>
                <Select
                  labelId='medium'
                  label='Médium'
                  value={mediumId}
                  onChange={(e) => handleMediumSelect(e.target.value)}
                >
                  {mediums.map((m: Medium) => (
                    <MenuItem key={m.id} value={m.id} disabled={m?.creatives?.length === 0}>
                      {m.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Widget>

            <Widget>
              <FormControl fullWidth sx={{ width: '14vw', visibility: mediumId !== '' ? 'visible' : 'hidden' }}>
                <InputLabel id='creatives'>Kreatív(ok)</InputLabel>
                <Select
                  labelId='creatives'
                  label='Kreatív(ok)'
                  value={variationId && creativeId ? variationId + '|' + creativeId : ''}
                  onChange={(e) => {
                    const [variationId, creativeId] = e.target.value.split('|');
                    handleVariationSelect(creativeId, variationId);
                  }}
                >
                  {creativeVariations.map((cv: CreativeVariation) => (
                    <MenuItem key={cv.id} value={cv.id + '|' + cv.creativeId}>
                      {cv.originalFilename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Widget>

            <Widget>
              <FormControl fullWidth sx={{ width: '14vw', visibility: variationId !== '' ? 'visible' : 'hidden' }}>
                <InputLabel id='dates'>Dátum</InputLabel>
                <Select
                  labelId='dates'
                  label='Dátum'
                  multiple={selectedDates.length > 0}
                  value={selectedDates}
                  onChange={(e) => {
                    setSelectedDates(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                  }}
                >
                  {variationDates.map((d: string) => (
                    <MenuItem key={d} value={d}>
                      {moment(d.split('|')[0]).format('YYYY.MM.DD') +
                        '-' +
                        moment(d.split('|')[1]).format('YYYY.MM.DD')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Widget>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: 'center', paddingBottom: 100 }}>
          <div style={{ justifyContent: 'center' }}>
            <MainButton onClick={downloadCreatives}>Kreatív letöltése</MainButton>

            <MainButton secondary onClick={downloadRender}>
              Render letöltése
            </MainButton>
          </div>
        </ModalFooter>
      </ModalStyled>
    </ModalWrapper>,
    document.body
  );
};

export default RenderErrorSearchModal;
