import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '../../config/Theme';
import Card from './Card';

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.color.gray3};
  opacity: 0.5;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalStyled = styled(Card)`
  max-width: 40%;
  position: fixed;
  z-index: 1060;
  padding: ${(props) => props.theme.space.medium};
`;

export type ModalProps = {
  isOpen: boolean;
  toggle: (state?: boolean) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const Modal = (props: ModalProps): ReactPortal | null =>
  props.isOpen
    ? ReactDOM.createPortal(
        <ModalWrapper>
          <ModalOverlay onClick={() => props.toggle(false)} />
          <ModalStyled className='modal'>{props.children}</ModalStyled>
        </ModalWrapper>,
        document.body
      )
    : null;

export default Modal;
