export type DuplicateBodyType = {
  entity: DuplicateEntity;
  entityId: string;
  names?: string[];
  creativeDuplicateOptions?: CreativeDuplicateType;
  mediumDuplicateOptions?: MediumDuplicateType;
};

export enum DuplicateEntity {
  CAMPAIGN = 'campaign',
  MEDIUM = 'medium',
  CREATIVE = 'creative',
}

export enum CreativeDuplicateType {
  DO_NOT_DUPLICATE = 'do_not_duplicate',
  WITH_FILES = 'with_files',
  WITHOUT_FILES = 'without_files',
  WITH_DATES = 'with_dates',
  WITH_FILES_AND_DATES = 'with_files_and_dates',
}

export type MediumDuplicateType = {
  params: boolean;
  urls: boolean;
};
