import React from 'react';
import moment from 'moment';
import { styled } from '../../config/Theme';

import type { MatcherErrorScreenshot } from '../Layout/MatcherErrorsModal';

import CreativeType, { CreativeTypeNames } from '../../enums/CreativeType';
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import { UrlTypeNames } from '../../enums/UrlType';
import { PlatformNames } from '../../enums/Platform';

import Flex from '../Spacing/Flex';
import Checkbox from '../Button/Checkbox';
import Link from '../Link/Link';
import Column from '../Table/Column';

const WordBreakCell = styled.span`
  white-space: normal;
  word-break: break-all;
`;

const ExcludedText = styled.span`
  color: ${(props) => props.theme.color.gray3};
  text-decoration: line-through;
  white-space: normal;
  word-break: break-all;
`;

const getMatcherErrorColumns = (
  selectAll: boolean,
  excludeAllScreenshot: () => void,
  mediumName: string,
  selectCreative: (creativeId: string) => void,
  excludeScreenshot: (id: string) => void
) => {
  const cols: Column<MatcherErrorScreenshot>[] = [
    {
      id: 'exclude',
      label: (
        <Flex justify='center' align='center'>
          <Checkbox checked={selectAll} onClick={excludeAllScreenshot} />
        </Flex>
      ),
      width: 5,
      sortable: false,
      getter(screenshot: MatcherErrorScreenshot) {
        return (
          <Flex justify='center' align='center'>
            <Checkbox checked={screenshot.exclude} onClick={() => excludeScreenshot(screenshot.id)} />
          </Flex>
        );
      },
    },
    {
      id: 'medium',
      label: 'Médium',
      width: 15,
      sortable: true,
      getter(screenshot) {
        return screenshot.exclude ? (
          <ExcludedText>{screenshot?.medium?.name ?? mediumName}</ExcludedText>
        ) : (
          <WordBreakCell>{screenshot?.medium?.name ?? mediumName}</WordBreakCell>
        );
      },
    },
    {
      label: 'Kreatív',
      id: 'size',
      width: 10,
      sortable: true,
      getter(screenshot) {
        const cellContent =
          screenshot.creative.type === CreativeType.ARTICLE
            ? CreativeTypeNames.get(CreativeType.ARTICLE)
            : `${screenshot.creative.width}x${screenshot.creative.height}`;

        return (
          <Flex justify='center' align='center'>
            {screenshot.exclude ? <ExcludedText>{cellContent}</ExcludedText> : cellContent}
          </Flex>
        );
      },
    },
    {
      label: 'URL',
      id: 'url',
      width: 25,
      sortable: true,
      getter(screenshot) {
        return screenshot.exclude ? (
          <ExcludedText>
            {screenshot.deepUrl.rootUrl} - {UrlTypeNames.get(screenshot.deepUrl.urlType)}
          </ExcludedText>
        ) : (
          <WordBreakCell>
            {screenshot.deepUrl.rootUrl} - {UrlTypeNames.get(screenshot.deepUrl.urlType)}
          </WordBreakCell>
        );
      },
    },
    {
      label: 'Típus',
      id: 'type',
      width: 10,
      sortable: true,
      getter(screenshot) {
        return screenshot.exclude ? (
          <ExcludedText>
            {CreativeCampaignTypeNames.get(screenshot?.medium?.type ?? CreativeCampaignType.TIME)}
          </ExcludedText>
        ) : (
          <WordBreakCell>
            {CreativeCampaignTypeNames.get(screenshot?.medium?.type ?? CreativeCampaignType.TIME)}
          </WordBreakCell>
        );
      },
    },
    {
      label: 'Platform',
      id: 'platform',
      width: 10,
      sortable: true,
      getter(screenshot) {
        return screenshot.exclude ? (
          <ExcludedText>{PlatformNames.get(screenshot.platform)}</ExcludedText>
        ) : (
          <WordBreakCell>{PlatformNames.get(screenshot.platform)}</WordBreakCell>
        );
      },
    },
    {
      label: 'Dátum',
      id: 'date',
      width: 10,
      sortable: true,
      getter(screenshot) {
        return (
          <Flex justify='center' align='center'>
            {screenshot.exclude ? (
              <ExcludedText>{moment(screenshot.day).format('YYYY.MM.DD.')}</ExcludedText>
            ) : (
              moment(screenshot.day).format('YYYY.MM.DD.')
            )}
          </Flex>
        );
      },
    },
    {
      label: '',
      id: 'link',
      width: 15,
      sortable: false,
      getter(screenshot: MatcherErrorScreenshot) {
        return <Link onClick={() => selectCreative(screenshot.creative.id)}>Kreatív megnyitása</Link>;
      },
    },
  ];

  return cols;
};

export default getMatcherErrorColumns;
