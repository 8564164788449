import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { styled, theme } from '../../config/Theme';

type DropdownProps = {
  visible: boolean;
};
const Component = styled.div<DropdownProps>`
  position: relative;
  height: 43px;
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  /* max-width: 340px; */
  box-sizing: border-box;
  z-index: ${(props) => (props.visible ? 1000 : 100)};
`;

const MainInput = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-radius: 5px;
  border: 1px solid #d3d6e8;
  padding: 12px 10px;
  position: relative;
  /* height: 2rem; */
  display: flex;
  align-items: center;

  span {
    user-select: none;
    cursor: default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    color: ${(props) => props.theme.color.black};
    flex: 1;
    font-weight: 500;
    font-family: 'Rubik';
    font-size: ${(props) => props.theme.fontsize.form};
  }

  svg {
    margin: 0 0.5rem 0 1rem;
    flex: 0;
  }
`;

const Dropdown = styled.div<DropdownProps>`
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.gray3};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.25rem;
  width: auto;
  right: 0;
  z-index: 1000;
  max-height: 10rem;
  overflow: auto;
`;

const ErrorMsg = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: ${(props) => props.theme.color.warningRed};
`;

const Option = styled.div`
  padding: 0.5rem 0.25rem;
  cursor: default;
  border-radius: 5px;
  text-overflow: ellipsis;
  word-wrap: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-family: 'Rubik';
  font-size: ${(props) => props.theme.fontsize.form};

  &:hover {
    background-color: ${(props) => props.theme.color.gray2};
  }
`;

export type SimpleDropdownOption = {
  id: string;
  label: string;
  default?: boolean;
};

const Label = styled.label`
  display: flex;
  font-size: ${(props) => props.theme.fontsize.form};
  flex-direction: column;
  gap: 10px;
`;

type SimpleDropdownProps = {
  label: string;
  placeholder?: string;
  errorMessage?: string;
  options: SimpleDropdownOption[];
  onChange: (selected: SimpleDropdownOption) => void;
};

const FormDropdown = (props: SimpleDropdownProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState<SimpleDropdownOption>();
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const defaultOption = props.options.find((option) => option.default === true);
    if (defaultOption) {
      setSelected(defaultOption);
    }
  }, [props.options]);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const select = (option: SimpleDropdownOption) => {
    setSelected(option);
    setOpen(false);
    props.onChange(option);
  };

  const close = () => {
    setOpen(false);
  };

  useOnClickOutside(componentRef, close);

  return (
    <Label>
      {props.label}
      <div>
        <Component ref={componentRef} visible={isOpen}>
          <MainInput onClick={toggle}>
            {selected ? (
              <span>{selected.label}</span>
            ) : (
              <span style={{ color: props.errorMessage ? theme.color.warningRed : theme.color.gray3 }}>
                {props.placeholder ?? '-'}
              </span>
            )}
            <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} color={theme.color.gray3}></FontAwesomeIcon>
          </MainInput>
          <Dropdown visible={isOpen}>
            {props.options.map((o) => (
              <Option key={o.id} onClick={() => select(o)} title={o.label}>
                {o.label}
              </Option>
            ))}
          </Dropdown>
        </Component>
        {props.errorMessage && <ErrorMsg>{props.errorMessage}</ErrorMsg>}
      </div>
    </Label>
  );
};

export default FormDropdown;
