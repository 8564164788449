import { AxiosResponse } from 'axios';
import { MouseEvent, MouseEventHandler } from 'react';
import { API } from './useAPI';

type UseUploadConfig = {
  multiple?: boolean;
  accept?: string | string[];
  url: string;
  onError?: (response: AxiosResponse<any>) => void;
};
export const useUpload = (config: UseUploadConfig): MouseEventHandler<Element> => {
  const handleUpload = async (files: File[]) => {
    const data = new FormData();

    files.forEach((f, i) => data.append(`file${i}`, f, f.name));

    const response = await API.post(config.url, data);

    if (!response || response.status >= 400) {
      if (config.onError) {
        config.onError(response);
      } else {
        console.error(`Failed to upload file, useUpload request returned with error code`);
        console.error(response);
      }
    }
  };

  return useGetFile({
    multiple: config.multiple,
    accept: config.accept,
    callback: handleUpload,
  });
};

type UseGetFileConfig = {
  multiple?: boolean;
  accept?: string | string[];
  callback?: (files: File[]) => void;
};
export const useGetFile = (config?: UseGetFileConfig): MouseEventHandler<Element> => {
  return (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    const handleUpload = () => {
      if (hiddenInput.files && hiddenInput.files.length != 0 && config?.callback) {
        config.callback(Array.from(hiddenInput.files));
      }
      document.documentElement.removeChild(hiddenInput);
    };

    const hiddenInput = document.createElement('input');
    hiddenInput.hidden = true;
    hiddenInput.type = 'file';
    hiddenInput.id = 'file';

    hiddenInput.multiple = config?.multiple ?? false;

    if (config?.accept) {
      hiddenInput.accept = Array.isArray(config.accept) ? config.accept.join(',') : config.accept;
    }

    hiddenInput.addEventListener('change', handleUpload);

    document.documentElement.appendChild(hiddenInput);
    hiddenInput.click();
  };
};
