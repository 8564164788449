import moment from 'moment';
import React from 'react';
import { Muted } from '../Typography';

const CopyrightComponent = (): JSX.Element => (
  <Muted>
    &copy; {moment().format('YYYY')} Dot Creative Kft.
    <br />
    <a href='https://forms.clickup.com/f/2dtd3-2143/MK2QIX0A768UC34SB4' target='blank'>
      Hiba vagy hiányzó funkció jelentése
    </a>
  </Muted>
);

export default CopyrightComponent;
