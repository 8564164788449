import type { MatcherErrorScreenshot } from '../components/Layout/MatcherErrorsModal';

import CreativeCampaignType from '../enums/CreativeCampaignType';
import CreativeType from '../enums/CreativeType';
import Platform from '../enums/Platform';
import Entity from '../interfaces/IEntity';
import CreativeVariation from './CreativeVariation';

export default class Creative implements Entity {
  constructor(
    public id?: string,
    public articleVariationError?: CreativeVariation[],
    public variationError?: CreativeVariation[],
    public start?: Date,
    public end?: Date,
    public width?: number,
    public height?: number,
    public duration?: number,
    public type?: CreativeType,
    public campaign?: CreativeCampaignType,
    public platform?: Platform,
    public variations?: CreativeVariation[],
    public mediumId?: string,
    public totalDays?: number,
    public daysProposed?: number,
    public matchNumber?: number,
    public successCount?: number,
    public progressColor?: 'success' | 'danger' | 'black',
    public matcherErrors?: MatcherErrorScreenshot[],
    public daysElapsed?: number,
    public errorState?: 'unseenErrors' | 'errorsSeen' | 'noErrors'
  ) {}
}
