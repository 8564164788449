import { faCheck, faExclamationTriangle, faInfo, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { toast } from 'react-toastify';
import { styled } from '../config/Theme';

export default {
  info: (message: string) => {
    toast(() => <ToastBody message={message} icon={faInfo} />);
  },

  success: (message: string) => {
    toast.success(() => <ToastBody message={message} icon={faCheck} />);
  },

  warn: (message: string) => {
    toast.warn(() => <ToastBody message={message} icon={faExclamationTriangle} />);
  },

  error: (message: string) => {
    toast.error(() => <ToastBody message={message} icon={faExclamationTriangle} />);
  },

  unknownError: () => {
    toast.error(() => <ToastBody message={'Ismeretlen hiba!'} icon={faExclamationTriangle} />);
  },
};

const Toast = styled.div`
  font-size: ${(props) => props.theme.fontsize.small};
  padding: ${(props) => props.theme.space.half};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & * {
    margin: 0px ${(props) => props.theme.space.half};
  }
`;

const ToastBody = (props: { message: string; icon: IconDefinition }) => {
  return (
    <Toast>
      <div>
        <FontAwesomeIcon icon={props.icon} />
        {props.message}
      </div>
    </Toast>
  );
};
