import React from 'react';
import { styled, theme } from '../../config/Theme';
import CampaignStatus, { CampaignStatusColors, CampaignStatusNames } from '../../enums/CampaignStatus';
import { ChildrenProps } from '../../utils/Types';

export type CampaignStatusProps = {
  status: CampaignStatus;
} & ChildrenProps;

const StatusCell = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

const CampaignStatusCell = (props: CampaignStatusProps): JSX.Element => (
  <StatusCell color={CampaignStatusColors.get(props.status) || theme.color.black}>
    {CampaignStatusNames.get(props.children)}
  </StatusCell>
);

export default CampaignStatusCell;
