import React from 'react';
import Column from './Column';
import { styled } from '../../config/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const HeadRow = styled.tr`
  font-size: ${(props) => props.theme.fontsize.tiny};
  height: ${(props) => props.theme.space.medium};
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray3};
  border: 1px solid ${(props) => props.theme.color.gray2};
`;

const Cell = styled.td<{ ellipsize?: boolean }>`
  padding: 0 ${(props) => props.theme.space.tiny};
  border: 1px solid ${(props) => props.theme.color.gray2};
  max-width: 0;
  white-space: nowrap;
  width: ${(props) => props.width + '%' || 'auto'};

  ${(props) => props.ellipsize && `overflow: hidden; text-overflow: ellipsis;`}
`;

const HeaderCell = styled(Cell)`
  word-break: normal;
  border: none;

  span {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }
`;

const SortContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.primary};
  margin-left: ${(props) => props.theme.space.tiny};

  svg {
    cursor: pointer;
  }
`;

type TableHeadProps<T> = {
  columns: Column<T>[];
  filterRow: JSX.Element;
};

function TableHead<T>({ columns, filterRow }: TableHeadProps<T>) {
  return (
    <thead>
      {filterRow}
      <HeadRow>
        {columns.map((column) => {
          return (
            <HeaderCell key={column.id} width={column.width}>
              <span>
                {column.label}
                {column.sortable && (
                  <SortContainer>
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      size='lg'
                      onClick={() => {
                        column.sort && column.sort(column.id, 'ASC');
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      size='lg'
                      onClick={() => {
                        column.sort && column.sort(column.id, 'DESC');
                      }}
                    />
                  </SortContainer>
                )}
              </span>
            </HeaderCell>
          );
        })}
      </HeadRow>
    </thead>
  );
}

export default TableHead;
