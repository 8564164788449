/* eslint-disable react/prop-types */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled, theme } from '../../config/Theme';
import ButtonComponent from './Button';

const HollowButton = styled(ButtonComponent)`
  border: 1px solid ${theme.color.gray3};
  padding: ${theme.space.tiny} ${theme.space.small};
  display: flex;
  flex-direction: row;
  & svg {
    margin-right: ${theme.space.tiny};
  }

  &:hover {
    border: 1px solid ${theme.color.gray3};
  }
`;

const HollowButtonComponent = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & { icon?: IconDefinition }
): JSX.Element => (
  <HollowButton onClick={props?.onClick} color={theme.color.gray3} bgcolor={theme.color.gray1} {...props}>
    {props.icon && <FontAwesomeIcon icon={props.icon} />}
    {props.children}
  </HollowButton>
);

export default HollowButtonComponent;
