import React from 'react';
import { theme } from '../../config/Theme';
import CreativeType from '../../enums/CreativeType';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';
import { DropDownOptionProps, OptionBadge } from './ResolutionOptionItem';

const CreativeTypeOptionItem = (props: DropDownOptionProps<CreativeType>): JSX.Element => {
  return (
    <OptionBadge color={theme.color.black} onClick={(e) => props.onSelected(e)} bgColor={theme.color.gray2}>
      <Flex row align='center' justify='center'>
        <Span
          color={theme.color.black}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          title={props.option.label}
        >
          {props.option.label}
        </Span>
      </Flex>
    </OptionBadge>
  );
};

export default CreativeTypeOptionItem;
