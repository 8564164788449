import React, { useMemo, useState } from 'react';
import { theme } from '../../config/Theme';
import { DropdownOption } from '../Editable/Dropdown';
import { firstLetterToUpperCase } from '../../utils/String';
import DropDownBadge from '../Badge/DropDownBadge';
import PageSizeOptionItem from '../Badge/PageSizeOptionItem';
import Checkbox from '../Button/Checkbox';
import Flex from '../Spacing/Flex';
import SearchBox from '../Editable/SearchBox';
import DropdownSelect from '../DropdownSelect';
import UserCampaignGroup from '../../enums/UserCampaignGroup';

type FilterRowProps = {
  onlyShowTheOnesWithError?: boolean;
  showEveryCampaign?: boolean;
  handleCampaignFilterByStatus?: (val: boolean) => void;
  handleCheck?: (val: boolean) => void;
  handleSearch?: (value: string) => void;
  totalCount: number;
  pageSize: number;
  setPageSize: (size: number) => void;
  name: string;
  selectCampaignGroups?: (items: string[]) => void;
};

const FilterRow = (props: FilterRowProps): JSX.Element => {
  const [pageSizeDropdownOpen, setPageSizeDropdownOpen] = useState<boolean>(false);
  const pageSizeOptions: DropdownOption<number>[] = useMemo(() => {
    return [
      { label: '10', value: 10, selected: props.pageSize === 10 },
      { label: '25', value: 25, selected: props.pageSize === 25 },
      { label: '50', value: 50, selected: props.pageSize === 50 },
    ];
  }, [props.pageSize]);

  const onlyShowTheOnesWithErrorClickHandler = () => {
    props.handleCheck && props.handleCheck(!props.onlyShowTheOnesWithError);
  };

  const showEveryCampaignClickHandler = () => {
    props.handleCampaignFilterByStatus && props.handleCampaignFilterByStatus(!props.showEveryCampaign);
  };

  const userCampaignGroups = useMemo((): string[] => {
    return Object.values(UserCampaignGroup);
  }, [UserCampaignGroup]);

  return (
    <tr style={{ height: '4rem' }} role='row'>
      <td colSpan={12}>
        <Flex align='center'>
          <Flex align='center'>
            {(props.name === 'kampány' || props.name == 'médium') && (
              <SearchBox
                withIcon
                placeholder={firstLetterToUpperCase(`${props.name} keresése`)}
                handleSearch={props.handleSearch}
              />
            )}
            {props.name !== 'tevékenység' && (
              <>
                <Checkbox
                  style={{ marginLeft: theme.space.tiny }}
                  checked={props.onlyShowTheOnesWithError}
                  onClick={onlyShowTheOnesWithErrorClickHandler}
                />
                <span>Csak a hibásak mutatása</span>
              </>
            )}

            {props.name === 'kampány' && (
              <>
                <Checkbox
                  style={{ marginLeft: theme.space.tiny }}
                  checked={props.showEveryCampaign}
                  onClick={showEveryCampaignClickHandler}
                />
                <span>Összes kampány mutatása</span>
              </>
            )}
          </Flex>
          <Flex justify='flex-end' align='center' style={{ width: 'auto' }}>
            {/* {props.name === 'kampány' && (
              <Flex column>
                <span
                  style={{
                    opacity: 0.5,
                    fontSize: 'calc(8px + 0.7vmin)',
                  }}
                >
                  Szűrés csoportra
                </span>

                <DropdownSelect
                  onSelect={props.selectCampaignGroups}
                  options={userCampaignGroups}
                  label='Összes csoport'
                />
              </Flex>
            )} */}
            <Flex column align='flex-end' style={{ marginRight: '1rem', minWidth: '210px' }}>
              <span>Oldalankénti találatok száma</span>
              <span
                style={{
                  opacity: 0.5,
                  fontSize: 'calc(8px + 0.7vmin)',
                }}
              >
                Összesen {props.totalCount} {props.name}
              </span>
            </Flex>
            <Flex justify='center' style={{ width: 'auto', alignSelf: 'center' }}>
              <DropDownBadge
                options={pageSizeOptions}
                isOpen={pageSizeDropdownOpen}
                toggle={() => setPageSizeDropdownOpen((prev) => !prev)}
                width='4rem'
                height='3rem'
              >
                {pageSizeOptions.map((option) => {
                  return (
                    <PageSizeOptionItem
                      key={option.label}
                      option={option}
                      onSelected={() => props.setPageSize(option.value)}
                    />
                  );
                })}
              </DropDownBadge>
            </Flex>
          </Flex>
        </Flex>
      </td>
    </tr>
  );
};

export default FilterRow;
