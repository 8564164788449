import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import { StatusCodes } from 'http-status-codes';
import React from 'react';
import Toaster from '../../utils/Toaster';

export type DeleteLinkProps = {
  id: string;
  entity: string;
  callback?: (id: string) => void;
  execute: (config?: AxiosRequestConfig | undefined, options?: RefetchOptions | undefined) => AxiosPromise<any>;
} & React.LinkHTMLAttributes<HTMLLinkElement>;

const DeleteLinkComponent = (props: DeleteLinkProps): JSX.Element => {
  const doDelete = async (e: React.MouseEvent) => {
    e.preventDefault();

    const res = await props.execute({ url: `/${props.entity}s/${props.id}`, method: 'DELETE' });

    if (res.status === StatusCodes.NOT_FOUND) Toaster.warn('Az elemet már törölték!');

    if (res.status === StatusCodes.NO_CONTENT) {
      Toaster.success('Sikeres törlés!');
      props.callback && props.callback(props.id);
    }
  };

  return <div onClick={doDelete}>{props.children}</div>;
};

export default DeleteLinkComponent;
