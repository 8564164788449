import React, { FunctionComponent, HTMLProps, useEffect, useRef, useState } from 'react';
import { StyledComponent } from 'styled-components';
import { Key } from 'ts-key-enum';
import useOnClickOutside from 'use-onclickoutside';
import { styled, Theme } from '../../../config/Theme';
import HeaderEditor from './HeaderEditor';

export type EditableTextProps = {
  text?: string;
  component: FunctionComponent<EditableDisplayTextProps>;
  editor?: StyledComponent<'input' | 'textarea', Theme>;
  onEdited: (text: string) => void;
  allowEmpty?: boolean;
  placeholderElement?: JSX.Element;
} & HTMLProps<Element>;

export type EditableDisplayTextProps = {
  text?: string;
  startEditing: () => void;
  placeholder: JSX.Element;
};

const Container = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const EditableText = (props: EditableTextProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string | undefined>(props.text);
  const editorRef = useRef<HTMLInputElement>(null);
  const contRef = useRef<HTMLDivElement>(null);

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Enter && !e.shiftKey) {
      e.preventDefault();
      editorRef?.current?.blur();
      stopEditing();
    }
  };

  const startEditing = () => {
    setText(props.text);
    if (!editorRef || !editorRef.current) return;
    setIsEditing(true);
    setTimeout(() => {
      editorRef?.current?.focus();
    }, 100);
  };

  const stopEditing = () => {
    if (!isEditing) return;
    setIsEditing(false);

    if (!props.allowEmpty && (!text || !text.trim())) return;
    props.onEdited(text?.trim() || '');
  };

  const setEditorLineHeight = () => {
    if (editorRef.current) {
      if (editorRef.current.scrollHeight != 0) {
        editorRef.current.style.height = editorRef.current?.scrollHeight + 'px';
      }
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.oninput = setEditorLineHeight;
      editorRef.current.onfocus = setEditorLineHeight;
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.oninput = null;
        editorRef.current.onfocus = null;
      }
    };
  }, [editorRef]);

  useOnClickOutside(editorRef, stopEditing);

  return (
    <>
      <Container isVisible={isEditing}>
        {props.editor ? (
          <props.editor
            ref={editorRef}
            onKeyUp={onKeyUp}
            value={text || ''}
            onChange={(e: any) => setText(e.target.value)}
          />
        ) : (
          <HeaderEditor
            ref={editorRef}
            onKeyUp={onKeyUp}
            value={text || ''}
            onChange={(e) => setText(e.target.value)}
          />
        )}
      </Container>
      <Container ref={contRef} isVisible={!isEditing}>
        <props.component
          text={props.text}
          startEditing={startEditing}
          placeholder={props.placeholderElement ?? <>Szöveg megadása</>}
        />
      </Container>
    </>
  );
};

export default EditableText;
