import React, { FC, useEffect, useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../config/Theme';
import styled from 'styled-components';

import useAPI from '../../hooks/useAPI';

import LogItems from '../Layout/LogItems';
import IconButton from '../Button/IconButton';
import { P } from '../Typography';

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-top: 1px solid rgb(192 202 225);
  border-bottom: 1px solid rgb(192 202 225);
  text-align: initial;
`;

const LogsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const LogsHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogsContentContainer = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(192 202 225) 0px 1px 5px;
  overflow-y: auto;
`;

type ScreenshotDetailsProps = {
  renderId: string;
  screenshotId: string;
};

const ScreenshotDetails: FC<ScreenshotDetailsProps> = ({ renderId, screenshotId }): JSX.Element => {
  const [, getLogs] = useAPI({ method: 'GET' }, { manual: true });
  const [showRenderLogs, setShowRenderLogs] = useState<boolean>(false);
  const [showMatcherLogs, setShowMatcherLogs] = useState<boolean>(false);

  const [renderLogs, setRenderLogs] = useState<string[] | { date: string; status: string; message: string }[]>([]);
  const [matcherLogs, setMatcherLogs] = useState<string[] | { date: string; status: string; message: string }[]>([]);

  const toggleShowRenderLogs = (): void => {
    setShowRenderLogs((prev) => !prev);
  };

  const toggleShowMatcherLogs = (): void => {
    setShowMatcherLogs((prev) => !prev);
  };

  const handleRenderLogs = async () => {
    const response = await getLogs({ url: '/system-logs/get-render-logs', params: { renderId, screenshotId } });
    if (!response || !response.data) return;

    setRenderLogs(JSON.parse(response.data.message));
  };

  const handleMatcherLogs = async () => {
    const response = await getLogs({ url: '/system-logs/get-matcher-logs', params: { renderId, screenshotId } });
    if (!response || !response.data) return;

    setMatcherLogs(JSON.parse(response.data.message));
  };

  useEffect(() => {
    if (showRenderLogs) {
      handleRenderLogs();
    }
  }, [showRenderLogs]);

  useEffect(() => {
    if (showMatcherLogs) {
      handleMatcherLogs();
    }
  }, [showMatcherLogs]);

  return (
    <DetailsContainer>
      <LogsContainer>
        <LogsHeader>
          <P>Render logok</P>
          <IconButton
            size='lg'
            icon={showRenderLogs ? faAngleUp : faAngleDown}
            color={theme.color.primary}
            hoverColor={theme.color.secondary}
            onClick={toggleShowRenderLogs}
          />
        </LogsHeader>
        {showRenderLogs &&
          (renderLogs.length > 0 ? (
            <LogsContentContainer>
              <LogItems logs={renderLogs} />
            </LogsContentContainer>
          ) : (
            <LogsContentContainer>
              <P>Nem található log</P>
            </LogsContentContainer>
          ))}
      </LogsContainer>

      <LogsContainer>
        <LogsHeader>
          <P>Matcher logok</P>
          <IconButton
            size='lg'
            icon={showMatcherLogs ? faAngleUp : faAngleDown}
            color={theme.color.primary}
            hoverColor={theme.color.secondary}
            onClick={toggleShowMatcherLogs}
          />
        </LogsHeader>

        {showMatcherLogs &&
          (matcherLogs.length > 0 ? (
            <LogsContentContainer>
              <LogItems logs={matcherLogs} />
            </LogsContentContainer>
          ) : (
            <LogsContentContainer>
              <P>Nem található log</P>
            </LogsContentContainer>
          ))}
      </LogsContainer>
    </DetailsContainer>
  );
};

export { ScreenshotDetails };
