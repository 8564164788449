import { faArrowAltCircleUp, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import React, { ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { styled, theme } from '../../config/Theme';
import CreativeType from '../../enums/CreativeType';
import Platform from '../../enums/Platform';
import useAPI, { API } from '../../hooks/useAPI';
import useModal from '../../hooks/useModal';
import { useGetFile } from '../../hooks/useUpload';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import Media from '../../models/Media';
import Medium from '../../models/Medium';
import Toaster from '../../utils/Toaster';
import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import PureMainButton from '../Button/PureMainButton';
import DeleteModalContent from '../Cards/DeleteModalContent';
import Modal, { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import Flex from '../Spacing/Flex';
import { H2, Span } from '../Typography';
import { Grid } from './Grid';
import RemoteMediaModalImage from './RemoteMediaModalImage';

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: 75%;
  max-height: 80%;
  min-height: 80%;
  position: fixed;
  z-index: 1060;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ModalBody = styled.div`
  flex: 1;
  display: flex;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - ${(props) => props.theme.space.large});
`;

const ModalFooter = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;

  > div {
    padding-top: ${(props) => props.theme.space.small};
    display: flex;
    width: 100%;

    button {
      margin-left: ${(props) => props.theme.space.small};
    }
  }
`;

const ButtonLeftSideContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${(props) => props.theme.space.small};
`;

const ButtonRightSideContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: ${(props) => props.theme.space.small};
  margin-right: ${(props) => props.theme.space.small};
`;

const NoUploadedFileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2c2c2;
  border-radius: 7px;
  /* border: 2px solid #061354; */
`;

const Thumbnail = styled.div`
  width: 14em;
  background-color: ${(props) => props.theme.color.gray2};
  border-radius: 7px;
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Row = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  min-height: 5.5rem;
  margin-bottom: 20px;
  gap: 1.5rem;
`;

const UploadedMediaItem = styled.div`
  width: 14rem;
  padding: 0 10px 10px 10px;
  margin: 0 3px;
`;

const UploadedMediaName = styled.div`
  margin-top: -0.7rem;
  text-align: left;
  word-wrap: break-word;
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

const ValidationContainer = styled.div`
  margin-bottom: 1rem;
`;
const InputContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const InputLabel = styled.label`
  text-align: left;
  margin-right: 0.5rem;
  margin-left: 2rem;
  word-wrap: break-word;
  font-size: ${(props) => props.theme.fontsize.tiny};
  ${(props) => props.color && 'color:' + props.color};
`;

const Input = styled.input`
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.2rem;
  height: 100%;
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.5);
  }
`;

type FileItem = {
  file: File;
  uploading: boolean;
  uploadProgress: number;
  width?: number;
  height?: number;
  duration?: number;
};

type UploadValidationType = {
  width: number;
  height: number;
  error: {
    width?: string;
    height?: string;
  };
};

export type DimensionsType = {
  width?: number | undefined;
  height?: number | undefined;
  duration?: number | undefined;
};

type MediaUploadRowProps = {
  item: Media;
  medias?: Media[];
  campaignId?: string;
  filename?: string;
  selected: boolean;
  mimeType?: string;
  dimensions?: (width: number, height: number, duration: number) => DimensionsType;
  onImageLoaded?: (width: number, height: number, duration?: number) => void;
  onMark: () => void;
};

const UploadMediaRow = (props: MediaUploadRowProps): JSX.Element => {
  return (
    <RowContainer onClick={() => props.onMark()}>
      <Row>
        <Thumbnail>
          <ImageWrapper style={{ border: props.selected ? '2px solid #061354' : 'none' }}>
            <RemoteMediaModalImage
              alt={props.item.filename}
              source={`/media/${props.campaignId}/binary?filename=${props.filename}`}
              filename={props.filename}
              campaignId={props.campaignId}
              onGotSize={props.onImageLoaded}
              mimeType={props.mimeType}
              dimensions={props.dimensions!(props.item.width!, props.item.height!, props.item.duration!)}
            />
          </ImageWrapper>
        </Thumbnail>
      </Row>
    </RowContainer>
  );
};

type UploadNewCreativeProps = {
  startUpload: (mediaItems: File[]) => void;
};

const UploadNewCreative = ({ startUpload }: UploadNewCreativeProps): JSX.Element => {
  const [uploadValidation, setUploadValidation] = useState<UploadValidationType>({
    width: 0,
    height: 0,
    error: {
      width: undefined,
      height: undefined,
    },
  });

  const chooseMediaFiles = useGetFile({
    multiple: true,
    accept: ['.jpg', '.png', '.jpeg', '.mp4', '.html', '.zip'],
    callback: async (files: File[]) => {
      const validationPromises = [];

      for (const file of files) {
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const img = new Image();
          const imgObjectUrl = URL.createObjectURL(file);
          img.src = imgObjectUrl;

          const imagePromise = new Promise((resolve, reject) => {
            img.onload = () => {
              const width = img.naturalWidth;
              const height = img.naturalHeight;

              if (width === uploadValidation.width && height === uploadValidation.height) {
                console.log('resolve', file);
                resolve({ file });
              } else {
                console.log('reject', file);
                reject({ error: 'Invalid dimensions' });
              }
            };
          });

          validationPromises.push(imagePromise);
        }
      }

      try {
        await Promise.all(validationPromises);
        startUpload(files);
      } catch (error) {
        Toaster.error('A megadott szélesség és magasság nem egyezik a feltölteni kívánt fájl méretével!');
        setUploadValidation((old) => ({
          ...old,
          error: {
            ...old.error,
            height: 'A megadott szélesség nem azonos a feltölteni fájlal!',
            width: 'A megadott szélesség nem azonos a feltölteni fájlal!',
          },
        }));
        console.error('Upload process aborted:', error);
      }
    },
  });

  const handleChooseMedia = (e: any) => {
    setUploadValidation((prevValidation) => {
      const updatedValidation: UploadValidationType = {
        ...prevValidation,
        error: { width: undefined, height: undefined },
      };

      if (!prevValidation.width) {
        updatedValidation.error.width = 'A szélesség mező kitöltése kötelező!';
      }

      if (!prevValidation.height) {
        updatedValidation.error.height = 'A magasság mező kitöltése kötelező!';
      }

      if (updatedValidation.error.width || updatedValidation.error.height) {
        return updatedValidation;
      }

      chooseMediaFiles(e);
      return updatedValidation;
    });
  };

  return (
    <div>
      <ValidationContainer>
        <InputContainer>
          <Flex align='center'>
            <InputLabel color={uploadValidation.error.width ? theme.color.danger : theme.color.gray3}>
              Szélesség (px)
            </InputLabel>
            <Input
              style={{
                borderColor: uploadValidation.error.width ? theme.color.danger : undefined,
              }}
              type='number'
              value={uploadValidation.width}
              onChange={(e) =>
                setUploadValidation((old) => ({
                  ...old,
                  error: { ...old.error, width: undefined },
                  width: parseInt(e.target.value),
                }))
              }
            />
          </Flex>
        </InputContainer>
        <InputContainer>
          <Flex align='center'>
            <InputLabel color={uploadValidation.error.height ? theme.color.danger : theme.color.gray3}>
              Magasság (px)
            </InputLabel>
            <Input
              style={{
                borderColor: uploadValidation.error.height ? theme.color.danger : undefined,
              }}
              type='number'
              value={uploadValidation.height}
              onChange={(e) =>
                setUploadValidation((old) => ({
                  ...old,
                  error: { ...old.error, height: undefined },
                  height: parseInt(e.target.value),
                }))
              }
            />
          </Flex>
        </InputContainer>
      </ValidationContainer>
      <div>
        <MainButton secondary icon={faArrowAltCircleUp} onClick={handleChooseMedia}>
          Kreatív feltöltése
        </MainButton>
      </div>
    </div>
  );
};

export type MediaStoreModalProps = {
  campaignId?: string;
  media?: Media;
  creative?: Creative;
  isOpen: boolean;
  updateCreative: (creative: Creative) => void;
  onFinish?: () => void;
  toggle: () => void;
  medium?: Medium;
} & React.HTMLAttributes<HTMLDivElement>;

const MediaStoreModalForCreative = (props: MediaStoreModalProps): ReactPortal | null => {
  const { mediumId } = useParams<{ mediumId: string }>();
  const [, getMedias] = useAPI<Media[]>(`/media/${props.campaignId}`, { manual: true });
  const [, postCreative] = useAPI<Creative>({ method: 'POST' }, { manual: true });
  const [, postVariation] = useAPI<CreativeVariation>({ method: 'POST' }, { manual: true });

  const [creativeMediaItems, setCreativeMediaItems] = useState<Media[]>([]);
  const [selectedMediaItems, setSelectedMediaItems] = useState<string[]>([]);
  const [fetchedMediaItems, setFetchedMediaItems] = useState<Media[]>([]);

  const [isDeleteModal, showDeleteModal] = useModal();

  const fetchData = async () => {
    const response = await getMedias();
    setFetchedMediaItems(response.data);
  };

  const startUpload = async (mediaItems: File[]) => {
    const existingMediaFiles = fetchedMediaItems.map((item) => item.originalFilename);

    for (const item of mediaItems) {
      if (!existingMediaFiles.includes(item.name)) {
        await uploadMedia(item);
      }
    }
  };

  const uploadMedia = async (item: File) => {
    const data = new FormData();
    data.append('file', item, item.name);
    await API.post<Media>(`/media/upload?campaign_id=${props.campaignId}`, data)
      .then((res) => {
        fetchData();
      })
      .catch((err: Error) => console.log(err));
  };

  const startCreateCreative = async () => {
    for (const mediaItem of creativeMediaItems) {
      await uploadCreative(mediaItem);
    }

    setCreativeMediaItems([]);
  };

  const uploadCreative = async (media: Media) => {
    const creativeId = props?.creative?.id;
    const variation = new CreativeVariation();
    const creativeResponse = await postVariation({
      url: `/creatives/${creativeId}/variations`,
      data: variation,
    });

    const uploadResponse = await API.post<Media>(
      `/creatives/${creativeId}/variations/${creativeResponse.data.id}/convert`,
      media
    )
      .then((res) => {
        if (!res.data) {
          Toaster.warn('Sikertelen létrehozás!');
        } else {
          console.log(res.data);
          props.creative?.variations;
          props.updateCreative({
            ...props.creative,
            variations: [...(props.creative?.variations ?? []), creativeResponse.data],
          });
          Toaster.success('Sikeres létrehozás!');
          if (props.onFinish) {
            props.onFinish();
          }
          props.toggle();
        }
      })
      .catch((err: Error) => console.log(err));
  };

  const getDimensions = (width: number, height: number, duration: number): DimensionsType => {
    return {
      width,
      height,
      duration,
    };
  };

  const handleSelectForDelete = (mediaId: string) => {
    let currentItems = [...selectedMediaItems];
    if (selectedMediaItems.includes(mediaId)) {
      currentItems = selectedMediaItems.filter((i) => i !== mediaId);

      setSelectedMediaItems(currentItems);
    } else {
      setSelectedMediaItems([...selectedMediaItems, mediaId]);
    }
  };

  const handleSelectForCreativeCreation = (media: Media) => {
    let currentItems = [...creativeMediaItems];
    if (creativeMediaItems.filter((m) => m.id === media.id).length !== 0) {
      currentItems = creativeMediaItems.filter((i) => i.id !== media.id);

      setCreativeMediaItems(currentItems);
    } else {
      setCreativeMediaItems([...creativeMediaItems, media]);
    }
  };

  const deleteMedias = async () => {
    if (selectedMediaItems.length > 0) {
      await API.delete<Media>(`/media/${props.campaignId}?media_ids=${JSON.stringify(selectedMediaItems)}`)
        .then((res) => {
          setCreativeMediaItems([]);
          setSelectedMediaItems([]);
          fetchData();
          showDeleteModal(false);
        })
        .catch((err: Error) => console.log(err));
    }
  };

  const isOpenedModal = () => {
    if (!props.isOpen) {
      setSelectedMediaItems([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    isOpenedModal();
  }, [props.isOpen]);

  return props.isOpen
    ? createPortal(
        <ModalWrapper>
          <ModalOverlay onClick={props.toggle} />
          <ModalStyled className='modal'>
            <ModalHeader>
              <Span color={theme.color.gray3}>Kreatív tár</Span>

              {selectedMediaItems.length > 0 && (
                <Span color={theme.color.gray3}>{selectedMediaItems.length} fájl kiválasztva</Span>
              )}
              <IconButton
                size='lg'
                icon={faTimesCircle}
                color={theme.color.black}
                hoverColor={theme.color.gray3}
                onClick={props.toggle}
              />
            </ModalHeader>

            <ModalBody>
              {fetchedMediaItems && fetchedMediaItems.length === 0 ? (
                <NoUploadedFileContainer>
                  <Span color={theme.color.gray3}>Nincs feltöltött fájl</Span>
                </NoUploadedFileContainer>
              ) : (
                <Grid width='15rem' height='10rem'>
                  {fetchedMediaItems &&
                    fetchedMediaItems.map((item) => (
                      <UploadedMediaItem key={item.id}>
                        <UploadMediaRow
                          key={item.id}
                          selected={selectedMediaItems && selectedMediaItems.includes(item.id!) ? true : false}
                          onMark={() => {
                            handleSelectForDelete(item.id!);
                            handleSelectForCreativeCreation(item);
                          }}
                          filename={item.filename}
                          item={item}
                          campaignId={item.campaignId}
                          mimeType={item.mimetype}
                          dimensions={() => getDimensions(item.width!, item.height!, item.duration!)}
                        />
                        <UploadedMediaName
                          style={{
                            color: selectedMediaItems.includes(item.id!) ? '#061354' : '#9499b6',
                            fontWeight: selectedMediaItems.includes(item.id!) ? 500 : 'normal',
                          }}
                        >
                          {item.originalFilename}
                        </UploadedMediaName>
                      </UploadedMediaItem>
                    ))}
                </Grid>
              )}
            </ModalBody>

            <ModalFooter>
              <ButtonLeftSideContainer>
                <UploadNewCreative startUpload={startUpload} />

                <PureMainButton
                  pureMediaModalButton
                  icon={faTrashAlt}
                  onClick={() => selectedMediaItems.length > 0 && showDeleteModal(true)}
                >
                  Kiválasztott kreatívok törlése
                </PureMainButton>
              </ButtonLeftSideContainer>

              {mediumId && (
                <ButtonRightSideContainer>
                  <MainButton variation='create' onClick={startCreateCreative}>
                    Kreatívok kiválasztása
                  </MainButton>
                </ButtonRightSideContainer>
              )}
            </ModalFooter>

            <Modal isOpen={isDeleteModal} toggle={showDeleteModal}>
              <DeleteModalContent onDelete={deleteMedias} onCancel={() => showDeleteModal(false)}>
                <H2>
                  Biztosan törölni szeretné a kiválasztott
                  {selectedMediaItems.length === 1 ? ' kreatívot' : ' kreatívokat'}?
                </H2>
              </DeleteModalContent>
            </Modal>
          </ModalStyled>
        </ModalWrapper>,
        document.body
      )
    : null;
};

export default MediaStoreModalForCreative;
