import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled, theme } from '../../config/Theme';
import ButtonComponent from './Button';

export type PureMainButtonProps = {
  secondary?: boolean;
  pureMediaModalButton?: boolean;
  icon?: IconDefinition;
  bgColor?: string;
  hoverColor?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const PureButtonStyled = styled(ButtonComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  & svg {
    margin-right: ${theme.space.half};
  }

  &:hover {
    background-color: transparent;
    color: ${(props) => (props.pureMediaModalButton ? '#8286a0' : '#4BB4FF')};
  }
`;

const PureMainButton = (props: PureMainButtonProps): JSX.Element => (
  <PureButtonStyled
    {...props}
    color={props.pureMediaModalButton ? theme.color.gray3 : '#245be6'}
    bgcolor={props.bgColor ? props.bgColor : 'transparent'}
  >
    {props.icon && <FontAwesomeIcon icon={props.icon} />}
    {props.children}
  </PureButtonStyled>
);

export default PureMainButton;
