import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled, theme } from '../../config/Theme';
import ButtonComponent from './Button';

export type MainButtonProps = {
  secondary?: boolean;
  icon?: IconDefinition;
  bgColor?: string;
  hoverColor?: string;
  variation?: 'create' | 'default';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const MainButtonStyled = styled(ButtonComponent)`
  height: calc(${(props) => props.theme.space.small} + ${(props) => props.theme.space.half});
  padding: 0 ${(props) => props.theme.space.tiny};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    margin-right: ${theme.space.half};
  }

  &:disabled {
    opacity: 0.4;
    background-color: ${(props) => props.variation === 'create' && props.theme.color.gray3};
    cursor: ${(props) => (props.variation === 'create' ? 'not-allowed' : 'progress')};
  }
`;

const MainButton = (props: MainButtonProps): JSX.Element => (
  <MainButtonStyled
    {...props}
    variation={props.variation ? props.variation : 'default'}
    color={theme.color.white}
    bgcolor={props.bgColor ? props.bgColor : props.secondary ? theme.color.secondary : theme.color.primary}
    hoverColor={props.hoverColor ? props.hoverColor : props.secondary ? theme.color.primary : theme.color.secondary}
  >
    {props.icon && <FontAwesomeIcon icon={props.icon} />}
    {props.children}
  </MainButtonStyled>
);

export default MainButton;
