import React from 'react';
import { styled } from '../../config/Theme';
import { ChildrenProps } from '../../utils/Types';

const NavHeader = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space.small} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavHeaderComponent = (props: ChildrenProps): JSX.Element => {
  return <NavHeader>{props.children}</NavHeader>;
};

export default NavHeaderComponent;
