import React, { FC, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../config/Theme';

import type { Screenshot } from './ScreenshotTab';

import { useDownload } from '../../hooks/useDownload';

import IconButton from '../Button/IconButton';
import Flex from '../Spacing/Flex';
import MainButton from '../Button/MainButton';
import { Muted } from '../Typography';
import { ScreenshotDetails } from './ScreenshotDetails';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(192 202 225) 0px 1px 5px;
  text-align: center;
  cursor: pointer;
`;

const BaseContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ScreenshotData = styled.div`
  flex-grow: 1;
`;

const DataFlexRow = styled.div`
  display: flex;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  text-align: left;
  max-width: 400px;
`;

const StatusDot = styled.span<{ status: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2rem;

  background-color: ${(props) =>
    props.status === 'inactive' || props.status === 'failed'
      ? 'rgb(217, 83, 79)'
      : props.status === 'waiting_for_match'
      ? 'rgb(240, 173, 78)'
      : props.status === 'inprogress' || props.status == 'success'
      ? 'rgb(92, 184, 92)'
      : props.status === 'scheduled'
      ? 'rgb(75, 180, 255)'
      : '#F0AD4E'};
`;

type ScreenshotRowProps = {
  screenshot: Screenshot;
};

const ScreenshotRow: FC<ScreenshotRowProps> = ({ screenshot }): JSX.Element => {
  const [showScreenshotDetails, setShowScreenshotDetails] = useState<boolean>(false);

  const downloadRender = useDownload({
    url: `/rendererror/${screenshot.renderId}/download-single`,
  });

  const downloadCreative = useDownload({
    url: `/creatives/${screenshot.creative.id}/variations/${screenshot.variation.id}/download?creativeId=${screenshot.creative.id}`,
  });

  const toggleShowBody = () => {
    setShowScreenshotDetails((prev) => !prev);
  };

  return (
    <Row key={screenshot.id}>
      <BaseContainer>
        <ScreenshotData>
          <DataFlexRow>
            <Flex column>
              <Muted>Screenshot url</Muted>
              <Link target='_blank' href={screenshot.url} style={{ color: theme.color.primary }}>
                {screenshot.url}
              </Link>
            </Flex>
            <Flex column>
              <Muted>Screenshot platform</Muted>
              {screenshot.platform}
            </Flex>
            <Flex column>
              <Muted>Screenshot Dátum</Muted>
              {moment(screenshot.created).format('YYYY-MM-DD hh:mm:ss.SSS')}
            </Flex>
          </DataFlexRow>
          <DataFlexRow>
            <Flex column>
              <Muted>Kampány név</Muted>
              <Flex>{screenshot.campaign.name}</Flex>
            </Flex>

            <Flex column>
              <Muted>Kampány státusz</Muted>
              <Flex align='center'>
                {screenshot.campaign.status}
                <StatusDot status={screenshot.campaign.status} style={{ marginLeft: '0.5rem' }} />
              </Flex>
            </Flex>
            <Flex />
          </DataFlexRow>
          <DataFlexRow>
            <Flex column>
              <Muted>Médium név</Muted>
              <Flex>{screenshot.medium.name}</Flex>
            </Flex>
            <Flex column>
              <Muted>Médium típus</Muted>
              <Flex>{screenshot.medium.type}</Flex>
            </Flex>
            <Flex />
          </DataFlexRow>
        </ScreenshotData>
        <StatusDot status={screenshot.status} />
        <IconButton
          size='lg'
          icon={showScreenshotDetails ? faAngleUp : faAngleDown}
          color={theme.color.primary}
          hoverColor={theme.color.secondary}
          onClick={toggleShowBody}
        />
      </BaseContainer>

      {showScreenshotDetails && screenshot.id && screenshot.renderId && (
        <ScreenshotDetails screenshotId={screenshot.id} renderId={screenshot.renderId} />
      )}

      {showScreenshotDetails && (
        <Flex style={{ margin: '1rem 0' }}>
          <MainButton onClick={downloadCreative} style={{ marginRight: '1rem' }}>
            Kreatív letöltése
          </MainButton>
          {screenshot.renderId && screenshot.renderFilePath && (
            <MainButton onClick={downloadRender}>Screenshot letöltése</MainButton>
          )}
        </Flex>
      )}
    </Row>
  );
};

export { ScreenshotRow };
