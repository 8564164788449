/* eslint-disable max-len */
import React, { HTMLProps, useMemo } from 'react';
import { styled, theme } from '../../config/Theme';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';

const ContainerBar = styled.div<{ bgColor?: string; percentage: number }>`
  display: grid;
  grid-template-columns: calc(100% * ${(props) => props.percentage}) 1fr;
  width: 100%;
  height: ${(props) => props.theme.space.half};
  border-radius: ${(props) => props.theme.space.quarter};
  margin-left: ${(props) => props.theme.space.half};
  background-color: ${(props) => props.bgColor ?? props.theme.color.white};
`;

const FillerBar = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${(props) => props.theme.color.primary};
`;

type ProgressProps = {
  daysShown?: number;
  daysProposed?: number;
  bgColor?: string;
  color?: 'danger' | 'success' | 'black';
} & HTMLProps<HTMLDivElement>;

const Progress = (props: ProgressProps): JSX.Element => {
  const percentage = useMemo<number>(() => {
    if (props.daysProposed && props.daysShown) {
      const result = props.daysShown / props.daysProposed;
      return result > 1 ? 1 : result;
    } else {
      return 0;
    }
  }, [props]);

  return (
    <div style={{ width: '100%' }}>
      <Flex row align='center' justify='center' style={{ marginBottom: theme.space.quarter }}>
        <Span color={props.color ? theme.color[props.color] : theme.color.black}>
          {props.daysShown ?? 0} / {props.daysProposed ?? 0}
        </Span>
      </Flex>
      {/* <Flex row align='center' justify='center'>
                <Flex column align='center' justify='center' width='60%'>
                    {
                        props.daysProposed &&
                        props.daysShown &&
                        props.daysShown > 0 &&
                        props.daysProposed > 0 &&
                        props.daysShown === props.daysProposed ?
                            (<FontAwesomeIcon icon={faCheck} color='green' />) :
                            (<ContainerBar
                                bgColor={props.bgColor}
                                percentage={percentage}
                            >
                                <FillerBar />
                            </ContainerBar>)
                    }
                </Flex>
            </Flex> */}
    </div>
  );
};

export default Progress;
