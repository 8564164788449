import React from 'react';
import { theme } from '../../config/Theme';
import MainButton from '../Button/MainButton';
import Flex from '../Spacing/Flex';

export type ActionModalProps = {
  positiveText?: string;
  negativeText?: string;
  onPositive: () => Promise<void> | void;
  onNegative?: () => void;
  oneOption?: Boolean;
  marginBottom?: string;
  marginTop?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ActionModalContent = ({
  positiveText,
  negativeText,
  onPositive,
  onNegative,
  oneOption = false,
  children,
  marginBottom = theme.space.small,
  marginTop = theme.space.medium,
}: ActionModalProps): JSX.Element => {
  return (
    <Flex column justify='center' align='center'>
      <div style={{ margin: theme.space.small, marginBottom }}>{children}</div>

      <Flex row justify='center' align='center' style={{ margin: theme.space.medium, marginTop }}>
        <MainButton
          style={{ width: '30%', marginRight: !oneOption ? theme.space.small : '' }}
          onClick={async () => await onPositive()}
        >
          {positiveText ?? 'OK'}
        </MainButton>

        {!oneOption && onNegative && (
          <MainButton style={{ width: '30%' }} secondary onClick={() => onNegative()}>
            {negativeText ?? 'Mégsem'}
          </MainButton>
        )}
      </Flex>
    </Flex>
  );
};

export default ActionModalContent;
