import React from 'react';
import { theme } from '../../../../config/Theme';
import Platform, { PlatformNames } from '../../../../enums/Platform';
import Creative from '../../../../models/Creative';
import Badge from '../../../Badge/Badge';
import Dropdown, { DropdownOption } from '../../../Editable/Dropdown';
import RowWrapper from '../../../Spacing/RowWrapper';
import { Span } from '../../../Typography';

const creativeTablePlatformAccessor = (
  creative: Creative,
  options: DropdownOption<Platform>[],
  setPlatform: (platform: string, creative: Creative) => void
): JSX.Element => {
  const handleActionSelected = (option: DropdownOption<Platform>) => {
    if (option.value !== creative.platform) setPlatform(option.value, creative);
  };

  return (
    <RowWrapper>
      <Dropdown options={options} onSelected={(o: DropdownOption<Platform>) => handleActionSelected(o)}>
        <Badge bgColor={theme.color.gray2}>
          <Span>{PlatformNames.get(creative.platform || Platform.DESKTOP)}</Span>
        </Badge>
      </Dropdown>
    </RowWrapper>
  );
};

export default creativeTablePlatformAccessor;
