import { Span } from '../Typography';
import React from 'react';
import Flex from '../Spacing/Flex';
import IconButton from '../Button/IconButton';
import ReactDOM from 'react-dom';
import { styled, theme } from '../../config/Theme';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';

const ModalBody = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 20px 0 20px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  form {
    width: 100%;
  }
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.white};
  padding: 20px;
  span {
    font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  box-sizing: border-box;
  width: 100%;
`;

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  min-width: 600px;
  max-width: 90%;
  max-height: 70%;
  position: fixed;
  z-index: 1060;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export type CrudModalState = { saved: boolean; updated: boolean; deleted: boolean };

type ModalValues = {
  toggleState: (state?: boolean) => void;
};
export type ModalProps = {
  isOpen: boolean;
  headerText: string;
  toggle: (state?: boolean) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const CrudModalContext = React.createContext({} as ModalValues);

const CrudModal = (props: ModalProps) => {
  const closeModal = () => {
    props.toggle(false);
  };

  return (
    <CrudModalContext.Provider value={{ toggleState: props.toggle }}>
      {props.isOpen
        ? ReactDOM.createPortal(
            <ModalWrapper>
              <ModalOverlay onClick={() => props.toggle(false)} />
              <ModalStyled className='modal'>
                <ModalHeader>
                  <Span color={theme.color.gray3}>{props.headerText}</Span>
                  <IconButton
                    size='lg'
                    icon={faTimesCircle}
                    color={theme.color.black}
                    hoverColor={theme.color.gray3}
                    onClick={closeModal}
                  />
                </ModalHeader>
                <ModalBody>{props.children}</ModalBody>
              </ModalStyled>
            </ModalWrapper>,
            document.body
          )
        : null}
    </CrudModalContext.Provider>
  );
};

export default CrudModal;
