import React, { FC, ReactPortal, useEffect, useState, useMemo } from 'react';
import fileDownload from 'js-file-download';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { theme } from '../../config/Theme';
import Toaster from '../../utils/Toaster';

import Creative from '../../models/Creative';

import useAPI from '../../hooks/useAPI';

import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import Flex from '../Spacing/Flex';
import CreativeModal from './CreativeModal';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import { useMatcherModalContext } from '../Provider/MatcherErrorModalProvider';
import FixErrorsContainer from '../Table/FixErrorsContainer';

import PaginationContainer from '../Table/PaginationContainer';
import CreativeType from '../../enums/CreativeType';
import CreativeCampaignType from '../../enums/CreativeCampaignType';
import UrlType from '../../enums/UrlType';
import Platform from '../../enums/Platform';
import MixErrorsContainer from '../Table/MixErrorsContainer';

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: 85%;
  max-height: 80%;
  min-height: 80%;
  position: fixed;
  z-index: 1060;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
  // justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
`;

const ModalBody = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  overflow-y: scroll;
  flex: 1;
`;

const ModalFooter = styled(Flex)`
  justify-content: space-between;
  padding-top: ${theme.space.tiny};
`;

const InfoText = styled.p`
  color: ${theme.color.gray3};
  font-weight: 500;
`;

const PaginationWrapper = styled(Flex)`
  justify-content: center;
  padding-top: ${theme.space.tiny};
`;

export type MatcherErrorScreenshot = {
  id: string;
  creative: {
    id: string;
    height: number;
    width: number;
    type: CreativeType;
  };
  exclude: boolean;
  medium: { name: string; type: CreativeCampaignType; id: string };
  campaign?: {
    id: string;
    name: string;
    status: string;
  };
  deepUrl: {
    rootUrl: string;
    urlType: UrlType;
  };
  platform: Platform;
  day: Date;
  isBigError: boolean;
};

type MatcherErrorsModalProps = {
  campaignId: string;
};

const MatcherErrorsModal: FC<MatcherErrorsModalProps> = ({ campaignId }): ReactPortal | null => {
  const [, getCampaignMatcherErrors] = useAPI(`/matchererrors/${campaignId}`, { manual: true });
  const [, getCreative] = useAPI({ url: '/creatives', method: 'GET' }, { manual: true });
  const [, exportScreenshots] = useAPI(
    { url: '/export/screenshots', method: 'POST', responseType: 'blob' },
    { manual: true }
  );

  const { modalTitle, isMatcherModalOpen, setIsMatcherModalOpen, mediumId, creativeId, matcherErrorsType } =
    useMatcherModalContext();

  const [showFixErrors, setShowFixErrors] = useState<boolean>(matcherErrorsType === 'time' ? true : false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [isCreativeModalOpen, setIsCreativeModalOpen] = useState<boolean>(false);
  const [creativeInModal, setCreativeInModal] = useState<Creative>();

  const pageCount = useMemo(() => {
    if (totalCount === 0) return 1;
    return Math.ceil(totalCount / 10);
  }, [totalCount]);

  const handleTotalPageCount = (totalCount: number): void => {
    setTotalCount(totalCount);
  };

  const handlePageIndexChange = (nextPageNumber: number) => {
    if (nextPageNumber < 0 || nextPageNumber > pageCount - 1) return;
    setPageIndex(nextPageNumber);
  };

  const handleCreativeSelection = async (creativeId: string) => {
    const response = await getCreative({ url: '/creatives/' + creativeId });
    setCreativeInModal(response.data);
    setIsCreativeModalOpen(true);
  };

  const exportScreenshotsToExcel = async () => {
    try {
      const screenshotResponse = await getCampaignMatcherErrors({
        params: { offset: 0, errorType: matcherErrorsType, mediumId, creativeId },
      });
      const screenshots = screenshotResponse.data[0];

      const exportScreenshotsResponse = await exportScreenshots({
        data: { ids: screenshots.map((sc: MatcherErrorScreenshot) => sc.id) },
      });
      fileDownload(exportScreenshotsResponse.data, `Shooter_hibalista.xlsx`);
    } catch (err: any) {
      Toaster.error('Ismeretlen hiba');
    }
  };

  useEffect(() => {
    if (isMatcherModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return createPortal(
    <ModalWrapper>
      <ModalOverlay onClick={() => setIsMatcherModalOpen(false)} />
      <ModalStyled className='modal'>
        <ModalHeader justify='flex-start'>
          <InfoText color={theme.color.gray3}>{modalTitle}</InfoText>
          <IconButton
            size='lg'
            style={{ marginLeft: 'auto' }}
            icon={faTimesCircle}
            color={theme.color.black}
            hoverColor={theme.color.gray3}
            onClick={() => setIsMatcherModalOpen(false)}
          />
        </ModalHeader>
        <ModalBody>
          {showFixErrors ? (
            <FixErrorsContainer
              mediumName={modalTitle}
              campaignId={campaignId}
              mediumId={mediumId}
              creativeId={creativeId}
              pageIndex={pageIndex}
              creativeSelection={handleCreativeSelection}
              pageIndexChange={handlePageIndexChange}
              totalPageCount={handleTotalPageCount}
            />
          ) : (
            <MixErrorsContainer
              mediumName={modalTitle}
              campaignId={campaignId}
              mediumId={mediumId}
              creativeId={creativeId}
              pageIndex={pageIndex}
              creativeSelection={handleCreativeSelection}
              pageIndexChange={handlePageIndexChange}
              totalPageCount={handleTotalPageCount}
            />
          )}

          {pageCount > 1 && (
            <PaginationWrapper>
              <PaginationContainer pageIndex={pageIndex} pageCount={pageCount} setPageIndex={handlePageIndexChange} />
            </PaginationWrapper>
          )}

          {isCreativeModalOpen && (
            <CreativeModal
              updateCreative={(creative) => setCreativeInModal(creative)}
              creative={creativeInModal}
              isOpen={isCreativeModalOpen}
              toggle={() => setIsCreativeModalOpen((val) => !val)}
            />
          )}
          <ModalFooter>
            <InfoText>Amelyeket bepipálod nem fognak beleszámítani a táblázat hibajelzésébe</InfoText>
            <Flex justify='flex-end' width='40%' align='center'>
              {!showFixErrors && !mediumId && !creativeId && (
                <MainButton onClick={() => setShowFixErrors(true)}>Fix errorok</MainButton>
              )}
              {showFixErrors && matcherErrorsType === 'av' && !mediumId && !creativeId && (
                <MainButton onClick={() => setShowFixErrors(false)}>Mix errorok</MainButton>
              )}

              <MainButton
                style={{ marginRight: '1rem', marginLeft: '1rem', backgroundColor: theme.color.secondary }}
                onClick={exportScreenshotsToExcel}
              >
                Excel export
              </MainButton>
              <MainButton onClick={() => setIsMatcherModalOpen(false)}>Bezárás</MainButton>
            </Flex>
          </ModalFooter>
        </ModalBody>
      </ModalStyled>
    </ModalWrapper>,
    document.body
  );
};

export default MatcherErrorsModal;
