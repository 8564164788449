import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { styled } from '../../config/Theme';
import { SpanStyle } from '../Typography';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SearchBoxProps = {
  handleSearch?: (value: string) => void | string;
  bgColor?: string;
  withIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SearchBoxStyled = styled.input<SearchBoxProps>`
  ${SpanStyle}
  height: calc(${(props) => props.theme.space.small} + ${(props) => props.theme.space.half});
  width: calc(100% - ${(props) => props.theme.space.tiny} * 2);
  padding: 0 ${(props) => props.theme.space.tiny};
  padding-right: ${(props) => props.theme.space.large};
  border: 1px solid ${(props) => props.theme.color.gray2};
  border-radius: 5px;
  background-color: ${(props) => props.bgColor || props.theme.color.gray1};
  color: ${(props) => props.theme.color.black};
  text-overflow: ellipsis;

  &::placeholder {
    color: ${(props) => props.theme.color.gray3};
  }

  &:focus {
    outline: none;
  }
`;

const SearchBoxWithIconContainer = styled.div`
  position: relative;
  margin-left: ${(props) => props.theme.space.tiny};
`;

const SearchBoxWithIcon = styled(SearchBoxStyled)`
  width: 336px;
`;

const SearchBoxWithIconInnerContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 21.5px;
  transform: translateY(-50%);
  color: ${(props) => props.theme.color.gray3};
`;

const SearchBox = (props: SearchBoxProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');

  const boxRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.handleSearch && props.handleSearch(searchValue);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  return !props.withIcon ? (
    <SearchBoxStyled ref={boxRef} {...props} value={searchValue} onChange={handleChange} />
  ) : (
    <SearchBoxWithIconContainer>
      <SearchBoxWithIcon ref={boxRef} {...props} value={searchValue} onChange={handleChange} />
      <SearchBoxWithIconInnerContainer>
        <FontAwesomeIcon icon={faSearch} />
      </SearchBoxWithIconInnerContainer>
    </SearchBoxWithIconContainer>
  );
};

export default SearchBox;
