import { styled } from '../../config/Theme';

export type ActionContainerProps = {
  alignment?: 'start' | 'end';
};

export default styled.div<ActionContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) => (props.alignment === 'end' ? 'justify-content: flex-end;' : 'justify-content: flex-start;')};

  & > * {
    ${(props) =>
      props.alignment === 'start' &&
      `
      margin-right: ${props.theme.space.tiny};
    `};

    ${(props) =>
      props.alignment === 'end' &&
      `
      margin-left: ${props.theme.space.tiny};
    `};
  }
`;
