import React, { FC, useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../config/Theme';

import PageWrapper from '../components/Layout/PageWrapper';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import NavHeader from '../components/Layout/NavHeader';
import HollowButton from '../components/Button/HollowButton';
import Title from '../components/Layout/Title';
import { H1 } from '../components/Typography';
import Card from '../components/Cards/Card';

import { ScreenshotTab } from '../components/LogPage/ScreenshotTab';

export type TabDataObject = {
  campaignId?: string;
  mediumId?: string;
};

const Logs: FC = (): JSX.Element => {
  return (
    <PageWrapper bgColor={theme.color.gray1}>
      <ContentWrapper>
        <NavHeader>
          <HollowButton icon={faArrowLeft} onClick={() => (location.href = '/overview')}>
            Áttekintés
          </HollowButton>
        </NavHeader>

        <Title>
          <H1>Rendszer logok</H1>
        </Title>

        <Card>
          <ScreenshotTab />
        </Card>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Logs;
