import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import HollowButton from '../components/Button/HollowButton';
import CreativeCard from '../components/Cards/CreativeCard';
import UrlListCard from '../components/Cards/UrlListCard';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import { Grid } from '../components/Layout/Grid';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import Title from '../components/Layout/Title';
import ActionContainer from '../components/Spacing/ActionContainer';
import ColumnWrapper from '../components/Spacing/ColumnWrapper';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import { H1 } from '../components/Typography';
import { theme } from '../config/Theme';
import useAPI from '../hooks/useAPI';
import Creative from '../models/Creative';
import Medium from '../models/Medium';

const CreativesPage: FunctionComponent = () => {
  const searchParams = new URLSearchParams(useLocation().search);

  if (!searchParams.has('mediumId')) {
    location.href = '/error';
  }

  const mediumId = searchParams.get('mediumId');

  const [{ data: medium }] = useAPI<Medium>(`/mediums/${mediumId}`);

  const [{ data: creatives }] = useAPI<[Creative[], number]>(`/creatives?mediumId=${mediumId}`);

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <ActionContainer alignment='start'>
              <HollowButton icon={faArrowLeft} onClick={() => (location.href = `/mediums/${mediumId}`)}>
                Vissza
              </HollowButton>
            </ActionContainer>
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <H1>{medium?.name}</H1>
            </ActionContainer>

            <ActionContainer alignment='end'>
              <UrlListCard urls={medium?.urls || []} />
            </ActionContainer>
          </Title>

          <Title>
            <ColumnWrapper>
              <Grid width='25rem' height='20rem'>
                {creatives && creatives[0] && creatives[0].map((c) => <CreativeCard key={c.id} creative={c} />)}
              </Grid>
            </ColumnWrapper>
          </Title>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
      </Footer>
    </>
  );
};

export default CreativesPage;
