import React, { HTMLProps } from 'react';
import { styled, theme } from '../../config/Theme';
import { DropdownOption } from '../Editable/Dropdown';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';

export type DropDownOptionProps<T> = {
  option: DropdownOption<T>;
  onSelected: (e: React.MouseEvent) => void;
} & HTMLProps<HTMLDivElement>;

export const OptionBadge = styled.div<{ bgColor: string }>`
  border-radius: 5px;
  background-color: ${(props) => props.bgColor};
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${(props) => props.theme.space.half};
  user-select: none;

  :hover {
    background-color: ${(props) => props.theme.color.white};
  }
`;

export type PageSizeOptionItemProps = {
  bgColor?: string;
} & DropDownOptionProps<number | string>;

const PageSizeOptionItem = (props: PageSizeOptionItemProps): JSX.Element => {
  return (
    <OptionBadge
      color={theme.color.black}
      onClick={(e) => props.onSelected(e)}
      bgColor={props.bgColor ?? theme.color.gray2}
    >
      <Flex row align='center' justify='center'>
        <Span color={theme.color.black}>{props.option.value}</Span>
      </Flex>
    </OptionBadge>
  );
};

export default PageSizeOptionItem;
