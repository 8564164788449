import moment from 'moment-with-locales-es6';
import Config from '../config/Config';
import TokenResponse from '../models/TokenResponse';

export enum Token {
  ACCESS = 'access_token',
  REFRESH = 'refresh_token',
}

const setBoth = (response: TokenResponse): void => {
  if (!response.accessToken || !response.expiresIn || !response.refreshToken) {
    throw new Error('Bad token response format');
  }

  setToken(Token.ACCESS, response.accessToken, moment().add(response.expiresIn, 'seconds'));
  setToken(Token.REFRESH, response.refreshToken, moment().add(Config.refreshTokenExpiry, 'seconds'));
};

const setToken = (token: Token, value?: string, expiry?: number): void => {
  const combinedValue = JSON.stringify({ value, expiry });
  localStorage.setItem(token, combinedValue);
};

const getToken = (token: Token): string | undefined => {
  try {
    const combinedValue = localStorage.getItem(token);
    if (!combinedValue) return;

    return JSON.parse(combinedValue).value;
  } catch (err) {
    console.error(err);
    return;
  }
};

const removeToken = (token: Token): void => {
  localStorage.removeItem(token);
};

const isInvalid = (token: Token): boolean => {
  try {
    const combinedValue = localStorage.getItem(token);
    if (!combinedValue) return true;

    const expiry = JSON.parse(combinedValue).expiry;

    return !expiry || expiry < Date.now();
  } catch (err) {
    console.error(err);
    return true;
  }
};

export default {
  setBoth,
  getToken,
  setToken,
  removeToken,
  isInvalid,
};
