import React, { HTMLProps } from 'react';
import { styled } from '../../config/Theme';

const TableWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TableWrapperComponent = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
  return <TableWrapper>{props.children}</TableWrapper>;
};

export default TableWrapperComponent;
