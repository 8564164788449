import React from 'react';
import { styled } from '../../config/Theme';
import { ChildrenProps } from '../../utils/Types';
export type ButtonProps = {
  bgcolor: string;
  color: string;
  hoverColor?: string;
  pureMediaModalButton?: boolean;
  variation?: 'create' | 'default';
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  ChildrenProps;

const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontsize.small};
  font-family: 'Rubik';

  &:hover {
    outline: none;
    border: none;
    background-color: ${(props) => props.hoverColor || props.color};
    color: ${(props) => (props.hoverColor ? props.color : props.bgcolor)};
  }

  &:active {
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color};
  }
`;

const ButtonComponent = (props: ButtonProps): JSX.Element => {
  return <Button {...props}>{props.children}</Button>;
};

export default ButtonComponent;
