import React from 'react';
import { styled } from '../../config/Theme';
import { ChildrenProps } from '../../utils/Types';

const Footer = styled.footer`
  width: calc(100% - ${(props) => props.theme.space.small} * 2);
  padding: ${(props) => props.theme.space.medium} ${(props) => props.theme.space.small};
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-shrink: 0;
  background-color: ${(props) => props.theme.color.gray1};

  div {
    grid-column-start: 2;
  }

  span {
    grid-column-start: 1;
  }
`;

const FooterComponent = (props: ChildrenProps): JSX.Element => {
  return <Footer>{props.children}</Footer>;
};

export default FooterComponent;
