import { styled } from '../../config/Theme';
type CardProps = {
  boxSizing?: string;
  width?: string;
};
export default styled.div<CardProps>`
  background-color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.lsmall};
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: calc(100% - ${(props) => props.theme.space.tiny} * 2);
  box-sizing: ${(props) => props.boxSizing || 'inherit'};
`;
