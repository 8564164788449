import React from 'react';
import { styled } from '../../config/Theme';
import Column from './Column';

const Cell = styled.td<{ ellipsize?: boolean }>`
  padding: 0 ${(props) => props.theme.space.tiny};
  border: 1px solid ${(props) => props.theme.color.gray2};
  max-width: 0;
  white-space: nowrap;
  position: relative;
  width: ${(props) => props.width + '%' || 'auto'};

  ${(props) => props.ellipsize && `overflow: hidden; text-overflow: ellipsis;`}
`;

export const ActionCellContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Row = styled.tr<{ cursor: string }>`
  height: ${(props) => props.theme.space.medium};
  border: 1px solid ${(props) => props.theme.color.gray2};
  cursor: ${(props) => props.cursor};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.color.gray1};
  }

  &:hover {
    background-color: ${(props) => props.theme.color.hover};
  }
`;

type TableBodyProps<T> = {
  columns: Column<T>[];
  rows: T[];
  idKey: keyof T;
  onRowClick?: (row: T) => void;
  cursor?: string;
};

function TableBody<T>({ columns, rows, idKey, onRowClick, cursor = 'pointer' }: TableBodyProps<T>) {
  return (
    <tbody>
      {rows.map((row) => {
        return (
          <Row key={row[idKey] as unknown as string} onClick={() => onRowClick && onRowClick(row)} cursor={cursor}>
            {columns.map((column) => {
              if (column.id === 'action') {
                return (
                  <Cell key={column.id}>
                    <ActionCellContainer>{column.getter(row)}</ActionCellContainer>
                  </Cell>
                );
              }
              return <Cell key={column.id}>{column.getter(row)}</Cell>;
            })}
          </Row>
        );
      })}
    </tbody>
  );
}

export default TableBody;
