import React, { useRef } from 'react';
import useAPI from '../../hooks/useAPI';
import Toaster from '../../utils/Toaster';

export type DownloadProps = {
  url: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Download = (props: DownloadProps): JSX.Element => {
  const hiddenFormRef = useRef<HTMLFormElement>(null);
  const [, getDownloadUrl] = useAPI(props.url, { manual: true });

  const handleClick = async () => {
    const form = hiddenFormRef.current;
    if (!form) {
      Toaster.unknownError();
      return;
    }

    const url = await getDownloadUrl().then((res) => {
      if (res.data && typeof res.data === 'string') {
        return new URL(res.data);
      }
    });

    if (!url) {
      Toaster.unknownError();
      return;
    }

    form.innerHTML = '';
    url.searchParams.forEach((value, key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      hiddenFormRef.current?.appendChild(input);
    });

    form.action = url.href;
    form.submit();
  };

  return (
    <div onClick={handleClick}>
      <form method='GET' ref={hiddenFormRef} />
      {props.children}
    </div>
  );
};

export default Download;
