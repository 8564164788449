import { styled } from '../../config/Theme';

export default styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  justify-content: flex-start;
  align-items: flex-start;
`;
