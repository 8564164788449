/* eslint-disable max-len */
/* eslint-disable no-invalid-this */
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { Key } from 'ts-key-enum';
import { styled, theme } from '../../config/Theme';
import CreativeType from '../../enums/CreativeType';
import useAPI, { API } from '../../hooks/useAPI';
import { useGetFile } from '../../hooks/useUpload';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import Toaster from '../../utils/Toaster';
import Badge from '../Badge/Badge';
import IconButton from '../Button/IconButton';
import Flex from '../Spacing/Flex';
import { Ellipsized, Span } from '../Typography';
import { DayInput, EditDayBadge } from './CreativeModal';
import RemoteImage from './RemoteImage';
export const ImageContainer = styled(Flex)`
  flex-direction: column;
  max-height: max-content;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.space.small};
  background-color: ${(props) => props.theme.color.gray2};
  border-radius: 5px;
  padding: ${(props) => props.theme.space.half};
`;

export type CreativeVariationProps = {
  creative?: Creative;
  variation: CreativeVariation;
  updateVariation: (creative: CreativeVariation) => void;
  openPicker: () => void;
  updateCreative: (creative: Creative) => void;
};

const DateBadge = styled(Badge)`
  background-color: ${(props) => props.theme.color.gray2};
  padding: ${(props) => props.theme.space.half} ${(props) => props.theme.space.quarter};
`;

const CreativeVariationItem = (props: CreativeVariationProps): JSX.Element => {
  const _URL = window.URL || window.webkitURL;

  const [duration, setDuration] = useState<number>(props.variation?.duration ?? 0);

  const [deleteVariationResponse, deleteVariation] = useAPI<CreativeVariation>(
    {
      url: `/creatives/${props.creative?.id}/variations/${props.variation.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [getCreativeResponse, getCreative] = useAPI<Creative>({ method: 'GET' }, { manual: true });

  const onFileSelected = async (files: File[]) => {
    const data = new FormData();
    data.append('file', files[0]);
    API.post<CreativeVariation>(`/creatives/${props.creative?.id}/variations/${props.variation.id}/upload`, data)

      .then((res) => {
        if (res.data) {
          const variation = res.data;

          if (!variation) throw Error();
          variation.dateRanges = props.variation?.dateRanges;
          variation.start = props.variation?.start;
          variation.end = props.variation?.end;

          props.updateCreative({
            ...props.creative,
            width: props.creative?.width ?? variation.creative?.width,
            height: props.creative?.height ?? variation.creative?.height,
            variations: [
              ...(props.creative?.variations?.filter((v) => v.id && v.id !== variation?.id) ?? []),
              variation,
            ],
          });
        } else if (!res.data && (files[0].name.includes('.zip') || files[0].name.includes('.html'))) {
          Toaster.warn(
            `A ${files[0].name} fájl nem tartalmazza a 'banner-info' információt, ezért nem lehet feltölteni a rendszerbe!`
          );
        } else {
          Toaster.warn('Videó mérete nem egyezik a kreatív méretével!');
        }
      })

      .catch(() => Toaster.unknownError());
  };

  const handleImageFile = async (files: File[]) => {
    const img = new Image();
    img.addEventListener('load', () => {
      if (
        (!props.creative?.width && !props.creative?.height) ||
        (img.width === props.creative?.width && img.height === props.creative?.height)
      ) {
        onFileSelected(files);
      } else {
        Toaster.warn('Kép mérete nem egyezik a kreatív méretével!');
      }
    });
    img.src = _URL.createObjectURL(files[0]);
  };

  const handleVideoFile = async (files: File[]) => {
    /* const video = document.createElement('video');
        video.addEventListener(
            'loadedmetadata',
            function() {
                if ((!props.creative?.width && !props.creative?.height) ||
                this.videoWidth === props.creative?.width &&
                this.videoHeight === props.creative?.height) {
                    onFileSelected(files);
                } else {
                    Toaster.warn('Videó mérete nem egyezik a kreatív méretével!');
                }
            });
        video.src = _URL.createObjectURL(files[0]);*/
    onFileSelected(files);
  };

  const handleHTMLFile = async (files: File[]) => {
    // if (!props.creative?.width || !props.creative?.height) {
    //     Toaster.warn('HTML feltöltés előtt állítson be méretet!');
    // } else if (!duration) {
    //     Toaster.warn('HTML feltöltés előtt állítson be hosszt!');
    // } else {
    // }
    onFileSelected(files);
  };

  const handleDynamicInterstitial = (files: File[]) => {
    onFileSelected(files);
  };

  const getImageFile = useGetFile({
    multiple: true,
    accept: ['.jpg', '.png', '.jpeg'],
    callback: handleImageFile,
  });

  const getVideoFile = useGetFile({
    multiple: true,
    accept: ['.mp4'],
    callback: handleVideoFile,
  });

  const getHTMLFile = useGetFile({
    multiple: true,
    accept: ['.html', '.zip'],
    callback: handleHTMLFile,
  });

  const getDynamicInterstitial = useGetFile({
    multiple: true,
    accept: ['.html', '.zip', '.mp4'],
    callback: handleDynamicInterstitial,
  });

  const handleUpload = (e: React.MouseEvent) => {
    switch (props.creative?.type) {
      case CreativeType.STATIC:
        getImageFile(e);
        break;
      case CreativeType.VIDEO:
        getVideoFile(e);
        break;
      case CreativeType.DYNAMIC:
        getHTMLFile(e);
        break;
      case CreativeType.INTERSTITIAL_STATIC:
        getImageFile(e);
        break;
      case CreativeType.INTERSTITIAL_DYNAMIC:
        getDynamicInterstitial(e);
        break;
      case CreativeType.HANGAR:
        getImageFile(e);
        break;
    }
  };

  const removeVariation = async () => {
    const result = await deleteVariation();

    if (deleteVariationResponse.error || !result) {
      console.error(deleteVariationResponse.error);
      Toaster.error('Sikertelen törlés!');
      return;
    }

    Toaster.success('Sikeres törlés!');

    const updatedCreative = await getCreative({ url: `/creatives/${props.creative?.id}` });

    props.updateCreative({
      ...updatedCreative.data,
      variations: props.creative?.variations?.filter((v) => v.id != props.variation.id) ?? [],
    });
  };

  const onDurationBlur = () => {
    props.updateVariation({
      ...props.variation,
      duration,
    });
  };

  const onDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = parseInt(e.target.value.replace(/\D/, '').substring(0, 3), 10);
    if (input === props.variation?.duration) return;
    if (!input || isNaN(input)) input = 0;

    setDuration(input);
  };

  return (
    <Flex
      row
      justify='space-between'
      width='95%'
      style={{ padding: theme.space.tiny, height: 'max-content' }}
      align='center'
    >
      <Flex width='50%' align='center'>
        {props.variation.bannerUploaded ? (
          <ImageContainer width='12rem'>
            <Flex column height={theme.space.large}>
              <RemoteImage
                alt='Feldolgozás alatt...'
                url={`/creatives/${props.creative?.id}/variations/${props.variation?.id}/download`}
                source={`/creatives/${props.creative?.id}/variations/${props.variation.id}/binary`}
              />
            </Flex>
            <Ellipsized style={{ textAlign: 'center' }}>{props.variation.originalFilename}</Ellipsized>
          </ImageContainer>
        ) : (
          <ImageContainer width='12rem' style={{ cursor: 'pointer' }} onClick={handleUpload}>
            <Flex row align='center' justify='center' width={theme.space.large} height={theme.space.large}>
              <Span color={theme.color.gray3} style={{ whiteSpace: 'nowrap' }}>
                Kreatív feltöltése
              </Span>
            </Flex>
          </ImageContainer>
        )}

        <DateBadge onClick={props.openPicker}>
          <Flex row style={{ padding: `0 ${theme.space.tiny}` }}>
            <FontAwesomeIcon icon={faCalendar} style={{ marginRight: theme.space.half }} />
            {props.variation?.start && props.variation?.end ? (
              <Span style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                {moment(props.variation.start).format('YYYY.MM.DD')}-{moment(props.variation.end).format('YYYY.MM.DD')}
              </Span>
            ) : (
              <Span color={theme.color.gray3} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                Dátum megadása
              </Span>
            )}
          </Flex>
        </DateBadge>

        {(props.creative?.type === CreativeType.DYNAMIC ||
          props.creative?.type === CreativeType.INTERSTITIAL_DYNAMIC) && (
          <EditDayBadge style={{ marginLeft: theme.space.small }}>
            <Span className='duration'>hossz:</Span>
            <DayInput
              style={{ marginLeft: theme.space.half, width: theme.space.small }}
              value={duration ?? 0}
              onBlur={onDurationBlur}
              onKeyDown={(e) => {
                if (e.key === Key.Enter) onDurationBlur();
              }}
              onChange={(e) => onDurationChange(e)}
            />
            <Span className='duration'>mp</Span>
          </EditDayBadge>
        )}
      </Flex>

      <Flex width='50%' justify='flex-end'>
        <Span color={theme.color.gray3} style={{ marginRight: theme.space.medium }}>
          Elkészített screenshotok száma : {props.variation.matchNumber ?? 0}
        </Span>
        <IconButton
          icon={faTrash}
          color={theme.color.gray3}
          onClick={() => removeVariation()}
          style={{ marginRight: theme.space.half }}
        />
      </Flex>
    </Flex>
  );
};

export default CreativeVariationItem;
