import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { theme } from '../../config/Theme';

import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import useMatcherErrorColumns from '../../hooks/useMatcherErrorColumns';
import { MatcherErrorScreenshot } from '../Layout/MatcherErrorsModal';

import Flex from '../Spacing/Flex';
import IconButton from '../Button/IconButton';

import NewTable from './NewTable';

const MixRowContainer = styled.div<{ selected?: boolean; bigError?: boolean }>`
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid #fff;
  background-color: ${(props) => (props.bigError ? props.theme.color.warningRed : props.theme.color.warningGrey)};
`;

const MixRow = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.space.medium};
`;

const MixRowText = styled.span<{ bigError?: boolean }>`
  font-weight: 500;
  color: ${(props) => (props.bigError ? 'white' : '')};
`;

const MixCell = styled.div<{ ellipsize?: boolean }>`
  padding: 0 ${(props) => props.theme.space.tiny};
  max-width: 0;
  white-space: nowrap;

  ${(props) =>
    props.ellipsize &&
    `overflow: hidden;
    text-overflow: ellipsis;`}
`;

const Divider = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  ::before {
    content: '|';
  }
`;

type MixErrorRowProps = {
  mediumName: string;
  mediumData: string;
  screenshot: MatcherErrorScreenshot[];
  creativeSelection: (creativeId: string) => void;
  excludeScreenshot: (id: string) => void;
  updateScreenshots: (updatedScreenshots: MatcherErrorScreenshot[]) => void;
};

const MixErrorRow: FC<MixErrorRowProps> = ({
  mediumName,
  mediumData,
  creativeSelection,
  excludeScreenshot,
  screenshot,
  updateScreenshots,
}): JSX.Element => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const mediumProperties = mediumData.split(',');
  const isBigError = screenshot.every((shot) => shot.isBigError);

  const excludeAllScreenshot = (): void => {
    // Uncheck all if everythings already selected
    const everythingSelectedAlready: boolean = screenshot.every((shot) => shot.exclude === true);

    const updatedScreenshots = screenshot.map((shot) => {
      shot.exclude = everythingSelectedAlready ? false : true;
      return shot;
    });

    setSelectAll(!everythingSelectedAlready);
    updateScreenshots(updatedScreenshots);
  };

  useEffect(() => {
    setSelectAll(() => {
      const everythingSelectedAlready: boolean = screenshot.every((sShot) => sShot.exclude === true);
      return everythingSelectedAlready;
    });
  }, [screenshot]);

  const columnHeaders = useMatcherErrorColumns(
    selectAll,
    excludeAllScreenshot,
    mediumName,
    creativeSelection,
    excludeScreenshot
  );

  const renderNestedTable = (): JSX.Element => {
    return <NewTable columns={columnHeaders} data={screenshot} idKey={'id'} filterRow={<></>} />;
  };

  return (
    <MixRowContainer selected={isSelected} bigError={isBigError}>
      <MixRow>
        <MixCell>
          <Flex justify='center' align='center'>
            <IconButton
              size='lg'
              icon={isSelected ? faAngleUp : faAngleDown}
              color={theme.color.primary}
              hoverColor={theme.color.secondary}
              onClick={() => setIsSelected((val) => !val)}
            />
          </Flex>
        </MixCell>
        <MixCell>
          <MixRowText bigError={isBigError}>
            {mediumProperties[1]}
            <Divider />
            Mix csomag
            <Divider />
            {moment(mediumProperties[2]).format('YYYY.MM.DD.')}
          </MixRowText>
        </MixCell>
      </MixRow>
      {isSelected && renderNestedTable()}
    </MixRowContainer>
  );
};

export default MixErrorRow;
