import React from 'react';
import { theme } from '../../config/Theme';
import useAPI from '../../hooks/useAPI';
import { useDownload } from '../../hooks/useDownload';
import Creative from '../../models/Creative';
import Screenshot from '../../models/Screenshot';
import Progress from '../Badge/Progress';
import Link from '../Link/Link';
import ColumnWrapper from '../Spacing/ColumnWrapper';
import Flex from '../Spacing/Flex';
import { Small, Span } from '../Typography';
import GridCard from './GridCard';
import ScreenshotPager from './ScreenshotPager';

type CreativeCardProps = {
  creative?: Creative;
};

const CreativeCard = (props: CreativeCardProps): JSX.Element => {
  const variationIds = props.creative?.variations?.map((v) => v.id).join(',');

  const screenshotUrl = `/screenshots?variation_ids=${variationIds}&status=success`;

  const [{ data: screenshots }] = useAPI<Screenshot[]>({ url: screenshotUrl });

  const downloadScreenshots = useDownload({ url: `/screenshots/download?variation_ids=${variationIds}` });

  const hasScreenshots = !!(screenshots && screenshots.length > 0);

  return (
    <ColumnWrapper>
      <GridCard width='25rem' height='20rem'>
        <Flex column width='100%' justify='space-between'>
          <Flex row justify='space-between' height='15%'>
            {props.creative?.width && props.creative?.height ? (
              <Span>{props.creative?.width + 'x' + props.creative?.height}</Span>
            ) : (
              <Span>-</Span>
            )}
            {hasScreenshots && (
              <Link onClick={() => (location.href = `/screenshots?creative_id=${props.creative?.id}`)}>Megnyitás</Link>
            )}
          </Flex>

          <ScreenshotPager screenshots={screenshots ?? []} />

          <Flex justify='space-between' align='flex-end' height='15%'>
            <Progress
              daysProposed={props.creative?.daysProposed}
              daysShown={props.creative?.successCount}
              bgColor={theme.color.white}
            />

            {hasScreenshots && (
              <Link onClick={downloadScreenshots}>
                <Small>Screenshotok letöltése</Small>
              </Link>
            )}
          </Flex>
        </Flex>
      </GridCard>
    </ColumnWrapper>
  );
};

export default CreativeCard;
