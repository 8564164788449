import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from '../../config/Theme';
import CreativeType from '../../enums/CreativeType';
import useAPI from '../../hooks/useAPI';
import useModal from '../../hooks/useModal';
import Creative from '../../models/Creative';
import Resolution from '../../models/Resolution';
import Toaster from '../../utils/Toaster';
import DropDownBadge, { DDBadge } from '../Badge/DropDownBadge';
import ResolutionOptionItem, { OptionBadge } from '../Badge/ResolutionOptionItem';
import ResolutionModal from '../Cards/ResolutionModal';
import Flex from '../Spacing/Flex';
import { DropdownOption } from './Dropdown';

type ResolutionDropdownProps = {
  bgColor?: string;
  creative?: Creative;
  wide?: boolean;
  wrapContent?: boolean;
  updateCreative: (creative: Creative) => void;
};

const ResolutionDropdown = (props: ResolutionDropdownProps): JSX.Element => {
  const newOptionValue = 'NEW';

  const [isOpen, toggle] = useModal();

  const [isCreateOpen, toggleCreate] = useModal();

  const [, deleteResolution] = useAPI({ method: 'DELETE' }, { manual: true });

  const [resolutions, setResolutions] = useState<Resolution[]>([]);

  const [, getResolutions] = useAPI<Resolution[]>('/resolutions', { manual: true });

  const hasUploaded = useMemo<boolean>(
    () => props.creative?.variations?.some((v) => v.bannerUploaded) ?? false,
    [props.creative?.variations]
  );

  const handleResolutionSelected = useCallback(
    (e: React.MouseEvent, option: DropdownOption<string>) => {
      if (!option || !option.value) {
        Toaster.error('Hibás felbontás!');
        return;
      }

      if (hasUploaded) {
        Toaster.warn('Feltöltés után a felbontás nem állítható!');
        return;
      }

      const resolution = resolutions?.find((r) => r.id === option.value);
      if (!resolution || !resolution.width || !resolution.height) {
        Toaster.error('Hibás felbontás!');
        return;
      }

      props?.updateCreative({
        ...props.creative,
        width: resolution.width,
        height: resolution.height,
      });
    },
    [hasUploaded, props, resolutions]
  );

  const handleResolutionDeleted = useCallback(
    async (e: React.MouseEvent, option: DropdownOption<string>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!option || !option.value) {
        Toaster.error('Sikertelen törlés!');
        return;
      }

      const resolution = resolutions?.find((r) => r.id === option.value);
      if (!resolution) {
        Toaster.error('Sikertelen törlés!');
        return;
      }

      setResolutions(resolutions.filter((r) => r.id !== resolution.id));
      await deleteResolution({ url: `/resolutions/${resolution.id}` });
    },
    [resolutions]
  );

  const addResolution = (resolution: Resolution) => {
    setResolutions([...resolutions, resolution]);
    props.updateCreative({
      ...props.creative,
      width: resolution.width,
      height: resolution.height,
    });
  };

  const resolutionOptions = useMemo(
    () =>
      resolutions?.map<DropdownOption<string>>((r: Resolution) =>
        Object.create({
          value: r.id,
          label: `${r.width}x${r.height}`,
          selected: r.width === props.creative?.width && r.height === props.creative?.height,
        })
      ),
    [props.creative?.height, props.creative?.width, resolutions]
  );

  const alt = useMemo(() => {
    if (props.creative?.width && props.creative.height) {
      return `${props.creative?.width}x${props.creative?.height}`;
    } else {
      return 'Nincs';
    }
  }, [props.creative?.width, props.creative?.height]);

  useEffect(() => {
    getResolutions().then((res) => setResolutions(res.data));
  }, []);

  if (props.creative?.type === CreativeType.ARTICLE) {
    return <span>-</span>;
  } else {
    return hasUploaded ? (
      <DDBadge bgColor={props.bgColor ?? theme.color.gray2} wide={props.wide}>
        <Flex row justify='center' align='center'>
          {props.creative?.width}x{props.creative?.height}
        </Flex>
      </DDBadge>
    ) : (
      <>
        <DropDownBadge
          wide={props.wide}
          wrapContent={props.wrapContent}
          options={resolutionOptions ?? []}
          bgColor={props.bgColor}
          isOpen={isOpen}
          toggle={toggle}
          alt={alt}
        >
          {resolutionOptions?.map((o, i) => (
            <ResolutionOptionItem
              key={i}
              option={o}
              bgColor={props.bgColor}
              onSelected={(e) => handleResolutionSelected(e, o)}
              onDelete={(e) => handleResolutionDeleted(e, o)}
            />
          ))}
          <OptionBadge bgColor={theme.color.gray2} onClick={() => toggleCreate(true)}>
            <Flex row align='center' justify='center'>
              Új felbontás
            </Flex>
          </OptionBadge>
        </DropDownBadge>

        <ResolutionModal
          isOpen={isCreateOpen}
          toggle={toggleCreate}
          resolutions={resolutions}
          addResolution={addResolution}
        />
      </>
    );
  }
};

export default ResolutionDropdown;
