import React, { HTMLProps, useEffect, useState } from 'react';
import { styled } from '../../config/Theme';
import { API } from '../../hooks/useAPI';
import { useDownload } from '../../hooks/useDownload';
import Flex from '../Spacing/Flex';
import { Muted } from '../Typography';
import { DimensionsType } from './MediaStoreModal';

const ImageStyled = styled.div<{ src: string; clickable?: boolean }>`
  width: 14rem;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  text-align: 'center';
  display: flex;
  justify-content: flex-end;
  ${(props) => props.clickable && 'cursor: pointer;'}
`;

const EmptyImageStyled = styled.div`
  width: 14rem;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
  display: flex;
  justify-content: space-between;
`;

const RibbonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ExtensionRibbon = styled.div`
  position: relative;
  font-weight: 500;
  width: auto;
  top: 0px !important;
  align-self: flex-end;
  padding: 6px;
  height: auto;
  background-color: #061354;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

const SizeRibbon = styled.div`
  position: relative;
  font-weight: 500;
  width: auto;
  align-self: flex-end;
  padding: 6px;
  height: auto;
  background-color: #061354;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

const BannerSpan = styled.div`
  font-size: ${(props) => props.theme.fontsize.tiny};
  color: ${(props) => props.theme.color.gray3};
  margin-left: 40%;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: center;
`;

export type ImageProps = {
  file?: File;
  source?: string;
  mimeType?: string;
  filename?: string;
  campaignId?: string;
  alt?: string;
  url?: string;
  dimensions: DimensionsType;
  onGotSize?: (width: number, height: number) => void;
} & HTMLProps<HTMLDivElement>;

const RemoteMediaModalImage = (props: ImageProps): JSX.Element => {
  const [image, setImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [width, setWidth] = useState<number | undefined>(0);
  const [height, setHeight] = useState<number | undefined>(0);
  const [fileType, setFileType] = useState<string>('');

  const downloadImage = useDownload({ url: props.url });

  useEffect(() => {
    if (!props.source) return;
    setIsLoading(true);
    API.get(props.source)
      .then((res) => {
        let fileType = '';

        if (props.mimeType) {
          fileType = props.mimeType.split('/')[1];

          setFileType(fileType);
        }

        setWidth(props.dimensions!.width);
        setHeight(props.dimensions!.height);

        if (res?.data?.source) {
          setImage(`data:${props.mimeType || 'image/jpeg'};base64,` + res.data.source);
        } else if (res?.data) {
          setImage(`data:${props.mimeType || 'image/jpeg'};base64,` + res.data);
        } else {
          setImage('');
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [props.mimeType, props.source]);

  // If it's a file read it and set the source
  useEffect(() => {
    if (!props.file) return;

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // setImageSrc(typeof(reader.result) === 'string' ? reader.result : undefined);
        const imgSrc: string = typeof reader.result === 'string' ? reader.result : '';
        setImage(imgSrc);
        setIsLoading(false);

        let type = '';

        if (props.file && props.file.name) {
          const splittedFileName = props.file.name.split('.');
          type = splittedFileName[splittedFileName.length - 1];

          setFileType(type);
        }

        // Detect image size
        const img = new Image();
        img.onload = function () {
          if (props.onGotSize) {
            setWidth(img.width);
            setHeight(img.height);
            props.onGotSize(img.width, img.height);
          }
        };
        img.src = imgSrc;
      },
      false
    );
    reader.readAsDataURL(props.file);
  }, [props.file]);

  if (isLoading) {
    return (
      <Flex row width='72%' height='100%' align='center' justify='center'>
        <Muted>Betöltés...</Muted>
      </Flex>
    );
  } else if (!image && image.length <= 0) {
    return (
      <EmptyImageStyled>
        <BannerSpan>
          <div>Banner</div>
        </BannerSpan>
        <RibbonContainer>
          {fileType !== '' && <ExtensionRibbon>{fileType}</ExtensionRibbon>}
          {width && height && (
            <SizeRibbon>
              {width}x{height}
            </SizeRibbon>
          )}
        </RibbonContainer>
      </EmptyImageStyled>
    );
  } else {
    return (
      <ImageStyled src={image} clickable={Boolean(props.url)} onClick={downloadImage}>
        <RibbonContainer>
          {fileType !== '' && <ExtensionRibbon>{fileType}</ExtensionRibbon>}
          {width && height && (
            <SizeRibbon>
              {width}x{height}
            </SizeRibbon>
          )}
        </RibbonContainer>
      </ImageStyled>
    );
  }
};

export default RemoteMediaModalImage;
