import React, { HTMLAttributes } from 'react';
import { styled } from '../../config/Theme';

const UserItemStyled = styled.div`
  margin-top: ${(props) => props.theme.space.tiny};
  width: 60%;
  display: grid;
  grid-template: 1fr / 100% ${(props) => props.theme.space.small};
`;

const UserItem = (props: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return <UserItemStyled>{props.children}</UserItemStyled>;
};

export default UserItem;
