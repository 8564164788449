import Entity from '../interfaces/IEntity';
import User from './User';

export default class UserGroup implements Entity {
  constructor(
    public id?: string,
    public created?: Date,
    public modified?: Date,
    public name?: string,
    public users?: User[],
    public creatorId?: string,
    public addresses?: string[]
  ) {}
}
