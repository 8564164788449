import { styled } from '../../config/Theme';

export default styled.a`
  font-size: ${(props) => props.theme.fontsize.small};
  color: ${(props) => props.theme.color.primary};
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.secondary};
    opacity: 1;
  }
`;
