import styled from 'styled-components';
import { theme } from '../../config/Theme';
import Flex from '../Spacing/Flex';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) => props.theme.space.large};
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1041;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.color.gray3};
  opacity: 0.5;
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1060;
  width: 600px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  background-color: ${(props) => props.theme.color.gray1};
`;

export const ModalHeader = styled(Flex)`
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  flex-direction: row;
  align-items: center;
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
  padding: 0 ${(props) => props.theme.space.small};
  border-radius: 5px 5px 0px 0px;
  background-color: ${(props) => props.theme.color.white};
`;

export const ModalBody = styled(Flex)`
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
`;

export const ModalFooter = styled(Flex)`
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  background-color: ${(props) => props.theme.color.white};
  justify-content: space-between;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
`;

export const InfoText = styled.p`
  color: ${theme.color.gray3};
  font-weight: 500;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const RadioInput = styled.input`
  margin: 0;
  margin-right: ${(props) => props.theme.space.half};
  width: 1rem;
  height: 1rem;
  border-color: ${(props) => props.theme.color.primary};
  border: 0.15em solid ${(props) => props.theme.color.primary};
  border-radius: 50%;
`;

export const InfoBlock = styled.div`
  width: calc(100% - 2 * ${(props) => props.theme.space.tiny});
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.secondaryWithOpacity};
  padding: ${(props) => props.theme.space.tiny};
  margin-top: ${(props) => props.theme.space.xsmall};
`;

export const NameInput = styled.input`
  min-width: 260px;
  padding: ${(props) => props.theme.space.threequarter};
  margin-top: ${(props) => props.theme.space.tiny};
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid #d3d6e8;
  border-radius: 5px;
`;
