import React from 'react';
import { styled } from '../../config/Theme';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.space.tiny} 0;
`;

const TitleComponent = (props: any): JSX.Element => {
  return <Title>{props.children}</Title>;
};

export default TitleComponent;
