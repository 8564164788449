import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import Button from '../components/Button/LoginButton';
import Card from '../components/Cards/CenterCard';
import CardPageWrapper from '../components/Login/CardPageWrapper';
import ColumnWrapper from '../components/Spacing/ColumnWrapper';
import { H1, H2 } from '../components/Typography';
import { styled, theme } from '../config/Theme';

const ErrorCard = styled(Card)`
  padding: ${(props) => props.theme.space.large} ${(props) => props.theme.space.small};
`;

const ErrorPage: FunctionComponent = () => {
  return (
    <CardPageWrapper bgColor={theme.color.primary}>
      <ErrorCard>
        <ColumnWrapper>
          <H1>404</H1>

          <FontAwesomeIcon size='6x' color={theme.color.warning} icon={faExclamationTriangle} />

          <H2>A keresett oldal nem található</H2>

          <Button onClick={(e) => (location.href = '/overview')}>Vissza a főoldalra</Button>
        </ColumnWrapper>
      </ErrorCard>
    </CardPageWrapper>
  );
};

export default ErrorPage;
