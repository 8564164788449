import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import { theme } from '../../config/Theme';

type DropdownProps = {
  visible: boolean;
};
const Component = styled.div<DropdownProps>`
  position: relative;
  height: 2rem;
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  max-width: 340px;
  z-index: ${(props) => (props.visible ? 1000 : 100)};
`;

const MainInput = styled.div`
  background-color: ${(props) => props.theme.color.gray2};
  border-radius: 5px;
  padding: 0 0.5rem 0 0.5rem;
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;

  span {
    user-select: none;
    cursor: default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    font-size: 1rem;
    flex: 1;
  }

  svg {
    margin: 0 0.5rem 0 1rem;
    flex: 0;
  }
`;

const Dropdown = styled.div<DropdownProps>`
  background-color: ${(props) => props.theme.color.gray2};
  border: 1px solid ${(props) => props.theme.color.gray3};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.25rem;
  width: auto;
  right: 0;
  z-index: 1000;
  max-height: 10rem;
  overflow: auto;
`;

const Option = styled.div`
  padding: 0.5rem 0.25rem;
  cursor: default;
  border-radius: 5px;
  text-overflow: ellipsis;
  word-wrap: keep-all;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: white;
  }
`;

export type SimpleDropdownOption = {
  id: string;
  label: string;
  default?: boolean;
};

type SimpleDropdownProps = {
  placeholder?: string;
  options: SimpleDropdownOption[];
  onChange: (selected: SimpleDropdownOption) => void;
};

const SimpleDropdown = (props: SimpleDropdownProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState<SimpleDropdownOption>(
    props.placeholder ? { label: props.placeholder, id: '' } : props.options[0]
  );
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const defaultOption = props.options.find((option) => option.default === true);
    if (defaultOption) {
      setSelected(defaultOption);
    }
  }, [props.options]);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const select = (option: SimpleDropdownOption) => {
    setSelected(option);
    setOpen(false);
    props.onChange(option);
  };

  const close = () => {
    setOpen(false);
  };

  useOnClickOutside(componentRef, close);

  return (
    <Component ref={componentRef} visible={isOpen}>
      <MainInput onClick={toggle}>
        <span style={{ color: selected?.label !== props.placeholder ? 'inherit' : theme.color.gray3 }}>
          {selected ? selected.label : props.placeholder ?? '-'}
        </span>
        <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} color={theme.color.gray3}></FontAwesomeIcon>
      </MainInput>
      <Dropdown visible={isOpen}>
        {props.options.map((o) => (
          <Option key={o.id} onClick={() => select(o)} title={o.label}>
            {o.label}
          </Option>
        ))}
      </Dropdown>
    </Component>
  );
};

export default SimpleDropdown;
