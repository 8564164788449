import React, { useEffect, useState } from 'react';
import { styled, theme } from '../../config/Theme';
import RemoteImage from './RemoteImage';
import Screenshot from '../../models/Screenshot';
import { API } from '../../hooks/useAPI';
import { Span } from '../Typography';
import { faArrowLeft, faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const LightboxContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #9499b696;
  text-align: center;
  z-index: 100;
  padding: 2rem;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const LightBoxContent = styled.div`
  width: 100%;
  background: #f9f9f9;
  height: 100%;
`;

const LightBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  background-color: white;
  padding: 1rem 2rem;

  .close-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

const LightBoxBody = styled.div`
  height: 90%;
  position: relative;
  padding: 2rem 4rem;
  box-sizing: border-box;

  .arrow-left {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.5rem;
  }

  .arrow-right {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.5rem;
  }

  .image-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    div {
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: ;
  }
`;

type ScreenshotsLightboxProps = {
  open: boolean;
  screenshot: Screenshot | null;
  close: () => void;
  next: () => void;
  prev: () => void;
};

const ScreenshotsLightbox = ({ open, screenshot, close, next, prev }: ScreenshotsLightboxProps) => {
  const [image, setImage] = useState<string>('');
  console.log(screenshot);

  useEffect(() => {
    API.get(`/screenshots/${screenshot?.id}/binary/featured`)
      .then((res) => {
        if (res?.data?.source) setImage(`data:${screenshot?.mimeType || 'image/jpeg'};base64,` + res.data.source);
        else if (res?.data) setImage(`data:${screenshot?.mimeType || 'image/jpeg'};base64,` + res.data);
        else setImage('');
      })
      .catch((err) => {
        console.error(err);
      });
  }, [screenshot?.mimeType, screenshot?.id]);

  return (
    <LightboxContainer open={open}>
      <LightBoxContent>
        <LightBoxHeader>
          <Span color={theme.color.gray3}>
            {/* @ts-ignore */}
            {screenshot?.medium?.campaign?.name} / {screenshot?.width}x{screenshot?.height} -{' '}
            {moment(screenshot?.modified).format('YYYY.MM.DD.')}
          </Span>
          <div className='close-icon' onClick={close}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </LightBoxHeader>

        <LightBoxBody>
          <div className='arrow-left' onClick={prev}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className='arrow-right' onClick={next}>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>

          <div className='image-container'>{image && <img src={image} alt='' />}</div>
        </LightBoxBody>
      </LightBoxContent>
    </LightboxContainer>
  );
};

export default ScreenshotsLightbox;
