/* eslint-disable max-len */
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faDownload, faFolder, faPlay, faPlus } from '@fortawesome/free-solid-svg-icons';
import { StatusCodes } from 'http-status-codes';
import fileDownload from 'js-file-download';
import moment from 'moment-with-locales-es6';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../components/Badge/Badge';
import InnerBadge from '../components/Badge/InnerBadge';
import Checkbox from '../components/Button/Checkbox';
import Download from '../components/Button/Download';
import HollowButton from '../components/Button/HollowButton';
import MainButton from '../components/Button/MainButton';
import PureMainButton from '../components/Button/PureMainButton';
import SearchSelect, { SelectOption } from '../components/Editable/SearchSelect';
import HeaderWithLink from '../components/Editable/Text/Display/HeaderWithLink';
import EditableText from '../components/Editable/Text/EditableText';
import HeaderEditor from '../components/Editable/Text/HeaderEditor';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import MediaStoreModal from '../components/Layout/MediaStoreModal';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import Title from '../components/Layout/Title';
import ActionContainer from '../components/Spacing/ActionContainer';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import { Muted, Span } from '../components/Typography';
import { styled, theme } from '../config/Theme';
import CampaignStatus, { CampaignStatusColors, CampaignStatusNames } from '../enums/CampaignStatus';
import useAPI from '../hooks/useAPI';
import useModal from '../hooks/useModal';
import Campaign from '../models/Campaign';
import Medium from '../models/Medium';
import UserGroup from '../models/UserGroup';
import Toaster from '../utils/Toaster';
import MediumTable from '../HOC/MediumTable';

import MatcherErrorsModal from '../components/Layout/MatcherErrorsModal';
import { useMatcherModalContext } from '../components/Provider/MatcherErrorModalProvider';
import CrudModal from '../components/Layout/CrudModalContext';
import MediumModal from '../components/Layout/MediumModal';

export type CampaignParams = {
  campaignId: string;
};

const StatusBadge = styled(Badge)`
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

const CampaignPage: FC = () => {
  // Hooks
  const { campaignId } = useParams<CampaignParams>();
  const [, getCampaign] = useAPI(`/campaigns/${campaignId}`, { manual: true });
  const [, putCampaign] = useAPI({ url: `/campaigns/${campaignId}`, method: 'PUT' }, { manual: true });
  const [, postMedium] = useAPI({ url: `/mediums`, method: 'POST' }, { manual: true });
  const [, getExcel] = useAPI({ url: `/export?campaign_id=${campaignId}`, responseType: 'blob' }, { manual: true });
  const [, getGroups] = useAPI<UserGroup[]>('/groups', { manual: true });

  const [, startScreenshots] = useAPI(
    { url: `/screenshots/start?campaign_id=${campaignId}`, method: 'POST' },
    { manual: true }
  );

  const [campaign, setCampaign] = useState<Campaign>();
  const [isMediumModalOpen, setIsMediumModalOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<SelectOption | null>();
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>();
  const [isModalOpen, setModalOpen] = useModal();

  const { isMatcherModalOpen } = useMatcherModalContext();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const campaignResponse = await getCampaign();
    if (!campaignResponse || !campaignResponse.data) return;
    setCampaign(campaignResponse.data);

    const { data: groups } = await getGroups();
    const options = groups?.map((g) => {
      return { label: g.name, value: g.id };
    }) as SelectOption[];

    setGroupOptions(options);

    if (campaignResponse.data.groupId) {
      setSelectedGroup(options.find((o) => o.value === campaignResponse.data.groupId));
    }
  };

  const handleStartScreenshots = () => {
    startScreenshots()
      .then((res) => {
        if (res && res.status === StatusCodes.NO_CONTENT) {
          Toaster.success('Screenshotok elindítva!');
        }
      })
      .catch((_err) => Toaster.error('Sikertelen indítás!'));
  };

  const newMedium = async () => {
    setIsMediumModalOpen(true);
  };

  const updateName = (newName: string) => {
    if (!campaign || !newName.trim()) return;

    campaign.name = newName;
    updateCampaign(campaign);
  };

  const setStatus = (status: CampaignStatus) => {
    if (!status || !campaign || campaign.status === status) return;

    campaign.status = status;
    updateCampaign(campaign);
    setCampaign(campaign);
  };

  const updateCampaign = (campaign: Campaign) => {
    putCampaign({ data: campaign }).then((res) => Object.assign(campaign, res.data));

    setCampaign(campaign);
  };

  const downloadExcel = async () => {
    const response = await getExcel();
    fileDownload(response.data, `${campaign?.name}_prodlista.xlsx`);
  };

  const statuses: Set<CampaignStatus> = useMemo(() => {
    const available = new Set<CampaignStatus>();
    if (!campaign) return available;

    available.add(CampaignStatus.INACTIVE);

    if (campaign.status) available.add(campaign?.status);

    if (!campaign.start || !campaign.end) return available;

    const now = moment();
    if (moment(campaign?.start)?.isAfter(now)) {
      available.add(CampaignStatus.SCHEDULED);
    } else if (moment(campaign?.end)?.isBefore(now)) {
      available.add(CampaignStatus.CLOSED);
    } else {
      available.add(CampaignStatus.IN_PROGRESS);
    }

    return available;
  }, [campaign?.start, campaign?.end]);

  const badges: JSX.Element[] = useMemo(() => {
    const list: JSX.Element[] = [];

    if (statuses) {
      statuses.forEach(function (status) {
        const selected = status === campaign?.status;
        list.push(
          <InnerBadge
            key={status}
            selected={selected}
            bgColor={theme.color.white}
            color={selected ? CampaignStatusColors.get(status as CampaignStatus) : theme.color.black}
            selectedColor={theme.color.gray2}
            onClick={(_e) => setStatus(status)}
          >
            {CampaignStatusNames.get(status)}
          </InnerBadge>
        );
      });
    }

    return list;
  }, [statuses, campaign?.status]);

  const updateNotifications = () => {
    if (!campaign || !campaign.groupId) return;
    campaign.notifications = !campaign.notifications;
    updateCampaign(campaign);
  };

  const handleChangeGroup = async (e: SelectOption | null) => {
    if (!campaign) return;

    const newCampaign = e ? { ...campaign, groupId: e.value } : { ...campaign, groupId: null };

    await putCampaign({ data: newCampaign }).then((res) => {
      setCampaign(res.data);
      setSelectedGroup(e);
    });
  };

  const openMediaStoreModal = (): void => {
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <>
      <CrudModal
        isOpen={isMediumModalOpen}
        toggle={() => {
          setIsMediumModalOpen(false);
        }}
        headerText='Új médium'
      >
        <MediumModal />
      </CrudModal>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <HollowButton icon={faArrowLeft} onClick={(_e) => (location.href = '/overview')}>
              Áttekintés
            </HollowButton>

            <ActionContainer alignment='end'>
              <MainButton icon={faPlay} onClick={() => handleStartScreenshots()}>
                Shotok azonnali indítása
              </MainButton>
              {campaign && campaign.status !== CampaignStatus.CLOSED && (
                <MainButton secondary icon={faPlus} onClick={newMedium}>
                  Médium létrehozása
                </MainButton>
              )}
              <MainButton
                icon={faFolder}
                bgColor={theme.color.gray3}
                hoverColor={theme.color.dark}
                onClick={() => openMediaStoreModal()}
              >
                Kreatív tár
              </MainButton>
            </ActionContainer>
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <EditableText
                text={campaign?.name}
                onEdited={updateName}
                component={HeaderWithLink}
                editor={HeaderEditor}
              />

              <ActionContainer alignment='end'>
                <Checkbox checked={campaign?.notifications} onClick={updateNotifications} disabled={!selectedGroup} />
                <Span
                  color={selectedGroup ? theme.color.black : theme.color.gray3}
                  style={{ whiteSpace: 'nowrap', marginLeft: 0 }}
                >
                  Jelezzen a csoportnak ha nem készült screenshot
                </Span>
                <SearchSelect
                  isClearable={false}
                  options={groupOptions}
                  value={selectedGroup}
                  width='16rem'
                  placeholder={<Span style={{ whiteSpace: 'nowrap' }}>Válasszon csoportot</Span>}
                  isDisabled={!groupOptions || groupOptions.length == 0}
                  onChange={(e) => handleChangeGroup(e)}
                />
              </ActionContainer>
            </ActionContainer>
          </Title>

          <Title>
            <ActionContainer alignment='start'>
              <Muted>Státusz</Muted>
              <StatusBadge>{badges}</StatusBadge>

              {campaign?.start && (
                <>
                  <Muted>Időzítés</Muted>
                  <StatusBadge>
                    {moment(campaign.start).format('YYYY.MM.DD.')} - {moment(campaign.end).format('YYYY.MM.DD.')}
                  </StatusBadge>
                </>
              )}
            </ActionContainer>
            <ActionContainer alignment='end'>
              <Download url={`/screenshots/download?campaign_id=${campaignId}`}>
                <PureMainButton secondary icon={faDownload}>
                  Screenshotok letöltése
                </PureMainButton>
              </Download>
              <PureMainButton icon={faFileAlt} onClick={() => downloadExcel()}>
                Prodlista export
              </PureMainButton>
            </ActionContainer>
          </Title>

          <MediumTable campaignId={campaignId} />
        </ContentWrapper>
      </PageWrapper>

      <MediaStoreModal campaignId={campaignId} isOpen={isModalOpen} toggle={toggleModal} onlyUpload={true} />
      {isMatcherModalOpen && <MatcherErrorsModal campaignId={campaignId} />}

      <Footer>
        <Copyright />
      </Footer>
    </>
  );
};

export default CampaignPage;
