enum CreativeCampaignType {
  AV = 'av',
  TIME = 'time',
}

export const CreativeCampaignTypeNames = new Map<CreativeCampaignType, string>([
  [CreativeCampaignType.AV, 'Mix'],
  [CreativeCampaignType.TIME, 'Fix'],
]);

export default CreativeCampaignType;
