import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-with-locales-es6';
import React, { useState } from 'react';
import { styled, theme } from '../../config/Theme';
import ScreenshotStatus from '../../enums/ScreenshotStatus';
import Screenshot from '../../models/Screenshot';
import IconButton from '../Button/IconButton';
import RemoteImage from '../Layout/RemoteImage';
import Flex from '../Spacing/Flex';
import { Span } from '../Typography';

const ScreenshotPagerStyled = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;

  .navigationContainer {
    display: flex;
    width: 100%;
    padding: 0 ${(props) => props.theme.space.tiny};

    .left {
      margin-right: auto;
    }

    .right {
      margin-left: auto;
    }
  }

  .date {
    position: absolute;
    right: ${(props) => props.theme.space.tiny};
    bottom: ${(props) => props.theme.space.tiny};
    background-color: ${(props) => props.theme.color.white};
    padding: 0 ${(props) => props.theme.space.half};
    border-radius: 5px;
    box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  }
`;

type ScreenshotPagerProps = {
  screenshots: Screenshot[];
};

const ScreenshotPager = (props: ScreenshotPagerProps): JSX.Element => {
  const [screenshotIndex, setScreenshotIndex] = useState<number>(0);

  const screenshot = props.screenshots[screenshotIndex];

  return (
    <ScreenshotPagerStyled>
      {!props.screenshots ||
      props.screenshots.length === 0 ||
      !props.screenshots.some((sc) => sc.status === ScreenshotStatus.SUCCESS) ? (
        <Flex row align='center' justify='center'>
          <Span color={theme.color.gray3}>Nem készült sikeres képernyőkép</Span>
        </Flex>
      ) : (
        <RemoteImage source={`/screenshots/${screenshot?.id}/binary`} mimeType={screenshot?.mimeType}>
          <Overlay>
            <div className='navigationContainer'>
              {screenshotIndex !== 0 && (
                <IconButton
                  className='left'
                  icon={faAngleLeft}
                  color={theme.color.gray3}
                  hoverColor={theme.color.secondary}
                  size='2x'
                  onClick={() => setScreenshotIndex(screenshotIndex - 1)}
                />
              )}

              {screenshotIndex + 1 !== props.screenshots?.length && (
                <IconButton
                  className='right'
                  icon={faAngleRight}
                  color={theme.color.gray3}
                  hoverColor={theme.color.secondary}
                  size='2x'
                  onClick={() => setScreenshotIndex(screenshotIndex + 1)}
                />
              )}
            </div>
            {moment(screenshot?.created).isValid() && (
              <Span className='date' color={theme.color.black}>
                {moment(screenshot?.created).format('YYYY.MM.DD.')}
              </Span>
            )}
          </Overlay>
        </RemoteImage>
      )}
    </ScreenshotPagerStyled>
  );
};

export default ScreenshotPager;
