import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import useAPI from '../../hooks/useAPI';
import Resolution from '../../models/Resolution';
import Toaster from '../../utils/Toaster';
import Flex from '../Spacing/Flex';
import { H2, SpanStyle } from '../Typography';
import ActionModalContent from './ActionModalContent';
import Modal, { ModalProps } from './Modal';

const ResolutionInput = styled.input`
  ${SpanStyle}
  height: ${(props) => props.theme.space.small};
  width: ${(props) => props.theme.space.medium};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.color.gray3};
  outline: none;
  margin-left: ${(props) => props.theme.space.half};
  margin-right: ${(props) => props.theme.space.half};
  text-align: center;

  :focus {
    border: 1px solid ${(props) => props.theme.color.primary};
  }
`;

type ResolutionModalProps = {
  resolutions: Resolution[];
  addResolution: (resolution: Resolution) => void;
} & ModalProps;

const ResolutionModal = (props: ResolutionModalProps): JSX.Element => {
  const [resolution, setResolution] = useState<Resolution>({ width: 100, height: 100 });

  const [, postResolution] = useAPI({ url: '/resolutions', method: 'POST' }, { manual: true });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, aspect: 'width' | 'height', current: Resolution) => {
      let input = parseInt(event.target.value.replace(/\D/, '').slice(0, 4));

      if (isNaN(input)) input = 0;

      setResolution({
        width: aspect === 'width' ? input : current.width,
        height: aspect === 'height' ? input : current.height,
      });
    },
    []
  );

  const createResolution = async () => {
    if (props.resolutions.some((r) => r.width === resolution.width && r.height === resolution.height)) {
      Toaster.warn('Ez a felbontás már létezik!');
      return;
    }

    const { data: newResolution } = await postResolution({ data: resolution });
    props.addResolution(newResolution);
    props.toggle();
    setResolution({ width: 100, height: 100 });
  };

  return (
    <Modal {...props}>
      <ActionModalContent onPositive={createResolution} onNegative={props.toggle} positiveText='Mentés'>
        <Flex column align='center' justify='center'>
          <H2 style={{ marginBottom: theme.space.medium }}>Új felbontás</H2>
          <Flex row justify='center' align='center'>
            <ResolutionInput value={resolution.width} onChange={(e) => handleChange(e, 'width', resolution)} />
            X
            <ResolutionInput value={resolution.height} onChange={(e) => handleChange(e, 'height', resolution)} />
          </Flex>
        </Flex>
      </ActionModalContent>
    </Modal>
  );
};

export default ResolutionModal;
