/* eslint-disable max-len */
import { styled } from '../../config/Theme';
import Size from '../../utils/Size';

export const Grid = styled.div<Size>`
  display: grid;
  width: 100%;
  grid-template: repeat(auto-fill, ${(props) => props.height}) / repeat(
      auto-fill,
      minmax(${(props) => props.width}, 1fr)
    );
  grid-auto-rows: ${(props) => props.height};
  padding: ${(props) => props.theme.space.small} 0;
`;
