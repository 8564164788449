import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import hu from 'date-fns/locale/hu';
import moment from 'moment';
import React, { ReactPortal, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createPortal } from 'react-dom';
import { styled, theme } from '../../config/Theme';
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import Platform, { PlatformNames } from '../../enums/Platform';
import useAPI from '../../hooks/useAPI';
import useModal from '../../hooks/useModal';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import Daterange from '../../models/Daterange';
import Toaster from '../../utils/Toaster';
import Badge from '../Badge/Badge';
import DateBadge from '../Badge/DateBadge';
import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import DatePicker from '../Cards/DatePicker';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import CreativeTypeDropdown from '../Editable/CreativeTypeDropdown';
import ResolutionDropdown from '../Editable/ResolutionDropdown';
import Flex from '../Spacing/Flex';
import { Muted, Section, Span, SpanStyle } from '../Typography';
import CreativeVariationItem from './CreativeVariationItem';
import CreativeModalDateBadge from '../Badge/CreativeModalDateBadge';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { GroupedRadio } from '../Form/GroupedRadio';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMsg } from '../Form/Form';
import CreativeType from '../../enums/CreativeType';
import MediaStoreModalForCreative from './MediaStoreModalForCreative';
import Medium from '../../models/Medium';
import DropdownButton2 from '../Button/DropdownButton2';

const schema = z.object({
  platform: z.nativeEnum(Platform, { required_error: 'Kötelező kiválasztani' }),
  start: z.date().nullish(),
  end: z.date().nullish(),
  width: z.number().nullish(),
  height: z.number().nullish(),
  type: z.nativeEnum(CreativeType).nullish(),
});

export type CreativeFormValues = z.infer<typeof schema>;

const RadioLabel = styled.label`
  display: flex;
  font-size: ${(props) => props.theme.fontsize.form};
  flex-direction: column;
  gap: 10px;
`;

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  max-height: 90%;
  min-height: 30%;
  position: fixed;
  z-index: 1060;
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
`;

const ModalBody = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.tiny} ${(props) => props.theme.space.small};
  width: calc(100% - 2 * ${(props) => props.theme.space.small});
  overflow: hidden;
  height: calc(100% - ${(props) => props.theme.space.large});
`;

export const Widget = styled(Flex)`
  flex-direction: column;
  width: max-content;
  margin-right: ${(props) => props.theme.space.small};
`;

export const Label = styled(Muted)`
  margin-bottom: ${(props) => props.theme.space.half};
`;

export const ParamsLabel = styled(Muted)`
  margin-bottom: ${(props) => props.theme.space.half};
  margin-top: ${(props) => props.theme.space.tiny};
`;

export const EditDayBadge = styled(Badge)`
  background-color: ${(props) => props.theme.color.white};
  width: max-content;
  margin-right: ${(props) => props.theme.space.half};
  padding: ${(props) => props.theme.space.half} ${(props) => props.theme.space.quarter};

  :last-child {
    margin-right: 0;
  }

  ${Span}.duration {
    margin-left: ${(props) => props.theme.space.quarter};
    color: ${(props) => props.theme.color.gray3};
  }
`;

export const DayInput = styled.input`
  ${SpanStyle}
  width: ${(props) => props.theme.space.tiny};
  border: 0px solid;
  padding: 0;
  background-color: transparent;
  height: min-content;
  outline: 0px;
`;

// export type CreativeModalProps = {
//   updateCreative: (creative: Creative) => void;
//   creative?: Creative;
//   isOpen: boolean;
//   toggle: () => void;
// } & React.HTMLAttributes<HTMLDivElement>;

export type CreativeDate = { months: number; weeks: number; days: number };

export type CreativeModalProps = {
  updateCreative: (creative: Creative) => void;
  creative?: Creative;
  campaignId?: string;
  medium?: Medium;
  isOpen: boolean;
  toggle: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const CreativeModal = (props: CreativeModalProps): ReactPortal | null => {
  const [, postCreative] = useAPI<Creative>({ url: `/creatives`, method: 'POST' }, { manual: true });
  const [totalDate, setTotalDate] = useState<CreativeDate | undefined>();

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isCreateCreativePickerOpen, toggleCreateCreativePicker] = useModal();
  const [isPickerOpen, togglePicker] = useModal();
  const [isMediaModalOpen, setIsMediaModalOpen] = useModal();

  const [variationInPicker, setVariationInPicker] = useState<CreativeVariation>();

  const [platform, setPlatform] = useState<Platform | undefined>(props.creative?.platform);
  const [dates, setDates] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: props.creative?.start, end: props.creative?.end });
  const [dateRangesForFirstCreative, setDateRangesForFirstCreative] = useState<Daterange[]>([]);
  const [postVariationResponse, postVariation] = useAPI<CreativeVariation>(
    {
      // url: `/creatives/${props.creative?.id}/variations`,
      method: 'POST',
    },
    { manual: true }
  );

  const [, putVariation] = useAPI<CreativeVariation>({ method: 'PUT' }, { manual: true });

  const [putCreativeResponse, putCreative] = useAPI<Creative>({ method: 'PUT' }, { manual: true });
  const form = useForm<CreativeFormValues>({
    defaultValues: {
      platform: props.creative?.platform,
      start: props.creative?.start,
      end: props.creative?.end,
    },
    // shouldUnregister: false,
    resolver: zodResolver(schema),
  });

  const {
    formState: { errors, isValid },
  } = form;

  const createVariation = async () => {
    const variation = new CreativeVariation();
    // Setting the default end and start date if there are already variations added to the creative
    if (props.creative?.variations && props.creative?.variations?.length > 0) {
      for (let i = props.creative.variations.length - 1; i >= 0; i--) {
        const { end, start } = props.creative.variations[i];
        if (end && start) {
          variation.end = end;
          variation.start = start;
          break;
        }
      }
    } else {
      variation.end = props.creative?.end;
      variation.start = props.creative?.start;
      if (dateRangesForFirstCreative.length > 0) {
        variation.dateRanges = dateRangesForFirstCreative;
      } else {
        variation.dateRanges = [new Daterange(undefined, moment(props.creative?.start), moment(props.creative?.end))];
      }
    }

    const result = await postVariation({ url: `/creatives/${props.creative?.id}/variations`, data: variation });

    if (postVariationResponse.error || !result.data) {
      console.error(postVariationResponse.error);
      Toaster.error('Sikertelen létrehozás!');
      return;
    }

    const result2 = await putCreative({
      url: `/creatives/${props.creative?.id}`,
      data: {
        ...props.creative,
        variations: [...(props.creative?.variations ?? []), result.data],
      },
    });

    props.updateCreative(result2.data);
  };

  const updateCreative = async (creative: Creative) => {
    if (!props.creative?.id) {
      form.setValue('width', creative.width);
      form.setValue('height', creative.height);
      form.setValue('type', creative.type);
      props.updateCreative(creative);
      return;
    }
    if (isLoading) return;
    setLoading(true);

    const result = await putCreative({ url: `/creatives/${creative.id}`, data: creative });

    if (putCreativeResponse.error || !result.data) {
      console.error(putCreativeResponse.error);
      Toaster.error('Sikertelen módosítás!');
      return;
    }
    setLoading(false);

    props.updateCreative(result.data);
  };

  // const openPickerForCreate = (variation: CreativeVariation) => {
  //   setVariationInPicker({ ...variation });
  //   toggleCreateCreativePicker(true);
  // };

  const openPicker = (variation: CreativeVariation) => {
    setVariationInPicker({ ...variation });
    togglePicker(true);
  };

  const onPickerFinish = (ranges: Daterange[], canceled?: boolean) => {
    togglePicker(false);

    if (canceled) {
      setVariationInPicker(undefined);
      return;
    }

    const variation = variationInPicker;
    if (!variation) return;

    variation.dateRanges = ranges.map((r) => new Daterange(undefined, r.start, r.end));

    updateVariation(variation);
  };

  const calculateCreativeTimespan = (
    start: Date | undefined,
    end: Date | undefined,
    daysProposed: number | undefined
  ): void => {
    if (!start || !end) {
      setTotalDate({
        months: 0,
        weeks: 0,
        days: 0,
      });

      return;
    }

    let months: number, weeks: number, days: number;
    const difference = new Date(end).getTime() - new Date(start).getTime();
    const totalDaysProposed = daysProposed ? daysProposed : Math.ceil(difference / (1000 * 3600 * 24));

    months = Math.floor(totalDaysProposed / 30);
    weeks = Math.floor((totalDaysProposed - months * 30) / 7);
    days = totalDaysProposed - months * 30 - weeks * 7;

    setTotalDate({
      months,
      weeks,
      days,
    });
  };

  const updateVariation = async (variation: CreativeVariation) => {
    delete variation.creative;
    const res = await putVariation({
      url: `/creatives/${props.creative?.id}/variations/${variation.id}`,
      data: variation,
    });

    const newVariations = [...(props.creative?.variations?.filter((v) => v.id !== res.data.id) ?? []), res.data];
    const start = newVariations.map((v) => v.start).sort()[0];
    const end = newVariations
      .map((v) => v.end)
      .sort()
      .reverse()[0];

    let totalDays = props.creative?.daysProposed;

    const newCreative = {
      ...props.creative,
      variations: newVariations,
      start,
      end,
      totalDays,
    };

    updateCreative(newCreative);
  };

  const handlePlatformSelect = (value: Platform) => {
    if (props.creative?.id) {
      // setPlatform(value);
      updateCreative({ ...props.creative, platform: value });
    }
  };

  const toggleMediaModal = () => {
    setIsMediaModalOpen(!isMediaModalOpen);
  };

  useEffect(() => {
    // We update the Timespan(Időtartam) when the creative changes
    calculateCreativeTimespan(props.creative?.start, props.creative?.end, props.creative?.daysProposed);
  }, [props.creative]);
  registerLocale('hu', hu);

  return props.isOpen
    ? createPortal(
        <>
          <ModalWrapper>
            <ModalOverlay onClick={props.toggle} />
            <ModalStyled className='modal'>
              <ModalHeader>
                <Span color={theme.color.gray3}>
                  {props.creative?.variations &&
                  props.creative?.variations.length != 0 &&
                  props.creative?.variations.some((v) => v.originalFilename)
                    ? props.creative?.variations
                        ?.map((v) => v.originalFilename)
                        .filter((v) => v?.trim().length)
                        .join(', ')
                    : 'Nincs feltöltött fájl'}
                </Span>
                <IconButton
                  size='lg'
                  icon={faTimesCircle}
                  color={theme.color.black}
                  hoverColor={theme.color.gray3}
                  onClick={props.toggle}
                />
              </ModalHeader>

              <ModalBody>
                <form
                  onSubmit={form.handleSubmit(async (formData) => {
                    const { data } = await postCreative({
                      data: { ...props.creative, platform: formData.platform, start: dates.start, end: dates.end },
                    });
                    updateCreative(data);
                  })}
                >
                  <Flex row justify='space-between' style={{ marginBottom: theme.space.tiny }}>
                    <Flex row justify='flex-start'>
                      <Widget>
                        <Label>Méret</Label>
                        <ResolutionDropdown wide creative={props.creative} updateCreative={updateCreative} />
                      </Widget>

                      <Widget>
                        <Label>Típus</Label>
                        <CreativeTypeDropdown wide creative={props.creative} updateCreative={updateCreative} />
                      </Widget>
                    </Flex>

                    <Flex row justify='flex-end'>
                      <Widget>
                        <Label>Kampány</Label>
                        <Badge bgColor={theme.color.gray2}>
                          <Span>
                            {CreativeCampaignTypeNames.get(props.creative?.campaign ?? CreativeCampaignType.TIME)}
                          </Span>
                        </Badge>
                      </Widget>

                      <Widget>
                        <Label>Dátum</Label>
                        <DateBadge
                          start={props.creative?.start || dates.start}
                          end={props.creative?.end || dates.end}
                          wide
                          onClick={() => {
                            if (!props.creative?.id && !props.creative?.start && !props.creative?.end) {
                              toggleCreateCreativePicker(true);
                            }
                          }}
                        />
                        {isCreateCreativePickerOpen && (
                          <>
                            <DatePicker
                              onFinish={(ranges) => {
                                const _ranges = ranges.map((r) => new Daterange(undefined, r.start, r.end));

                                const start =
                                  _ranges.length === 0
                                    ? null
                                    : _ranges.sort((a, b) => {
                                        // @ts-ignore
                                        if (a.start > b.start) return 1;
                                        // @ts-ignore
                                        if (b.start > a.start) return -1;
                                        return 0;
                                      })[0].start;

                                const end =
                                  _ranges.length === 0
                                    ? null
                                    : _ranges.sort((a, b) => {
                                        // @ts-ignore
                                        if (a.end > b.end) return -1;
                                        // @ts-ignore
                                        if (b.end > a.end) return 1;
                                        return 0;
                                      })[0].end;

                                setDates({
                                  // @ts-ignore
                                  start: start as Date,
                                  // @ts-ignore
                                  end: end as Date,
                                });
                                setDateRangesForFirstCreative(_ranges);
                                toggleCreateCreativePicker(false);
                              }}
                              ranges={dateRangesForFirstCreative ?? []}
                            />
                          </>
                        )}
                      </Widget>

                      <Flex column justify='flex-start'>
                        <Label>Időtartam</Label>
                        <CreativeModalDateBadge
                          months={totalDate?.months ?? 0}
                          weeks={totalDate?.weeks ?? 0}
                          days={totalDate?.days ?? 0}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex row justify='space-between' align='center' width='100%'>
                    <FormControl style={{ marginBottom: '1rem' }}>
                      <Label>Platform</Label>
                      <GroupedRadio>
                        <Controller
                          name='platform'
                          control={form.control}
                          render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                            <RadioGroup
                              row
                              onChange={(e) => {
                                onChange(e);
                                handlePlatformSelect(e.target.value as Platform);
                              }}
                              value={value ?? ' '}
                            >
                              {Object.values(Platform).map((value, index) => {
                                return (
                                  <FormControlLabel
                                    key={index}
                                    value={value}
                                    sx={{
                                      '& .MuiTypography-root': {
                                        fontSize: 14,
                                        lineHeight: '14px',
                                      },
                                    }}
                                    control={
                                      <Radio
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 16,
                                          },
                                        }}
                                      />
                                    }
                                    label={PlatformNames.get(value)}
                                  />
                                );
                              })}
                            </RadioGroup>
                          )}
                        />
                      </GroupedRadio>
                      {errors.platform && <ErrorMsg>{errors.platform.message}</ErrorMsg>}
                    </FormControl>
                    {props.creative?.id ? (
                      // <MainButton type='button' disabled={!isValid} icon={faPlus} onClick={() => createVariation()}>
                      //   Kreatív variáció
                      // </MainButton>
                      <DropdownButton2 createVariation={createVariation} toggleMediaModal={toggleMediaModal} />
                    ) : (
                      <MainButton variation='create' disabled={!isValid || !dates.start || !dates.end}>
                        Létrehozás
                      </MainButton>
                    )}
                  </Flex>
                  <Flex row justify='space-between' width='100%' style={{ marginBottom: theme.space.tiny }}>
                    <Section>Kreatívok</Section>
                  </Flex>
                  <Flex
                    column
                    style={{
                      overflowY: 'auto',
                      border: `${
                        props.creative?.variations && props.creative?.variations?.length > 0
                          ? '1px solid black'
                          : 'none'
                      }`,
                      borderRadius: '5px',
                      marginBottom: theme.space.small,
                    }}
                  >
                    <Flex column>
                      {props.creative?.variations
                        ?.sort((a, b) => {
                          const d1 = moment(a.created);
                          const d2 = moment(b.created);
                          return d1.isValid() && d2.isValid() ? d1.diff(d2) : 0;
                        })
                        .map((variation, i) => (
                          <CreativeVariationItem
                            key={i}
                            creative={props.creative}
                            openPicker={() => openPicker(variation)}
                            updateVariation={updateVariation}
                            variation={variation}
                            updateCreative={props.updateCreative}
                          />
                        ))}
                    </Flex>
                  </Flex>
                  {isPickerOpen && (
                    <DatePicker onFinish={onPickerFinish} ranges={variationInPicker?.dateRanges ?? []} />
                  )}
                </form>
              </ModalBody>
            </ModalStyled>
          </ModalWrapper>
          <MediaStoreModalForCreative
            campaignId={props.campaignId}
            isOpen={isMediaModalOpen}
            toggle={toggleMediaModal}
            onFinish={() => {}}
            medium={props.medium}
            creative={props.creative}
            updateCreative={updateCreative}
          />
        </>,
        document.body
      )
    : null;
};

export default CreativeModal;
