import React, { FC } from 'react';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import IconButton from '../Button/IconButton';

const styling = {
  unseenErrors: {
    borderWidth: '2px',
    borderColor: theme.color.danger,
    color: theme.color.danger,
    fontWeight: 500,
    hoverColor: theme.color.danger,
  },
  errorsSeen: {
    borderWidth: '1px',
    borderColor: theme.color.dark,
    color: theme.color.dark,
    fontWeight: 500,
    hoverColor: theme.color.dark,
  },
  noErrors: {
    borderWidth: '0',
    borderColor: 'white',
    color: theme.color.dark,
    fontWeight: 400,
    hoverColor: theme.color.dark,
  },
};

type CampaignErrorState = 'unseenErrors' | 'errorsSeen' | 'noErrors';

type CampaignProgressProps = {
  errorState: CampaignErrorState;
  totalDays: number;
  daysPassed: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

type WrapperProps = {
  errorState: CampaignErrorState;
};

const Tooltip = styled.p`
  text-align: left;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: -16px;
  color: white;
  background-color: ${theme.color.dark};
  padding: 16px;
  border-radius: 5px;
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 25px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: ${theme.color.dark} transparent transparent transparent;
  }
`;

const Wrapper = styled.button<WrapperProps>`
  border-width: ${({ errorState }) => styling[errorState].borderWidth};
  border-color: ${({ errorState }) => styling[errorState].borderColor};
  color: ${({ errorState }) => styling[errorState].color};
  font-weight: ${({ errorState }) => styling[errorState].fontWeight};
  background-color: transparent;
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  cursor: pointer;
  position: relative;
  :hover ${Tooltip} {
    visibility: visible;
  }
`;

const CampaignProgress: FC<CampaignProgressProps> = ({ onClick, errorState, daysPassed, totalDays }): JSX.Element => {
  let daysProgressValidation = daysPassed >= 0 && totalDays >= 0;

  return (
    <Wrapper onClick={onClick} errorState={errorState}>
      {errorState !== 'noErrors' && (
        <IconButton
          icon={errorState === 'unseenErrors' ? faExclamationTriangle : faInfoCircle}
          color={styling[errorState].color}
          style={{ marginRight: daysProgressValidation ? theme.space.half : 'initial' }}
          size={'sm'}
          hoverColor={styling[errorState].hoverColor}
        />
      )}
      {daysProgressValidation && daysPassed + ' / ' + totalDays}
      {/* <Tooltip>
                URL-ek: 2<br />
                Kreatív méretek: 3<br />
                Kreatív variációk<br />
                Kampány napok<br />
                <br />
                Nevező<br />
                2x3x9x7=378
            </Tooltip> */}
    </Wrapper>
  );
};

export default CampaignProgress;
