import React, { useContext, useEffect, useState } from 'react';
import { CrudModalContext } from './CrudModalContext';
import MainButton from '../Button/MainButton';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormDropdown from '../Form/FormDropdown';
import FormInput from '../Form/FormInput';
import { SimpleDropdownOption } from '../Editable/SimpleDropdown';
import useAPI from '../../hooks/useAPI';
import UserGroup from '../../models/UserGroup';
import { FormButton, FormItem } from '../Form/Form';

const schema = z.object({
  name: z.string({ required_error: 'Ez a mező nem lehet üres' }).min(1, 'Ez a mező nem lehet üres'),
  groupId: z.string({ required_error: 'Ez a mező nem lehet üres' }).min(1, 'Ez a mező nem lehet üres'),
});

type FormValues = z.infer<typeof schema>;

export type ModalProps = {} & React.HTMLAttributes<HTMLDivElement>;

const CampaignModal = (props: ModalProps) => {
  const [, getGroups] = useAPI<UserGroup[]>('/groups', { manual: true });
  const [, createCampaign] = useAPI({ url: '/campaigns', method: 'POST' }, { manual: true });
  const formRef = React.useRef<HTMLFormElement>(null);
  const crudModalContext = useContext(CrudModalContext);
  const [userGroups, setUserGroups] = useState<SimpleDropdownOption[]>([]);

  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      groupId: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    let isCancelled = false;
    getGroups().then((data) => {
      if (!isCancelled) {
        const options = data.data?.map((g) => {
          return { label: g.name, id: g.id } as SimpleDropdownOption;
        });
        setUserGroups(options || []);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, []);

  const {
    formState: { errors, isValid },
  } = form;
  const onSubmit = async (data: FormValues) => {
    const response = await createCampaign({ data });
    crudModalContext.toggleState();
    location.href = `/campaigns/${response.data.id}`;
  };

  return (
    <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
      <FormItem>
        <FormInput
          autoComplete='off'
          errorMessage={errors.name?.message}
          label='Kampány neve'
          {...form.register('name')}
        ></FormInput>
      </FormItem>
      <div>
        <FormDropdown
          label='Jelez a választott csoportnak, ha nem készült screenshot'
          options={userGroups}
          placeholder='Válassz értesítési csoportot'
          onChange={(e) => {
            form.setValue('groupId', e.id);
            form.trigger('groupId');
          }}
          errorMessage={errors.groupId?.message}
        ></FormDropdown>
      </div>
      <FormButton>
        <MainButton variation='create' disabled={!isValid} onClick={(e) => formRef.current?.submit}>
          Létrehozás
        </MainButton>
      </FormButton>
    </form>
  );
};

export default CampaignModal;
