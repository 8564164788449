import React from 'react';
import { styled, theme } from '../../config/Theme';

const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 12px 10px;
  border-radius: 5px;
  border: 1px solid #d3d6e8;
  box-sizing: border-box;
  color: ${(props) => props.theme.color.black};
  font-weight: 500;
  font-family: 'Rubik';
  font-size: ${(props) => props.theme.fontsize.form};
  :focus-visible {
    outline: none;
  }
`;

const Label = styled.label`
  display: flex;
  font-size: ${(props) => props.theme.fontsize.form};
  flex-direction: column;
  gap: 10px;
`;

const ErrorMsg = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.warningRed};
  margin-top: 5px;
`;

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label: string;
  errorMessage?: string;
};

const FormInput = React.forwardRef<HTMLInputElement, Props>(({ label, errorMessage, ...props }: Props, ref) => {
  return (
    <Label>
      <span>{label}</span>
      <div>
        <Input {...props} ref={ref} style={errorMessage ? { borderColor: theme.color.warningRed } : {}} />
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
      </div>
    </Label>
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
