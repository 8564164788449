import { faArrowLeft, faEnvelope, faPlusCircle, faTimesCircle, faUsersSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeyboardEventHandler } from 'react-select';
import { Key } from 'ts-key-enum';
import UserItem from '../components/Badge/UserItem';
import HollowButton from '../components/Button/HollowButton';
import IconButton from '../components/Button/IconButton';
import MainButton from '../components/Button/MainButton';
import SpacedCard from '../components/Cards/SpacedCard';
import SearchSelect, { SelectOption } from '../components/Editable/SearchSelect';
import HeaderWithLink from '../components/Editable/Text/Display/HeaderWithLink';
import EditableText from '../components/Editable/Text/EditableText';
import Copyright from '../components/Layout/Copyright';
import Footer from '../components/Layout/Footer';
import HalfColumn from '../components/Layout/HalfColumn';
import Header from '../components/Layout/Header';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import Title from '../components/Layout/Title';
import ActionContainer from '../components/Spacing/ActionContainer';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import Flex from '../components/Spacing/Flex';
import { Ellipsized, H2 } from '../components/Typography';
import { styled, theme } from '../config/Theme';
import useAPI from '../hooks/useAPI';
import User from '../models/User';
import UserGroup from '../models/UserGroup';
import Toaster from '../utils/Toaster';

const UserGroupEditPage: FunctionComponent = () => {
  const { groupId } = useParams<{ groupId: string }>();

  const [group, setGroup] = useState<UserGroup>();

  const [usersInGroup, setUsersInGroup] = useState<User[]>();

  const [userOptions, setUserOptions] = useState<SelectOption[]>();

  const [allUsers, setAllUsers] = useState<User[]>();

  const [addUser, setAddUser] = useState<SelectOption | null>();

  const [getGroupResponse, getGroup] = useAPI(`/groups/${groupId}`, { manual: true });

  const [putGroupResponse, putGroup] = useAPI({ url: `/groups/${groupId}`, method: 'PUT' }, { manual: true });

  const [usersInGroupResponse, getUsersInGroup] = useAPI({ url: `/users?group_id=${groupId}` }, { manual: true });

  const [getUsersReponse, getUsers] = useAPI({ url: `/users` }, { manual: true });

  const [deleteResponse, deleteGroup] = useAPI({ url: `/groups/${groupId}`, method: 'DELETE' }, { manual: true });

  const handleDelete = async (e: any) => {
    await deleteGroup();
    location.href = '/groups';
  };

  const handleAddUser = async () => {
    if (!group || !allUsers || !addUser || !usersInGroup) return;

    const selected = allUsers.find((u) => u.id === addUser.value);
    let newGroup: UserGroup = {};

    if (group.users?.find((u) => u.email === addUser.value) || group.addresses?.find((a) => a === addUser.value)) {
      Toaster.warn('Duplikált email cím!');
      return;
    }

    if (!selected) {
      newGroup = { ...group, addresses: [...(group.addresses || []), addUser.label] };
    } else {
      newGroup = { ...group, users: [...usersInGroup, selected] };
    }

    await putGroup({ data: newGroup }).then((res) => {
      setGroup(newGroup);
      setUsersInGroup(newGroup.users);
      setAddUser(null);
    });
  };

  const handleKeyDown: KeyboardEventHandler = async (e: KeyboardEvent) => {
    if (e.key === Key.Enter) handleAddUser();
  };

  const deleteUserFromGroup = (userId?: string) => {
    if (!userId || !usersInGroup) return;
    const newUsers = usersInGroup.filter((u) => u.id !== userId);
    const newGroup = { ...group, users: newUsers };
    putGroup({ data: newGroup });
    setGroup(newGroup);
    setUsersInGroup(newUsers);
  };

  const deleteAddress = (address?: string) => {
    if (!address || !group?.addresses) return;
    const newGroup = { ...group, addresses: group.addresses.filter((a) => a !== address) };
    putGroup({ data: newGroup });
    setGroup(newGroup);
  };

  const updateName = (newName: string) => {
    if (!group || !newName.trim()) return;

    group.name = newName;

    putGroup({ data: group });

    setGroup({ ...group, name: newName });
  };

  useEffect(() => {
    getGroup()
      .then((res) => {
        if (res && res.data) setGroup(res.data);

        getUsersInGroup()
          .then((res) => res && res.data && setUsersInGroup(res.data))
          .catch((err) => console.error(err));

        getUsers()
          .then((res) => {
            if (!res || !res.data) return;
            setAllUsers(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, []);

  useMemo(() => {
    const userIds = usersInGroup?.map((u) => u.id);
    const options = allUsers
      ?.filter((u) => !userIds?.includes(u.id) && u.id && u.email)
      ?.map((u) => {
        return { value: u.id, label: u.email };
      }) as SelectOption[];

    setUserOptions(options);
  }, [allUsers, usersInGroup]);

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header />

        <ContentWrapper>
          <NavHeader>
            <HollowButton icon={faArrowLeft} onClick={(e) => (location.href = '/groups')}>
              Vissza
            </HollowButton>
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <EditableText text={group?.name} onEdited={updateName} component={HeaderWithLink} />
            </ActionContainer>
            <ActionContainer alignment='end'>
              <MainButton icon={faUsersSlash} onClick={handleDelete}>
                Csoport törlése
              </MainButton>
            </ActionContainer>
          </Title>

          <MemberWrapper>
            <HalfColumn>
              <SpacedCard>
                <H2>Új tag csoporthoz adása</H2>
                <SearchSelect
                  isCreatable
                  options={userOptions}
                  value={addUser}
                  placeholder='Email cím'
                  onChange={setAddUser}
                  onKeyDown={handleKeyDown}
                />
                <HalfColumn>
                  <MainButton icon={faPlusCircle} onClick={(e) => handleAddUser()}>
                    Felhasználó hozzáadása
                  </MainButton>
                </HalfColumn>
              </SpacedCard>
            </HalfColumn>

            <HalfColumn>
              <H2>Csoporttagok</H2>
              {usersInGroup?.map((user) => (
                <UserItem key={user.id}>
                  <Ellipsized title={user.email}>{user.email}</Ellipsized>
                  <IconButton
                    icon={faTimesCircle}
                    size='1x'
                    color={theme.color.primary}
                    hoverColor={theme.color.secondary}
                    onClick={(e: any) => deleteUserFromGroup(user.id)}
                  />
                </UserItem>
              ))}
              {group?.addresses?.map((address) => (
                <UserItem key={address}>
                  <Flex row align='center'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      color={theme.color.secondary}
                      style={{ marginRight: theme.space.tiny }}
                    />
                    <Ellipsized title={address}>{address}</Ellipsized>
                  </Flex>
                  <IconButton
                    icon={faTimesCircle}
                    size='1x'
                    color={theme.color.primary}
                    hoverColor={theme.color.secondary}
                    onClick={(e: any) => deleteAddress(address)}
                  />
                </UserItem>
              ))}
            </HalfColumn>
          </MemberWrapper>
        </ContentWrapper>
      </PageWrapper>

      <Footer>
        <Copyright />
      </Footer>
    </>
  );
};

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
export default UserGroupEditPage;
