import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import React, { FunctionComponent, useState } from 'react';
import LoginButton from '../components/Button/LoginButton';
import Card from '../components/Cards/CenterCard';
import CardPageWrapper from '../components/Login/CardPageWrapper';
import { FormField, FormInput, FormLabel } from '../components/Login/Form';
import { H1 } from '../components/Typography';
import { styled } from '../config/Theme';
import { ApiURL, PasswordTokenRequest } from '../hooks/useAPI';
import TokenResponse from '../models/TokenResponse';
import Toaster from '../utils/Toaster';
import TokenHelper from '../utils/TokenHelper';

const LoginCard = styled(Card)`
  padding: ${(props) => props.theme.space.large} ${(props) => props.theme.space.small};
`;

const LoginPage: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const doLogin = (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !password) {
      Toaster.error('Adja meg belépési adatait!');
      return;
    }

    const payload: PasswordTokenRequest = {
      grantType: 'password',
      username: email,
      password: password,
    };

    axios
      .post<TokenResponse>(`${ApiURL}/oauth/token`, payload)
      .then((res) => {
        TokenHelper.setBoth(res.data);
        location.href = '/overview';
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === StatusCodes.UNAUTHORIZED) Toaster.error('Hibás belépési adatok!');
        else Toaster.unknownError();
      });
  };

  return (
    <CardPageWrapper>
      <form onSubmit={doLogin}>
        <LoginCard>
          <H1>Seeker</H1>

          <FormField>
            <FormLabel>Email cím</FormLabel>
            <FormInput
              name='username'
              type='email'
              placeholder='Email cím'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></FormInput>
          </FormField>

          <FormField>
            <FormLabel>Jelszó</FormLabel>
            <FormInput
              name='password'
              type='password'
              placeholder='Jelszó'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></FormInput>
          </FormField>

          <LoginButton>Belépés</LoginButton>
        </LoginCard>
      </form>
    </CardPageWrapper>
  );
};

export default LoginPage;
