import { styled } from '../../config/Theme';

type JustifyValue = 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
type AlignValue = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

export type FlexProps = {
  column?: boolean;
  row?: boolean;
  justify?: JustifyValue;
  align?: AlignValue;
  width?: string;
  height?: string;
  margin?: string;
  gap?: string;
  wrap?: boolean;
  boxSizing?: string;
};

export default styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  margin: ${(props) => props.margin || '0'};
  gap: ${(props) => props.gap || '0'};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  box-sizing: ${(props) => props.boxSizing || 'inherit'};
  position: relative;
`;
