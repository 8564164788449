import React from 'react';
import moment from 'moment';
import { CampaignStatusNames } from '../../enums/CampaignStatus';
import { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import { CreativeTypeNames } from '../../enums/CreativeType';
import { PlatformNames } from '../../enums/Platform';
import Creative from '../../models/Creative';
import UserEvent from '../../models/UserEvent';
import Flex from '../Spacing/Flex';
import ActionLogCell from '../Table/ActionLogCell';
import Column from '../Table/Column';
import { Log, Ellipsized, TextContainerWithEllipsis } from '../Typography';

const getCreativeDescription = (
  creative: Creative,
  type: string,
  campaign: string,
  platform: string,
  status: string
) => {
  return `${creative.width}x${creative.height},${type}, ${campaign}, ${platform}, ${status}`;
};

const getLogColumns = () => {
  return columns;
};

const columns: Column<UserEvent>[] = [
  {
    id: 'created',
    label: 'Időpont',
    sortable: true,
    getter(event: UserEvent) {
      const rawDate = moment(event.created);
      if (!rawDate.isValid()) return '-';
      const date = rawDate.format('YYYY.MM.DD HH:mm');
      return (
        <Flex justify='center' align='center' title={rawDate.format('YYYY.MM.DD HH:mm:ss')}>
          <Log>{date}</Log>
        </Flex>
      );
    },
    width: 10,
  },
  {
    label: 'Kampány',
    id: 'campaign',
    sortable: true,
    getter(event: UserEvent) {
      if (event.campaign) {
        return (
          <TextContainerWithEllipsis>
            <Log title={event.campaign.name}>{event.campaign.name}</Log>
          </TextContainerWithEllipsis>
        );
      } else {
        return <Log>{'-'}</Log>;
      }
    },
    width: 16,
  },
  {
    label: 'Médium',
    id: 'medium',
    sortable: true,
    getter(event: UserEvent) {
      if (event.medium) {
        return (
          <TextContainerWithEllipsis>
            <Log title={event.medium.name}>{event.medium.name}</Log>
          </TextContainerWithEllipsis>
        );
      } else {
        return <Log>{'-'}</Log>;
      }
    },
    width: 16,
  },
  {
    label: 'Kreatív',
    id: 'creative',
    sortable: false,
    getter(event: UserEvent) {
      if (event.creative) {
        const c = event.creative;
        const platform = (c.platform && PlatformNames.get(c.platform)) || 'Ismeretlen platform';
        const campaign = (c.campaign && CreativeCampaignTypeNames.get(c.campaign)) || 'Ismeretlen kampány típus';
        const type = (c.type && CreativeTypeNames.get(c.type)) || 'Ismeretlen kreatív típus';
        const status =
          (event.campaign?.status && CampaignStatusNames.get(event.campaign?.status)) || 'Ismeretlen kampány státusz';
        const creativeDescription = getCreativeDescription(c, type, platform, campaign, status);
        return (
          <Log>
            <Ellipsized title={creativeDescription}>{creativeDescription}</Ellipsized>
          </Log>
        );
      } else {
        return '-';
      }
    },
    width: 20,
  },
  {
    label: 'Felhasználó',
    id: 'user',
    sortable: true,
    getter(event: UserEvent) {
      return (
        <Log title={event?.user?.email}>
          <Ellipsized>{event?.user?.email}</Ellipsized>
        </Log>
      );
    },
    width: 17,
  },
  {
    label: 'Akció',
    id: 'action',
    sortable: false,
    getter(event: UserEvent) {
      return <ActionLogCell event={event} />;
    },
    width: 20,
  },
];

export default getLogColumns;
