enum UserEventAction {
  CAMPAIGN_CREATED = 'Kampány: Létrehozva', // 0
  CAMPAIGN_DELETED = 'Kampány: Törölve', // 1
  CAMPAIGN_RENAMED = 'Kampány: Átnevezve', // 2
  CAMPAIGN_SCREENSHOTS_DOWNLOADED = 'Kampány: Shotok letöltve', // 3
  MEDIUM_CREATED = 'Médium: Létrehozva', // 4
  MEDIUM_DELETED = 'Médium: Törölve', // 5
  MEDIUM_RENAMED = 'Médium: Átnevezve', // 6
  MEDIUM_PDF_UPLOADED = 'Médium: PDF feltöltve', // 7
  URL_ADDED = 'Médium: URL felvéve', // 8
  URL_REMOVED = 'Médium: URL törölve', // 9
  CREATIVE_CREATED = 'Kreatív: Létrehozva', // 10
  CREATIVE_DELETED = 'Kreatív: Törölve', // 11
  RESOLUTION_CHANGED = 'Kreatív: Felbontás frissítve', // 12
  DATE_CHANGED = 'Kreatív variáció: Dátum módosítva', // 13
  BANNER_UPLOADED = 'Kreatív variáció: Banner feltöltve', // 14
  CREATIVE_PARAMS_ADDED = 'Kreatív: Paraméter hozzáadva', // 15
  CREATIVE_PDF_UPLOADED = 'Kreatív: PDF feltöltve', // 16
  CREATIVE_PLATFORM_CHANGED = 'Kreatív: Platform frissítve', // 17
  MEDIUM_SCREENSHOTS_DOWNLOADED = 'Médium: Shotok letöltve', // 18
  GROUP_CREATED = 'Csoport: Létrehozva', // 19
  GROUP_RENAMED = 'Csoport: Átnevezve', // 20
  GROUP_USER_ADDED = 'Csoport: Tag felvéve', // 21
  GROUP_USER_REMOVED = 'Csoport: Tag törölve', // 22,
  GROUP_DELETED = 'Csoport: Törölve', // 23
  SCREENSHOTS_STARTED = 'Screenshotok kézzel indítva', // 24
  CREATIVE_VARIATION_CREATED = 'Kreatív variáció: Létrehozva', // 25
  CREATIVE_VARIATION_DELETED = 'Kreatív variáció: Törölve', // 26
  BANNER_DELETED = 'Kreatív variáció: Banner törölve', // 27
  CREATIVE_TYPE_CHANGED = 'Kreatív: Típus megváltoztatva', // 28
  MEDIUM_STATUS_CHANGED = 'Médium: Státusz megváltoztatva', // 29
  CAMPAIGN_STATUS_CHANGED = 'Kampány: Státusz megváltoztatva', // 30
}

export default UserEventAction;
