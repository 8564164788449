enum CreativeType {
  STATIC = 'static',
  VIDEO = 'video',
  DYNAMIC = 'dynamic',
  INTERSTITIAL_STATIC = 'interstitial_static',
  INTERSTITIAL_DYNAMIC = 'interstitial_dynamic',
  ARTICLE = 'article',
  HANGAR = 'hangar',
}

export const CreativeTypeNames = new Map<CreativeType, string>([
  [CreativeType.STATIC, 'Statikus'],
  [CreativeType.VIDEO, 'Videó'],
  [CreativeType.DYNAMIC, 'Dinamikus'],
  [CreativeType.INTERSTITIAL_STATIC, 'Interstitial statikus'],
  [CreativeType.INTERSTITIAL_DYNAMIC, 'Interstitial dinamikus'],
  [CreativeType.ARTICLE, 'Cikk'],
  [CreativeType.HANGAR, 'Hangár'],
]);
export default CreativeType;
