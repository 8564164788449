import React, { HTMLProps, useEffect, useState } from 'react';
import { styled } from '../../config/Theme';
import { API } from '../../hooks/useAPI';
import { useDownload } from '../../hooks/useDownload';
import Flex from '../Spacing/Flex';
import { Muted } from '../Typography';

export type ImageProps = {
  file?: File;
  source?: string;
  mimeType?: string;
  alt?: string;
  url?: string;
  onGotSize?: (width: number, height: number) => void;
} & HTMLProps<HTMLDivElement>;

const ImageStyled = styled.div<{ src: string; clickable?: boolean }>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  text-align: 'center';
  position: relative;
  ${(props) => props.clickable && 'cursor: pointer;'}
`;

const RemoteImage = (props: ImageProps): JSX.Element => {
  const [image, setImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const downloadImage = useDownload({ url: props.url });

  useEffect(() => {
    if (!props.source) return;
    setIsLoading(true);
    API.get(props.source)
      .then((res) => {
        if (res?.data?.source) setImage(`data:${props.mimeType || 'image/jpeg'};base64,` + res.data.source);
        else if (res?.data) setImage(`data:${props.mimeType || 'image/jpeg'};base64,` + res.data);
        else setImage('');
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [props.mimeType, props.source]);

  // If it's a file read it and set the source
  useEffect(() => {
    if (!props.file) return;

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // setImageSrc(typeof(reader.result) === 'string' ? reader.result : undefined);
        const imgSrc: string = typeof reader.result === 'string' ? reader.result : '';
        setImage(imgSrc);
        setIsLoading(false);

        // Detect image size
        const img = new Image();
        img.onload = function () {
          if (props.onGotSize) {
            props.onGotSize(img.width, img.height);
          }
        };
        img.src = imgSrc;
      },
      false
    );
    reader.readAsDataURL(props.file);
  }, [props.file]);

  if (isLoading) {
    return (
      <Flex row width='100%' height='100%' align='center' justify='center'>
        <Muted>Betöltés...</Muted>
      </Flex>
    );
  } else if (!image && image.length <= 0) {
    return (
      <Flex row width='100%' height='100%' align='center' justify='center'>
        <Muted>{props.alt ?? 'Hiányzó kép'}</Muted>
        {props.children}
      </Flex>
    );
  } else {
    return (
      <ImageStyled src={image} clickable={Boolean(props.url)} onClick={downloadImage}>
        {props.children}
      </ImageStyled>
    );
  }
};

export default RemoteImage;
