enum UrlType {
  MAIN_PAGE = 'main_page',
  SUB_SITES = 'sub_sites',
  ALL = 'all',
}

export const UrlTypeNames = new Map<UrlType, string>([
  [UrlType.ALL, 'A megadott URL és az alatta lévő URL-ek'],
  [UrlType.MAIN_PAGE, 'Csak a megadott URL'],
  [UrlType.SUB_SITES, 'A megadott URL alatti URL-ek'],
]);

export const UrlTypeTooltips = new Map<UrlType, string>([
  [
    UrlType.ALL,
    'Ezzel a beállítással a Seeker a fő url-en és az alá tartozó rovat- és cikkoldalakon egyaránt keresi a megjelenéseket, és hibaüzenetet küld, ha valahol nem találja meg.',
  ],
  [
    UrlType.MAIN_PAGE,
    'Ezzel a beállítással a Seeker a fő url-en keresi a megjelenéseket, és hibaüzenetet küld, ha valahol nem találja meg.',
  ],
  [
    UrlType.SUB_SITES,
    'Ezzel a beállítással a Seeker a megadott fő url-en nem, csak a hozzá tartozó rovat- és cikkoldalakon keresi a megjelenéseket.',
  ],
]);

export default UrlType;
