import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import useAPI from '../../hooks/useAPI';

import type { MatcherErrorScreenshot } from '../Layout/MatcherErrorsModal';
import MixErrorRow from './MixErrorRow';

const ErrorsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
`;

type MixEntityType = {
  [key: string]: MatcherErrorScreenshot[];
};

type MixErrorsContainerProps = {
  campaignId: string;
  mediumId: string | null;
  creativeId: string | null;
  mediumName: string;
  pageIndex: number;
  creativeSelection: (creativeId: string) => void;
  pageIndexChange: (nextPageNumber: number) => void;
  totalPageCount: (totalCount: number) => void;
};

const MixErrorsContainer: FC<MixErrorsContainerProps> = ({
  campaignId,
  mediumId,
  creativeId,
  mediumName,
  pageIndex,
  creativeSelection,
  pageIndexChange,
  totalPageCount,
}): JSX.Element => {
  const [, getCampaignMatcherErrors] = useAPI(`/matchererrors/${campaignId}`, { manual: true });
  const [, updateScreenshots] = useAPI({ url: '/screenshots', method: 'PUT' }, { manual: true });

  const [screenshots, setScreenshots] = useState<MatcherErrorScreenshot[]>([]);
  const [mixEntities, setMixEntities] = useState<MixEntityType>({});

  const handleSetScreenshots = (screenshots: MatcherErrorScreenshot[]) => {
    setScreenshots(() => screenshots.filter((screenshot) => screenshot.creative !== null));
  };

  const fetchData = async (pageIndex: number): Promise<void> => {
    const campaignMatcherErrorsResponse = await getCampaignMatcherErrors({
      params: { offset: pageIndex, errorType: 'av', mediumId, creativeId },
    });
    if (!campaignMatcherErrorsResponse || !campaignMatcherErrorsResponse.data) return;

    const responseScreenshots = campaignMatcherErrorsResponse.data[0];

    handleSetScreenshots(responseScreenshots);
    totalPageCount(campaignMatcherErrorsResponse.data[1]);
  };

  const updateMixScreenshots = async (updatedScreenshots: MatcherErrorScreenshot[]) => {
    const newScreenshotArray = screenshots.map((screenshot) => {
      const updatedScreenshotIndex = updatedScreenshots.findIndex(
        (updatedScreenshot) => updatedScreenshot.id === screenshot.id
      );

      if (updatedScreenshotIndex > -1) {
        return updatedScreenshots[updatedScreenshotIndex];
      }

      return screenshot;
    });

    await updateScreenshots({ data: updatedScreenshots });
    handleSetScreenshots(newScreenshotArray);
  };

  const excludeScreenshot = async (id: string) => {
    const updatedScreenshots = screenshots.map((screenshot) => {
      if (screenshot.id === id) {
        screenshot.exclude = !screenshot.exclude;
      }
      return screenshot;
    });

    await updateMixScreenshots(updatedScreenshots);
  };

  useEffect(() => {
    if (!screenshots) return;
    const newMixEntities: MixEntityType = {};

    for (const entity of screenshots) {
      const matcherError = entity as unknown as MatcherErrorScreenshot;
      const errorByMediumAndDay =
        newMixEntities[`${matcherError.medium.id},${matcherError.medium.name},${matcherError.day}`];
      if (errorByMediumAndDay) {
        errorByMediumAndDay.push(matcherError);
      } else {
        newMixEntities[`${matcherError.medium.id},${matcherError.medium.name},${matcherError.day}`] = [matcherError];
      }
    }

    setMixEntities(newMixEntities);
  }, [screenshots]);

  useEffect(() => {
    fetchData(pageIndex);
    pageIndexChange(pageIndex);
  }, [pageIndex]);

  return (
    <ErrorsContainer>
      {Object.keys(mixEntities).map((row, i) => (
        <MixErrorRow
          updateScreenshots={updateMixScreenshots}
          key={`${mixEntities[row]}-${i}`}
          creativeSelection={creativeSelection}
          excludeScreenshot={excludeScreenshot}
          mediumName={mediumName}
          mediumData={row}
          screenshot={mixEntities[row]}
        />
      ))}
    </ErrorsContainer>
  );
};

export default MixErrorsContainer;
