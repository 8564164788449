// Packages, Config
import React, { FC, useEffect, useMemo, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import fileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faDownload, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../config/Theme';

// Types, Models
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../enums/CreativeCampaignType';
import CreativeType from '../enums/CreativeType';
import Platform from '../enums/Platform';
import Creative from '../models/Creative';
import Medium from '../models/Medium';

// Utils, Providers
import Toaster from '../utils/Toaster';
import { useMatcherModalContext } from '../components/Provider/MatcherErrorModalProvider';

// Hooks
import useAPI from '../hooks/useAPI';
import { useDownload } from '../hooks/useDownload';
import useModal from '../hooks/useModal';
import { useGetFile } from '../hooks/useUpload';

// Components
import Badge from '../components/Badge/Badge';
import InnerBadge from '../components/Badge/InnerBadge';
import DropdownButton from '../components/Button/DropdownButton';
import HollowButton from '../components/Button/HollowButton';
import MainButton from '../components/Button/MainButton';
import PureMainButton from '../components/Button/PureMainButton';
import DeleteModalContent from '../components/Cards/DeleteModalContent';
import Modal from '../components/Cards/Modal';
import ProdlistCard from '../components/Cards/ProdlistCard';
import UrlCard from '../components/Cards/UrlCard';
import HeaderWithLink from '../components/Editable/Text/Display/HeaderWithLink';
import EditableText from '../components/Editable/Text/EditableText';
import BulkUploadModal from '../components/Layout/BulkUploadModal';
import Copyright from '../components/Layout/Copyright';
import CreativeModal from '../components/Layout/CreativeModal';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import MediaStoreModal from '../components/Layout/MediaStoreModal';
import NavHeader from '../components/Layout/NavHeader';
import PageWrapper from '../components/Layout/PageWrapper';
import PrArticleModal from '../components/Layout/PrArticleModal';
import TableWrapper from '../components/Layout/TableWrapper';
import Title from '../components/Layout/Title';
import DeleteLink from '../components/Link/DeleteLink';
import ActionContainer from '../components/Spacing/ActionContainer';
import ContentWrapper from '../components/Spacing/ContentWrapper';
import Flex from '../components/Spacing/Flex';
import CreativesTable from '../HOC/CreativesTable';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { H2, Span } from '../components/Typography';
import PaginationContainer from '../components/Table/PaginationContainer';
import MatcherErrorsModal from '../components/Layout/MatcherErrorsModal';
import ErrorModal from '../components/Layout/ErrorModal';
import { DropdownOption } from '../components/Editable/Dropdown';
import Actions from '../enums/DropdownActions';
import { DuplicateEntity } from '../enums/Duplication';
import CampaignStatus from '../enums/CampaignStatus';

const MediumPage: FC = () => {
  const { mediumId } = useParams<{ mediumId: string }>();
  const [, getMedium] = useAPI<Medium>(`/mediums/${mediumId}`, { manual: true });
  const [, putMedium] = useAPI<Medium>({ url: `/mediums/${mediumId}`, method: 'PUT' }, { manual: true });
  const [, deleteMedium] = useAPI(`/mediums/${mediumId}`, { manual: true });

  const [, getCreatives] = useAPI(`/creatives?mediumId=${mediumId}`, { manual: true });
  const [, postCreative] = useAPI<Creative>({ url: `/creatives`, method: 'POST' }, { manual: true });
  const [, putCreative] = useAPI({ method: 'PUT' }, { manual: true });
  const [, deleteCreative] = useAPI({ method: 'DELETE' }, { manual: true });
  const [, duplicateCreative] = useAPI({ url: '/duplicate', method: 'POST' }, { manual: true });

  const [, getExcel] = useAPI({ url: `/export?medium_id=${mediumId}`, responseType: 'blob' }, { manual: true });
  const [, startScreenshots] = useAPI(
    { url: `/screenshots/start?medium_id=${mediumId}`, method: 'POST' },
    { manual: true }
  );
  const downloadScreenshots = useDownload({ url: `/screenshots/download?medium_id=${mediumId}` });

  const [medium, setMedium] = useState<Medium>();
  const [creatives, setCreatives] = useState<Creative[]>([]);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [creativeInEditModal, setCreativeInEditModal] = useState<Creative>();
  const [bulkUploadFiles, setBulkUploadFiles] = useState<File[]>([]);
  const [onlyCreativesWithErrors, setOnlyCreativesWithErrors] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<string | undefined>('');
  const [toDelete, setToDelete] = useState<Creative>();
  const [selectedMediumType, setSelectedMediumType] = useState<CreativeCampaignType>(
    medium?.type ?? CreativeCampaignType.TIME
  );

  const [isCreativeEditModalOpen, setIsCreativeEditModalOpen] = useModal();
  const [isMediaModalOpen, setIsMediaModalOpen] = useModal();
  const [isPrEditModalOpen, setIsPrEditModalOpen] = useModal();
  const [isDeleteModal, showDeleteModal] = useModal();
  const [isErrorModalOpen, showErrorModal] = useModal();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setModalTitle, setIsMatcherModalOpen, isMatcherModalOpen, setCreativeId, setMatcherErrorsType } =
    useMatcherModalContext();

  const startBulkUpload = useGetFile({
    multiple: true,
    accept: ['.jpg', '.png', '.jpeg', '.mp4', '.html', '.zip'],
    callback: (files: File[]) => {
      setBulkUploadFiles(files);
    },
  });

  const pageCount = useMemo(() => {
    if (totalCount === 0) return 1;
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const fetchData = async () => {
    const response = await getMedium();
    setMedium(response.data);
    setSelectedMediumType(response.data.type ?? CreativeCampaignType.TIME);
    setCampaignId(response.data.campaign?.id);
    await fetchCreatives(pageIndex * pageSize, pageSize, onlyCreativesWithErrors);
  };

  const updateMedium = async (medium: Medium) => {
    const response = await putMedium({ data: medium });
    setMedium(response.data);
  };

  const updateName = (newName: string) => {
    if (!medium || !newName.trim()) return;

    medium.name = newName;
    updateMedium(medium);
  };

  const updateMediumType = (type: CreativeCampaignType) => {
    setSelectedMediumType(type);
    updateMedium({ ...medium, type });

    creatives.forEach((c) => (c.campaign = type));
    setCreatives(creatives);
  };

  const fetchCreatives = async (offset: number, limit: number, onlyErrors: boolean) => {
    const response = await getCreatives({ params: { offset, limit, onlyErrors } });
    setCreatives(response.data[0]);
    setTotalCount(response.data[1]);
  };

  const saveCreative = async (creative: Creative) => {
    await putCreative({ url: `/creatives/${creative.id}`, data: creative });
    const isInArray = creatives.find((c) => c.id === creative.id);
    setCreatives(
      (!!isInArray ? creatives : [...creatives, creative]).map((c) => (c.id === creative.id ? creative : c))
    );
  };

  const updateCreative = async (creative: Creative, save: boolean = false) => {
    if (creative.id && (isCreativeEditModalOpen || isPrEditModalOpen) && save) {
      saveCreative(creative);
      setCreativeInEditModal(creative);
      return;
    }

    if (creative.id) {
      saveCreative(creative);
    }

    if (isCreativeEditModalOpen || isPrEditModalOpen) setCreativeInEditModal(creative);
  };

  const handleCreativeDelete = async () => {
    const deleteResponse = await deleteCreative({ url: `/creatives/${toDelete?.id}` });

    if (deleteResponse.status === 204) {
      Toaster.success('Sikeres törlés!');

      const remainingCreatives = creatives?.filter((c) => c.id !== toDelete?.id);
      showDeleteModal(false);
      setToDelete(undefined);
      setCreatives(remainingCreatives);
    } else {
      Toaster.error('Sikertelen törlés!');
    }
  };

  const addCreative = async () => {
    const creative = new Creative();
    creative.mediumId = medium?.id;
    // creative.platform = Platform.DESKTOP;
    creative.campaign = medium?.type;

    // const response = await postCreative({ data: creative });
    // setCreatives([...creatives, creative]);
    togleCreativeEditModal(creative);
  };

  const addPrArticle = async () => {
    const creative = new Creative();
    creative.mediumId = medium?.id;
    // creative.platform = Platform.DESKTOP;
    creative.campaign = medium?.type ?? CreativeCampaignType.TIME;
    creative.type = CreativeType.ARTICLE;
    //TODO skip in new
    // const response = await postCreative({ data: creative });
    // setCreatives([...creatives, response.data]);
    // togglePrModal(response.data);
    togglePrModal(creative);
  };

  const togleCreativeEditModal = (creative?: Creative) => {
    setCreativeInEditModal(creative);
    setIsCreativeEditModalOpen(!isCreativeEditModalOpen);
  };

  const togglePrModal = (creative?: Creative) => {
    setCreativeInEditModal(creative);
    setIsPrEditModalOpen(!isPrEditModalOpen);
  };

  const toggleErrorModal = () => {
    showErrorModal(!isErrorModalOpen);
  };

  const toggleMediaModal = () => {
    setIsMediaModalOpen(!isMediaModalOpen);
  };

  const openMatcherErrorModal = (creative: Creative) => {
    setModalTitle('Hibalista');
    setCreativeId(creative.id || null);
    setMatcherErrorsType(creative.campaign || '');
    setIsMatcherModalOpen(true);
  };

  const openMediaStoreModal = (): void => {
    toggleMediaModal();
  };

  const handleStartScreenshots = async () => {
    const response = await startScreenshots();

    if (response.status === StatusCodes.NO_CONTENT) {
      Toaster.success('Screenshotok elindítva!');
    } else {
      Toaster.error('Sikertelen indítás!');
    }
  };

  const downloadExcel = async () => {
    const response = await getExcel();
    fileDownload(response.data, `${medium?.campaign?.name}_${medium?.name}_prodlista.xlsx`);
  };

  const handlePageSize = (size: number) => {
    fetchCreatives(0, size, onlyCreativesWithErrors);
    setPageSize(size);
    setPageIndex(0);
  };

  const filterErrorCreatives = (val: boolean) => {
    setPageIndex(0);
    fetchCreatives(0, pageSize, val);
    setOnlyCreativesWithErrors(val);
  };

  const handlePageIndexChange = (nextPageNumber: number) => {
    if (nextPageNumber < 0 || nextPageNumber > pageCount - 1) return;
    setPageIndex(nextPageNumber);
  };

  const redirectTo = (href: string) => {
    if (!medium?.urls || !medium.urls.length) {
      toggleErrorModal();
    } else {
      location.href = href;
    }
  };

  const handleCreativeDuplication = async (creative: Creative) => {
    Toaster.success('Kreatív duplikálása folyamatban!');
    const response = await duplicateCreative({ data: { entity: DuplicateEntity.CREATIVE, entityId: creative.id } });

    if (response.status !== StatusCodes.OK) {
      Toaster.error('Sikertelen duplikálás!');
      return;
    }

    Toaster.success('Sikeres duplikálás!');
    await fetchCreatives(pageIndex * pageSize, pageSize, onlyCreativesWithErrors);
  };

  const handleActionSelected = async (option: DropdownOption<Actions>, creative: Creative) => {
    switch (option.value) {
      case Actions.VIEW:
        if (creative?.type === 'article') {
          togglePrModal(creative);
        } else {
          togleCreativeEditModal(creative);
        }
        break;

      case Actions.DUPLICATE:
        handleCreativeDuplication(creative);
        break;

      case Actions.DELETE:
        setToDelete(creative);
        showDeleteModal(true);
        break;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageWrapper bgColor={theme.color.gray1}>
        <Header canLogout={(medium?.urls?.length ?? 0) > 0} toggleErrorModal={toggleErrorModal} />

        <ContentWrapper>
          <NavHeader>
            <ActionContainer alignment='start'>
              <HollowButton icon={faArrowLeft} onClick={() => redirectTo('/overview')}>
                Áttekintés
              </HollowButton>
              <HollowButton icon={faArrowLeft} onClick={() => redirectTo(`/campaigns/${medium?.campaign?.id}`)}>
                Kampánynézet
              </HollowButton>
            </ActionContainer>
            {medium?.campaign?.status !== CampaignStatus.CLOSED && (
              <ActionContainer alignment='end'>
                <DeleteLink
                  id={mediumId}
                  entity='medium'
                  execute={deleteMedium}
                  callback={() => (location.href = `/campaigns/${medium?.campaign?.id}`)}
                >
                  <HollowButton icon={faTrash}>
                    <Span style={{ whiteSpace: 'nowrap' }}>Médium törlése</Span>
                  </HollowButton>
                </DeleteLink>
              </ActionContainer>
            )}
          </NavHeader>

          <Title>
            <ActionContainer alignment='start'>
              <EditableText text={medium?.name} onEdited={updateName} component={HeaderWithLink} />
            </ActionContainer>
            <ActionContainer alignment='end'>
              <MainButton
                icon={faFileAlt}
                bgColor={theme.color.gray3}
                hoverColor={theme.color.dark}
                onClick={() => downloadExcel()}
              >
                Prodlista export
              </MainButton>
              <MainButton icon={faPlay} onClick={() => handleStartScreenshots()}>
                Shotok azonnali indítása
              </MainButton>
            </ActionContainer>
          </Title>

          {/* <Flex row>
            <ProdlistCard medium={medium || {}} updateMedium={updateMedium} setMedium={setMedium} />
          </Flex> */}

          <Flex row>
            <UrlCard medium={medium || {}} setMedium={setMedium} />
          </Flex>

          <Title>
            <ActionContainer alignment='start'>
              {medium?.campaign?.status !== CampaignStatus.CLOSED && (
                <DropdownButton
                  addCreative={addCreative}
                  addMultipleCreative={startBulkUpload}
                  selectCreatives={openMediaStoreModal}
                  addArticle={addPrArticle}
                />
              )}
            </ActionContainer>
            <ActionContainer alignment='end'>
              <RadioGroup row value={selectedMediumType}>
                {Object.values(CreativeCampaignType).map((value, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={() => updateMediumType(value)}
                      control={<Radio />}
                      label={CreativeCampaignTypeNames.get(value)}
                    />
                  );
                })}
              </RadioGroup>

              <PureMainButton icon={faDownload} onClick={downloadScreenshots}>
                Screenshotok letöltése
              </PureMainButton>
              <PureMainButton onClick={() => (location.href = `/creatives?mediumId=${mediumId}`)}>
                Screenshotok megtekintése
              </PureMainButton>
            </ActionContainer>
          </Title>

          <TableWrapper>
            <CreativesTable
              campaignStatus={medium?.campaign?.status}
              pageSize={pageSize}
              creatives={creatives}
              handlePageSize={handlePageSize}
              updateCreative={updateCreative}
              openMatcherErrorModal={openMatcherErrorModal}
              handleActionSelected={handleActionSelected}
              onlyCreativesWithErrors={onlyCreativesWithErrors}
              filterErrorCreatives={filterErrorCreatives}
            />
          </TableWrapper>
        </ContentWrapper>
      </PageWrapper>

      {isCreativeEditModalOpen && (
        <CreativeModal
          updateCreative={updateCreative}
          creative={creativeInEditModal}
          isOpen={isCreativeEditModalOpen}
          toggle={togleCreativeEditModal}
          campaignId={campaignId}
          medium={medium}
        />
      )}

      {isPrEditModalOpen && (
        <PrArticleModal
          updateCreative={updateCreative}
          creative={creativeInEditModal}
          isOpen={isPrEditModalOpen}
          toggle={togglePrModal}
          // isCreating={true}
          isCreating={!!!creativeInEditModal?.id}
        />
      )}

      {campaignId && (
        <MediaStoreModal
          campaignId={campaignId}
          isOpen={isMediaModalOpen}
          toggle={toggleMediaModal}
          onFinish={fetchData}
          medium={medium}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          isOpen={isErrorModalOpen}
          toggle={toggleErrorModal}
          title='URL hiányzik'
          description='Kérem vegyen fel egy URL-t, mielőtt elhagyja az oldalt'
        />
      )}

      {isMatcherModalOpen && campaignId && <MatcherErrorsModal campaignId={campaignId} />}

      <BulkUploadModal
        creatives={creatives}
        files={bulkUploadFiles}
        cancel={() => setBulkUploadFiles([])}
        onFinish={fetchData}
      />

      <Modal isOpen={isDeleteModal} toggle={showDeleteModal}>
        <DeleteModalContent onDelete={handleCreativeDelete} onCancel={() => showDeleteModal(false)}>
          <H2>Biztosan törölni szeretné a kreatívot?</H2>
        </DeleteModalContent>
      </Modal>

      <Footer>
        <Copyright />
        {pageCount > 1 && (
          <Flex justify='center' style={{ marginTop: theme.space.small }}>
            <PaginationContainer pageIndex={pageIndex} pageCount={pageCount} setPageIndex={handlePageIndexChange} />
          </Flex>
        )}
      </Footer>
    </>
  );
};

export default MediumPage;
