/* eslint-disable max-len */
import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { KeyboardEventHandler } from 'react-select';
import { Key } from 'ts-key-enum';
import validator from 'validator';
import { styled, theme } from '../../config/Theme';
import UrlType from '../../enums/UrlType';
import useAPI from '../../hooks/useAPI';
import DeepUrl from '../../models/DeepUrl';
import Medium from '../../models/Medium';
import Toaster from '../../utils/Toaster';
import UrlBadge from '../Badge/UrlBadge';
import UrlTypeBadge from '../Badge/UrlTypeBadge';
import MainButton from '../Button/MainButton';
import SearchSelect, { SelectOption } from '../Editable/SearchSelect';
import Flex from '../Spacing/Flex';
import Card from './Card';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MediumFormValues } from '../Layout/MediumModal';
import { GroupedRadio } from '../Form/GroupedRadio';
import { ErrorMsg } from '../Form/Form';

export type UrlCardProps = {
  medium: Medium;
  isCreateMedium?: boolean;
  setMedium: React.Dispatch<React.SetStateAction<Medium | undefined>>;
};
const UrlBadeContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const UrlError = styled.div`
  margin-top: 1rem;
  border: 1px solid red;
  border-radius: 5px;
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;

  span:nth-child(2) {
    margin-left: 1rem;
    font-weight: 400;
  }
`;

const UrlTitle = styled.span`
  margin-bottom: ${theme.space.lsmall};
  display: block;
  font-weight: 500;
`;

const UrlCard = (props: UrlCardProps): JSX.Element => {
  const [searchUrl, setSearchUrl] = useState<SelectOption | null>();
  const [urlOptions, setUrlOptions] = useState<SelectOption[]>();
  const [urlType, setUrlType] = useState<UrlType | undefined>(undefined);
  const [radioGroupKey, setRadioGroupKey] = useState<number>(Date.now());
  const [urlTypeError, setUrlTypeError] = useState<boolean>(false);
  const form = useFormContext<MediumFormValues>();

  const errors = form?.formState.errors;

  const [urls, getUrls] = useAPI('/urls', { manual: true });

  useEffect(() => {
    getUrls();
  }, [getUrls]);

  const [postUrlResponse, postUrl] = useAPI<DeepUrl>(
    { url: `/mediums/${props.medium.id}/url`, method: 'POST' },
    { manual: true }
  );

  const [deleteUrlResponse, deleteUrl] = useAPI<DeepUrl>({ method: 'DELETE' }, { manual: true });

  const removeUrl = async (urlId?: string, rootUrl?: string) => {
    if (props.isCreateMedium && rootUrl && props.medium.urls) {
      const filtered = props.medium.urls.filter((url) => url.rootUrl !== rootUrl);
      const newMedium = { ...props.medium, urls: filtered };
      props.setMedium(newMedium);
      form.setValue('urls', [...filtered.map((url) => ({ rootUrl: url.rootUrl!, urlType: url.urlType! }))]);
      getUrls();
    }
    if (!urlId || !props.medium || !props.medium.urls || props.medium.urls.length == 0) return;

    const response = await deleteUrl({ url: `/mediums/${props.medium.id}/url/${urlId}` });
    if (deleteUrlResponse.error || !response.data) {
      console.error(postUrlResponse.error);
      Toaster.error('Sikertelen URL törlés!');
      return;
    }

    const newMedium = { ...props.medium, urls: props.medium.urls.filter((url) => url.id !== urlId) };
    props.setMedium(newMedium);
    getUrls();
  };

  const addUrl = async () => {
    setUrlTypeError(false);
    if (!searchUrl || !props.medium) return;

    let rootUrl = searchUrl.label;
    if (!rootUrl.startsWith('http://') && !rootUrl.startsWith('https://')) rootUrl = 'https://' + searchUrl.label;
    if (rootUrl.endsWith('/')) rootUrl = rootUrl.slice(0, -1);

    if (props.medium?.urls?.some((url) => url.rootUrl === rootUrl)) {
      Toaster.warn('Az URL már létezik!');
      return;
    }

    if (!validator.isURL(rootUrl)) {
      Toaster.error('Érvénytelen url!');
      return;
    }

    if (!urlType) {
      Toaster.error('URL típus kiválasztása kötelező!');
      setUrlTypeError(true);
      return;
    }

    const payload = new DeepUrl(undefined, rootUrl, urlType);

    if (!props.isCreateMedium) {
      const response = await postUrl({ data: payload });
      if (postUrlResponse.error || !response.data) {
        console.error(postUrlResponse.error);
        Toaster.error('Sikertelen URL létrehozás');
        return;
      }
      props.setMedium({ ...props.medium, urls: [...(props.medium.urls ?? []), response.data] });
    } else {
      form.setValue('urls', [...form.getValues('urls'), { rootUrl, urlType }]);
      props.setMedium({ ...props.medium, urls: [...(props.medium.urls ?? []), payload] });
      form.trigger('urls');
    }

    setSearchUrl(null);
    getUrls();
    setUrlType(undefined);
    setRadioGroupKey(Date.now());
  };

  useMemo(() => {
    const options = urls.data?.map((u: string) => ({ value: u, label: u })) as SelectOption[];
    setUrlOptions(options);
  }, [urls.data]);

  const handleKeyDown: KeyboardEventHandler = async (e: KeyboardEvent) => {
    if (e.key === Key.Enter) addUrl();
  };

  const shouldRenderUrlErrorCard = useMemo(
    () => props.medium.urls?.some((url) => url.urlList?.length === 1 && url.urlType !== UrlType.MAIN_PAGE),
    [props.medium]
  );

  return (
    <Card boxSizing='border-box' style={{ width: '100%' }}>
      <UrlTitle>URL-ek</UrlTitle>

      <div>
        <SearchSelect
          placeholder='https://domain.hu'
          isClearable
          isCreatable
          options={urlOptions}
          value={searchUrl}
          onChange={setSearchUrl}
          onKeyDown={handleKeyDown}
          width='100%'
          errorMessage={errors?.urls?.message}
        />
        {props.isCreateMedium && errors?.urls && <ErrorMsg>{errors.urls.message}</ErrorMsg>}
      </div>

      <Flex
        boxSizing='border-box'
        gap={theme.space.lsmall}
        margin={`${theme.space.lsmall} 0 0 0`}
        row
        justify='space-between'
        align='center'
      >
        <GroupedRadio>
          <RadioGroup row key={radioGroupKey}>
            {Object.values(UrlType).map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={value}
                  onChange={() => {
                    setUrlType(value);
                  }}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 14,
                      lineHeight: '14px',
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                  label={<UrlTypeBadge id={value} urlType={value} />}
                />
              );
            })}
          </RadioGroup>
        </GroupedRadio>

        <MainButton variation='create' disabled={!searchUrl || !urlType} type='button' onClick={() => addUrl()}>
          <span style={{ whiteSpace: 'nowrap' }}>URL hozzáadása</span>
        </MainButton>
      </Flex>
      <div>{urlTypeError && <ErrorMsg>Kötelező választani</ErrorMsg>}</div>

      <UrlBadeContainer>
        {props.medium.urls?.map((url, index) => {
          return (
            <UrlBadge
              key={index}
              icon={faTimesCircle}
              bgColor={theme.color.gray2}
              border={url.urlList?.length === 1 ? `1px solid ${theme.color.warningRed}` : 'none'}
              url={url}
              onIconClick={() => (props.isCreateMedium ? removeUrl(undefined, url.rootUrl) : removeUrl(url.id))}
            />
          );
        })}
      </UrlBadeContainer>

      {shouldRenderUrlErrorCard && (
        <Flex>
          <UrlError>
            <FontAwesomeIcon icon={faExclamationTriangle} color={theme.color.danger} />
            <span>
              A pirossal jelzett oldalak esetében a Seeker nem talált al-url-t, kérjük kézzel vidd fel a rovatok
              url-jeit!
            </span>
          </UrlError>
        </Flex>
      )}
    </Card>
  );
};

export default UrlCard;
