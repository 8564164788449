import React, { MouseEventHandler } from 'react';
import { faFileAlt, faImage, faImages } from '@fortawesome/free-regular-svg-icons';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, theme } from '../../config/Theme';

export type DropdownButtonItemsProps = {
  addCreative: () => void;
  addMultipleCreative: MouseEventHandler<Element>;
  selectCreatives: () => void;
  handleClose: () => void;
  addArticle: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const DropdownButtonItemsStyled = styled.div`
  width: fit-content;
  position: absolute;
  display: grid;
  align-items: center;
  background-color: white;
  color: ${theme.color.primary};
  font-family: 'Rubik';
  & svg {
    margin-right: ${theme.space.half};
  }
  border-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  z-index: 1;
  box-shadow: 0px 3px 8px 0px #c0cae199;
`;

const ButtonItem = styled.div`
  font-family: 'Rubik';
  font-size: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;

  :hover {
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
  }
`;

const Divider = styled.div`
  border: 1px solid #eeeeee;
  width: 80%;
  justify-self: center;
`;

const DropdownButtonItems = (props: DropdownButtonItemsProps): JSX.Element => {
  return (
    <DropdownButtonItemsStyled onClick={props.handleClose}>
      <ButtonItem onClick={() => props.addCreative()}>
        <FontAwesomeIcon icon={faImage} />
        Kreatív hozzáadása
      </ButtonItem>
      <ButtonItem onClick={(e) => props.addMultipleCreative(e)}>
        <FontAwesomeIcon icon={faImages} />
        Több kreatív feltöltése
      </ButtonItem>
      <ButtonItem onClick={() => props.selectCreatives()}>
        <FontAwesomeIcon icon={faGripHorizontal} />
        Kreatívok beválogatása
      </ButtonItem>
      <Divider />
      <ButtonItem onClick={() => props.addArticle()}>
        <FontAwesomeIcon icon={faFileAlt} />
        PR cikk hozzáadása
      </ButtonItem>
    </DropdownButtonItemsStyled>
  );
};

export default DropdownButtonItems;
