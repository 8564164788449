import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { makeUseAxios, UseAxios } from 'axios-hooks';
import { StatusCodes } from 'http-status-codes';
import config from '../config/Config';
import TokenResponse from '../models/TokenResponse';
import Toaster from '../utils/Toaster';
import TokenHelper, { Token } from '../utils/TokenHelper';

export type PasswordTokenRequest = {
  grantType: string;
  username: string;
  password: string;
};

export type RefreshTokenRequest = {
  grantType: string;
  refreshToken?: string;
};

export const ApiURL = config.port
  ? `${config.protocoll}://${config.baseUrl}:${config.port}`
  : `${config.protocoll}://${config.baseUrl}`;

// Instantiate axios object
export const API: AxiosInstance = axios.create({
  baseURL: ApiURL,
  timeout: config.timeout,
});

// Attach access token to all requests
API.interceptors.request.use(
  (requestConfig) => {
    // Cancel all API requests if there's no access_token
    if (!TokenHelper.getToken(Token.ACCESS)) {
      console.log(`Redirected due to this response: ${!TokenHelper.getToken(Token.ACCESS)}`);
      throw new axios.Cancel();
    }
    return setHeaders(requestConfig);
  },
  (error) => Promise.reject(error)
);

export const setHeaders = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  requestConfig.headers = {
    Authorization: `Bearer ${TokenHelper.getToken(Token.ACCESS)}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return requestConfig;
};

// Refresh token when receiving 401 Unauthorized response
API.interceptors.response.use(
  (result) => Promise.resolve(result),
  async (error) => {
    // Handle 401s
    if (error.config && error.response?.status === StatusCodes.UNAUTHORIZED && !error.config.__isRetry) {
      error.config.__isRetry = true;
      if (error.config.url == '/oauth/token') {
        const data = error.config?.data && JSON.parse(error.config.data);
        if (data?.refreshToken) {
          throw new axios.Cancel();
        }
      }
      return await refreshTokenWithRequest()
        // In case everything went fine, retry the previous request
        .then((res) => {
          setHeaders(error.config);
          return API.request(error.config);
        })

        // In case of error, redirect to login page
        .catch((err) => {
          TokenHelper.removeToken(Token.ACCESS);
          TokenHelper.removeToken(Token.REFRESH);
          location.href = config.loginUrl;
          throw new axios.Cancel();
        });

      // Handle 403s
    } else if (error.config && error.response?.status === StatusCodes.FORBIDDEN) {
      Toaster.warn('Hozzáférés megtagadva!');
      throw new axios.Cancel();
    }
    return error;
  }
);

const refreshTokenWithRequest = async () => {
  const refreshToken = TokenHelper.getToken(Token.REFRESH);

  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token not found.'));
  }

  const payload: RefreshTokenRequest = {
    grantType: 'refresh_token',
    refreshToken: refreshToken,
  };

  // Get new tokens
  return await API.post<TokenResponse>('/oauth/token', payload)

    // If successful, save new tokens, and repeat previous request
    .then(async (res) => {
      TokenHelper.setBoth(res.data);
      return Promise.resolve(true);
    })

    .catch((err) => Promise.reject(err));
};

export const refreshToken = async (): Promise<boolean> => {
  const refreshToken = TokenHelper.getToken(Token.REFRESH);

  if (!refreshToken) {
    console.log(new Error('Refresh token not found.'));
    Promise.resolve(false);
  }

  const payload: RefreshTokenRequest = {
    grantType: 'refresh_token',
    refreshToken: refreshToken,
  };

  // Get new tokens
  return await API.post<TokenResponse>('/oauth/token', payload)
    .then(async (res) => {
      TokenHelper.setBoth(res.data);
      return Promise.resolve(true);
    })

    .catch(() => {
      return Promise.resolve(false);
    });
};

const useAPI: UseAxios = makeUseAxios();
useAPI.configure({ axios: API });

export default useAPI;
