import React, { useMemo } from 'react';
import getLogColumns from '../components/Columns/getLogColumns';
import Column from '../components/Table/Column';
import Campaign from '../models/Campaign';
import UserEvent from '../models/UserEvent';

const useLogColumns = () => {
  const columns: Column<UserEvent>[] = useMemo(() => {
    return getLogColumns();
  }, []);
  return columns;
};

export default useLogColumns;
