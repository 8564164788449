import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { theme } from '../../config/Theme';

import CampaignStatus from '../../enums/CampaignStatus';
import Campaign from '../../models/Campaign';

import CampaignProgress from '../Badge/CampaignProgress';
import Flex from '../Spacing/Flex';
import CampaignStatusCell from '../Table/CampaignStatusCell';
import Column from '../Table/Column';
import { Ellipsized } from '../Typography';
import Dropdown, { DropdownOption } from '../Editable/Dropdown';
import Actions from '../../enums/DropdownActions';
import { faDownload, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import StatusBadge from '../Badge/StatusBadge';

const options: DropdownOption<Actions>[] = [
  {
    value: Actions.DOWNLOAD,
    label: 'Screenshotok letöltése',
    icon: <FontAwesomeIcon icon={faDownload} color={theme.color.primary} />,
    textColor: theme.color.primary,
  },
  {
    value: Actions.DUPLICATE,
    label: 'Kampány duplikálása',
    icon: <FontAwesomeIcon icon={faCopy} color={theme.color.primary} />,
    textColor: theme.color.primary,
  },
  {
    value: Actions.DELETE,
    label: 'Kampány törlése',
    icon: <FontAwesomeIcon icon={faTrashAlt} color={theme.color.danger} />,
    textColor: theme.color.danger,
  },
];

const getCampaignColumns = (
  openMatcherErrorModal: (campaign: Campaign) => void,
  handleSorting: (property: string, direction: 'ASC' | 'DESC') => void,
  handleSelectedDropdownOption: (option: DropdownOption<Actions>, Campaign: Campaign) => void
) => {
  const columns: Column<Campaign>[] = [
    {
      id: 'name',
      label: 'Kampány',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        return <Ellipsized>{campaign.name}</Ellipsized>;
      },
      width: 20,
      sortable: true,
    },
    {
      label: 'Státusz',
      id: 'status',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        return (
          <CampaignStatusCell status={campaign.status ?? CampaignStatus.SCHEDULED}>
            {campaign.status}
          </CampaignStatusCell>
        );
      },
      sortable: true,
      width: 12,
    },
    {
      label: 'Kampány kezdete',
      id: 'start',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        const date = moment(campaign.start);
        return date.isValid() ? date.format('YYYY.MM.DD.') : '-';
      },
      width: 13,
      sortable: true,
    },
    {
      label: 'Kampány vége',
      id: 'end',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        const date = moment(campaign.end);
        return date.isValid() ? date.format('YYYY.MM.DD.') : '-';
      },
      width: 13,
      sortable: true,
    },
    {
      id: 'creativeCount',
      label: 'Kreatívok',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        const creativeCount = campaign.mediums?.reduce((acc, m) => {
          const creativeCount = acc + (m.creatives?.length || 0);
          return creativeCount;
        }, 0);

        const variationErrors = campaign.mediums?.reduce((acc, m) => {
          const allErrorNumbers =
            acc +
            (m.creatives?.reduce((acc, v) => {
              const errorNumbers =
                Number(v.variations?.length) === 0
                  ? 1
                  : 0 + Number(v.variationError?.length) === 0 && 0 + Number(v.articleVariationError?.length) === 0
                  ? 0
                  : 1;
              const err = acc + errorNumbers;
              return err;
            }, 0) || 0);

          return allErrorNumbers;
        }, 0);
        return (
          <Flex justify='center' align='center'>
            <StatusBadge type={variationErrors !== 0 || creativeCount === 0 ? 'danger' : 'success'}>
              {Number(creativeCount) - Number(variationErrors)} / {creativeCount}
            </StatusBadge>
          </Flex>
        );
      },
      width: 8,
      sortable: true,
    },
    {
      label: 'Eltelt napok',
      id: 'daysElapsed',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(campaign) {
        return <>{campaign.daysElapsed}</>;
      },
      width: 10,
      sortable: true,
    },
    {
      label: 'Megjelent napok',
      id: 'progress',
      getter(c: Campaign) {
        return (
          <Flex justify='center' align='center'>
            <CampaignProgress
              errorState={c.errorState || 'noErrors'}
              totalDays={c.daysProposed ?? 0}
              daysPassed={c.successCount ?? 0}
              onClick={(e) => {
                e.stopPropagation();
                openMatcherErrorModal(c);
              }}
            />
          </Flex>
        );
      },
      width: 12,
      sortable: false,
    },
    {
      label: 'Matchek',
      id: 'matchNumber',
      sort: (property: string, direction: 'ASC' | 'DESC') => {
        handleSorting(property, direction);
      },
      getter(c: Campaign) {
        return (
          <Flex justify='center' align='center'>
            {c.matchNumber}
          </Flex>
        );
      },
      width: 8,
      sortable: true,
    },
    {
      label: '',
      id: 'action',
      getter(campaign: Campaign) {
        return (
          <Dropdown
            options={options}
            onSelected={(option: DropdownOption<Actions>) => handleSelectedDropdownOption(option, campaign)}
          >
            <FontAwesomeIcon icon={faEllipsisV} color={theme.color.primary} style={{ cursor: 'pointer' }} />
          </Dropdown>
        );
      },
      width: 10,
      sortable: false,
    },
  ];

  return columns;
};

export default getCampaignColumns;
