import React from 'react';
import { theme } from '../../../../config/Theme';
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../../../../enums/CreativeCampaignType';
import Creative from '../../../../models/Creative';
import Badge from '../../../Badge/Badge';
import RowWrapper from '../../../Spacing/RowWrapper';
import { Span } from '../../../Typography';

const creativeTableCampaignAccessor = (creative: Creative): JSX.Element => (
  <RowWrapper>
    <Badge bgColor={theme.color.gray2}>
      <Span>{CreativeCampaignTypeNames.get(creative.campaign || CreativeCampaignType.TIME)}</Span>
    </Badge>
  </RowWrapper>
);

export default creativeTableCampaignAccessor;
