import { theme } from '../config/Theme';

enum CampaignStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'inprogress',
  CLOSED = 'closed',
  INACTIVE = 'inactive',
}

export const CampaignStatusColors = new Map<CampaignStatus, string>([
  [CampaignStatus.SCHEDULED, theme.color.secondary],
  [CampaignStatus.IN_PROGRESS, theme.color.success],
  [CampaignStatus.CLOSED, theme.color.danger],
  [CampaignStatus.INACTIVE, theme.color.warning],
]);

export const CampaignStatusNames = new Map<CampaignStatus, string>([
  [CampaignStatus.SCHEDULED, 'Előkészítve'],
  [CampaignStatus.IN_PROGRESS, 'Folyamatban'],
  [CampaignStatus.CLOSED, 'Lezárva'],
  [CampaignStatus.INACTIVE, 'Inaktív'],
]);
export default CampaignStatus;
