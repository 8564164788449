import React, { FC } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { theme } from '../../config/Theme';
import IconButton from '../Button/IconButton';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const styling = {
  danger: {
    borderWidth: '0',
    borderColor: theme.color.danger,
    color: theme.color.danger,
    fontWeight: 400,
  },
  success: {
    borderWidth: '0',
    borderColor: theme.color.success,
    color: theme.color.success,
    fontWeight: 400,
  },
};

type CampaignErrorState = 'success' | 'danger';

type CampaignProgressProps = {
  type: 'success' | 'danger';
  children: React.ReactNode;
};

type WrapperProps = {
  errorState: CampaignErrorState;
};

const Wrapper = styled.button<WrapperProps>`
  border-width: ${({ errorState }) => styling[errorState].borderWidth};
  border-color: ${({ errorState }) => styling[errorState].borderColor};
  font-weight: ${({ errorState }) => styling[errorState].fontWeight};
  background-color: transparent;
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  cursor: pointer;
  position: relative;
`;

const StatusBadge: FC<CampaignProgressProps> = ({ type, children }): JSX.Element => {
  return (
    <Wrapper errorState={type}>
      <IconButton
        icon={type === 'danger' ? faExclamationTriangle : faCheckCircle}
        color={styling[type].color}
        style={{ marginRight: theme.space.half }}
        size={'sm'}
      />

      {children}
    </Wrapper>
  );
};

export default StatusBadge;
