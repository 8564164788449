import React, { createContext, useContext, ReactNode, useState } from 'react';

type MatcherModalProviderProps = {
  children: ReactNode;
};

type MatcherModalContext = {
  modalTitle: string;
  mediumId: string | null;
  creativeId: string | null;
  isMatcherModalOpen: boolean;
  matcherErrorsType: string;
  setIsMatcherModalOpen: (open: boolean) => void;
  setModalTitle: (title: string) => void;
  setMediumId: (id: string | null) => void;
  setCreativeId: (id: string | null) => void;
  setMatcherErrorsType: (type: string) => void;
};

const MatcherModalContext = createContext<MatcherModalContext>({
  modalTitle: '',
  mediumId: null,
  creativeId: null,
  isMatcherModalOpen: false,
  matcherErrorsType: '',
  setModalTitle: () => {},
  setIsMatcherModalOpen: () => {},
  setMediumId: () => {},
  setCreativeId: () => {},
  setMatcherErrorsType: () => {},
});

export function useMatcherModalContext() {
  return useContext(MatcherModalContext);
}

export function MatcherModalProvider({ children }: MatcherModalProviderProps) {
  const [modalTitle, setModalTitle] = useState<string>('');
  const [mediumId, setMediumId] = useState<string | null>('');
  const [creativeId, setCreativeId] = useState<string | null>('');
  const [isMatcherModalOpen, setIsMatcherModalOpen] = useState<boolean>(false);
  const [matcherErrorsType, setMatcherErrorsType] = useState<string>('');

  const handleMatcherModalOpen = (open: boolean): void => {
    setIsMatcherModalOpen(open);
  };

  const handleMatcherModalTitle = (title: string): void => {
    setModalTitle(title);
  };

  const handleMediumIdChange = (id: string | null): void => {
    setMediumId(id);
  };

  const handleCreativeIdChange = (id: string | null): void => {
    setCreativeId(id);
  };

  const handleMatcherErrorsType = (type: string): void => {
    setMatcherErrorsType(type);
  };

  const value = {
    modalTitle,
    mediumId,
    creativeId,
    isMatcherModalOpen,
    matcherErrorsType,
    setModalTitle: handleMatcherModalTitle,
    setIsMatcherModalOpen: handleMatcherModalOpen,
    setMediumId: handleMediumIdChange,
    setCreativeId: handleCreativeIdChange,
    setMatcherErrorsType: handleMatcherErrorsType,
  };

  return (
    <>
      <MatcherModalContext.Provider value={value}>{children}</MatcherModalContext.Provider>
    </>
  );
}
