import React from 'react';
import { styled } from '../../config/Theme';

export type PaginationButtonProps = {
  isCurrentPage: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button<PaginationButtonProps>`
  height: ${(props) => props.theme.space.small};
  min-width: ${(props) => props.theme.space.small};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isCurrentPage ? props.theme.color.white : props.theme.color.black)};
  background-color: ${(props) => (props.isCurrentPage ? props.theme.color.primary : props.theme.color.white)};
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  outline: none;
  border: none;
  margin: 0 calc(${(props) => props.theme.space.half} / 2);
  cursor: pointer;
  font-family: 'Rubik';

  &:hover {
    outline: none;
    border: none;
    background-color: ${(props) => (props.isCurrentPage ? props.theme.color.secondary : props.theme.color.gray2)};
    color: ${(props) => (props.isCurrentPage ? props.theme.color.white : props.theme.color.black)};
  }

  &:active {
    color: ${(props) => (props.isCurrentPage ? props.theme.color.white : props.theme.color.black)};
    background-color: ${(props) => (props.isCurrentPage ? props.theme.color.primary : props.theme.color.white)};
  }
`;

const PaginationButton = (props: PaginationButtonProps): JSX.Element => <Button {...props}>{props.children}</Button>;

export default PaginationButton;
