import { styled } from '../../config/Theme';

export const FormButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const FormItem = styled.div`
  margin-bottom: 20px;
`;

export const ErrorMsg = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.warningRed};
  margin-top: 5px;
`;
