import moment from 'moment-with-locales-es6';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { ThemeProvider } from 'styled-components';
import StyledToastContainer from './components/StyledToastContainer';
import config from './config/Config';
import { styled, theme } from './config/Theme';
import CampaignPage from './pages/CampaignPage';
import CreativesPage from './pages/CreativesPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import LogPage from './pages/LogPage';
import Logs from './pages/Logs';
import MediumPage from './pages/MediumPage';
import OverviewPage from './pages/OverviewPage';
import ScreenshotsPage from './pages/ScreenshotsPage';
import UserGroupEditPage from './pages/UserGroupEditPage';
import UserGroupsPage from './pages/UserGroupsPage';
import TokenHelper, { Token } from './utils/TokenHelper';

import { MatcherModalProvider } from './components/Provider/MatcherErrorModalProvider';

// Setup protected endpoints
const PrivateRoute = ({ children, ...rest }: { [x: string]: any; children: any }): JSX.Element => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return TokenHelper.isInvalid(Token.ACCESS) ? (
          <Redirect to={{ pathname: config.loginUrl, state: { from: location } }} />
        ) : (
          children
        );
      }}
    />
  );
};

const AppContainer = styled.div`
  display: block;
  height: 100%;
  width: min(100%, 100vw);
`;

const App = (): JSX.Element => {
  // Configuration
  useEffect(() => {
    moment.locale('hu');
  }, []);

  return (
    <AppContainer>
      <ThemeProvider theme={theme}>
        <MatcherModalProvider>
          <Router>
            <Switch>
              <Redirect exact from='/' to='/login' />

              <Route path='/login' component={LoginPage} />

              <PrivateRoute path='/overview'>
                <Route path='/overview' component={OverviewPage} />
              </PrivateRoute>

              <PrivateRoute path='/campaigns/:campaignId'>
                <Route path='/campaigns/:campaignId'>
                  <CampaignPage />
                </Route>
              </PrivateRoute>

              <PrivateRoute path='/mediums/:mediumId'>
                <Route path='/mediums/:mediumId' component={MediumPage} />
              </PrivateRoute>

              <PrivateRoute path='/creatives'>
                <Route path='/creatives' component={CreativesPage} />
              </PrivateRoute>

              <PrivateRoute path='/screenshots'>
                <Route path='/screenshots' component={ScreenshotsPage} />
              </PrivateRoute>

              <PrivateRoute path='/groups/:groupId'>
                <Route path='/groups/:groupId' component={UserGroupEditPage} />
              </PrivateRoute>

              <PrivateRoute path='/groups'>
                <Route path='/groups' component={UserGroupsPage} />
              </PrivateRoute>

              <PrivateRoute path='/logs'>
                <Route path='/logs' component={LogPage} />
              </PrivateRoute>

              <PrivateRoute path='/system-logs'>
                <Route path='/system-logs' component={Logs} />
              </PrivateRoute>

              <Route path='/error' component={ErrorPage} />

              <Redirect from='*' to='/error' />
            </Switch>
            <StyledToastContainer />
          </Router>
        </MatcherModalProvider>
      </ThemeProvider>
    </AppContainer>
  );
};

export default App;
