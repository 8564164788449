/* eslint-disable react/prop-types */
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-with-locales-es6';
import React, { FunctionComponent, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { styled, theme } from '../../config/Theme';
import Daterange from '../../models/Daterange';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import Card from '../Cards/Card';

enum CellMode {
  DISABLED,
  NORMAL,
  SELECTED,
  POINT,
}

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.whiteSemiTransparent};
`;

const Component = styled(Card)`
  width: 15rem;
  min-width: 200px;
  min-height: 300px;
  margin: 0 auto;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.space.tiny};
`;

const Footer = styled.div`
  margin-top: ${(props) => props.theme.space.tiny};

  button {
    width: 100%;
    padding: ${(props) => props.theme.space.half} 0;
    font-weight: 500;
  }
`;

const Title = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontsize.tiny};
`;

const NavButtons = styled.div`
  justify-self: flex-end;
  line-height: ${(props) => props.theme.space.half};
  width: ${(props) => props.theme.space.small};
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div``;

const Rows = styled.div``;

const Row = styled.div`
  height: ${(props) => props.theme.space.xsmall};
  margin-bottom: ${(props) => props.theme.space.half};
  display: flex;
  align-items: center;
  justify-items: center;
`;

const HeaderRow = styled(Row)`
  height: 34px;
  border-bottom: 1px solid ${(props) => props.theme.color.gray2};
`;

const HeaderCell = styled.div`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.color.gray3};
  font-weight: 400;
  font-size: ${(props) => props.theme.fontsize.xtiny};
`;

type DayProps = {
  background: string;
};

const Day = styled.span<DayProps>`
  display: inline-block;
  width: ${(props) => props.theme.space.xsmall};
  line-height: ${(props) => props.theme.space.xsmall};
  border-radius: ${(props) => props.theme.space.threequarter};
  position: relative;
  ${(props) => (props.background !== 'transparent' ? 'background:' + props.background + ' !important' : '')};
`;

type CellComponentProps = {
  mode?: CellMode;
  color: string;
  background: string;
  start?: boolean;
  end?: boolean;
};

const CellComponent = styled.div<CellComponentProps>`
  flex: 1;
  font-size: ${(props) => props.theme.fontsize.xtiny};
  text-align: center;
  font-weight: 500;
  height: ${(props) => props.theme.space.xsmall};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  cursor: pointer;

  &:hover ${Day} {
    background: ${(props) => props.theme.color.gray2};
  }
`;

const PointCellComponent = styled(CellComponent)`
  &:hover ${Day} {
    background: ${(props) => props.theme.color.gray2};

    &::after {
      content: 'x';
      color: white;
      position: absolute;
      background-color: red;
      text-align: center;
      display: inline-block;
      z-index: 100;
      font-size: ${(props) => props.theme.fontsize.xtiny};
      border-radius: ${(props) => props.theme.space.half};
      line-height: ${(props) => props.theme.space.tiny};
      top: 0.25rem;
      width: ${(props) => props.theme.space.tiny};
      height: ${(props) => props.theme.space.tiny};
      left: 0.25rem;
    }
  }
`;

type CellProps = {
  mode?: CellMode;
  start?: boolean;
  end?: boolean;
  empty?: boolean;
  day: moment.Moment;
  inCreate: boolean;
  onClick: (day: moment.Moment) => void;
};

const Cell: FunctionComponent<CellProps> = ({ day, mode, start, end, onClick, empty, inCreate }) => {
  const _mode = typeof mode !== 'undefined' ? mode : CellMode.NORMAL;
  let color = theme.color.black;
  let backgroundColor = 'transparent';
  let dayBackground = 'transparent';

  if (_mode == CellMode.DISABLED) {
    color = theme.color.gray3;
  } else if (_mode === CellMode.SELECTED) {
    backgroundColor = theme.color.gray2;
    dayBackground = theme.color.gray2;
    color = theme.color.primary;
  } else if (_mode === CellMode.POINT) {
    color = theme.color.white;
    backgroundColor = theme.color.gray2;
    dayBackground = theme.color.primary;
  }

  let background = backgroundColor;
  // CSS "hack" to split the bg color in two
  if (start && !end) {
    background = `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, ${backgroundColor} 50%)`;
  } else if (!start && end) {
    background = `linear-gradient(90deg, ${backgroundColor} 0%, ${backgroundColor} 50%, rgba(0,0,0,0) 50%)`;
  } else if (start && end) {
    background = 'transparent';
  }

  const MainComponent = mode === CellMode.POINT && !inCreate ? PointCellComponent : CellComponent;

  return (
    <MainComponent
      onClick={() => onClick(day)}
      mode={_mode}
      color={color}
      background={background}
      start={start}
      end={end}
    >
      <Day background={dayBackground}>{empty ? '' : day.date()}</Day>
    </MainComponent>
  );
};

export type DateRangeContainerProps = {
  onFinish: (ranges: Daterange[], canceled?: boolean) => void;
  ranges: Daterange[];
} & React.HTMLAttributes<HTMLDivElement>;

const DatePicker = (props: DateRangeContainerProps): JSX.Element => {
  const [from, setFrom] = useState<number>(moment().startOf('month').unix());
  const [to, setTo] = useState<number>(moment().add(1, 'month').endOf('month').unix());

  const [ranges, setRanges] = useState<Daterange[]>(
    props.ranges.map((r) => new Daterange(r.id, moment(r.start), moment(r.end)))
  );
  const [inCreate, setInCreate] = useState<boolean>(false);

  const finish = () => {
    props.onFinish(ranges.filter((r) => r.end));
  };

  const cancel = () => {
    props.onFinish([], true);
  };

  const pickerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(pickerRef, cancel);

  // Otherwise it's "en", though the App.tsx set it :/
  // With "en" locale the getContent starts an endless loop because of "moment.startOf"
  moment.locale('hu');

  // Gather weekdays from moment
  const forDays = moment().day('1');
  const days = [];
  do {
    days.push(forDays.format('dd'));
    forDays.add(1, 'day');
  } while (forDays.day() != 1);

  // Nav to next month
  const nextMonth = () => {
    const newFrom = moment.unix(from).add(1, 'month');
    setFrom(newFrom.unix());
    setTo(newFrom.add(1, 'month').endOf('month').unix());
  };

  // Nav to prev month
  const prevMonth = () => {
    const newFrom = moment.unix(from).subtract(1, 'month');
    setFrom(newFrom.unix());
    setTo(newFrom.add(1, 'month').endOf('month').unix());
  };

  const getCellStatus = (day: moment.Moment): { mode: CellMode; start: boolean; end: boolean } => {
    const result = {
      mode: CellMode.NORMAL,
      start: false,
      end: false,
    };
    for (const range of ranges) {
      if (range.start?.isSame(day, 'day') && range.end?.isSame(day, 'day')) {
        result.mode = CellMode.POINT;
        result.start = true;
        result.end = true;
      } else if (range.start?.isSame(day, 'day')) {
        result.mode = CellMode.POINT;
        result.start = true;
      } else if (range.end?.isSame(day, 'day')) {
        result.mode = CellMode.POINT;
        result.end = true;
      } else if (range.end && day.isBetween(range.start, range.end)) {
        result.mode = CellMode.SELECTED;

        if (day.day() === 1) {
          result.start = true;
        } else if (day.day() === 0) {
          result.end = true;
        }

        if (day.isSame(moment(day).startOf('month'), 'day')) {
          result.start = true;
        } else if (day.isSame(moment(day).endOf('month'), 'day')) {
          result.end = true;
        }
      }
    }

    return result;
  };

  // Create rows (weeks) and cells (days) in them
  const getContent = () => {
    const currentMonth = moment.unix(from).endOf('month');
    // Real start (start of the week)
    const day = moment.unix(from).startOf('week');
    const content = [];
    let row = [];
    let rowIndex = 1;
    const toDate = moment.unix(to);
    while (day.isBefore(toDate)) {
      const sameMonth = day.format('YYYY-MM') === currentMonth.format('YYYY-MM');

      if (!sameMonth) {
        row.push(
          <Cell
            inCreate={inCreate}
            day={moment(day)}
            onClick={cellClick}
            empty={true}
            key={day.unix() + '-' + rowIndex}
          />
        );
      } else {
        const { mode, start, end } = getCellStatus(day);
        row.push(
          <Cell
            inCreate={inCreate}
            day={moment(day)}
            onClick={cellClick}
            key={day.unix() + '-' + rowIndex}
            mode={mode}
            start={start}
            end={end}
          />
        );
      }

      if (day.day() === 0 && day.isAfter(currentMonth)) {
        currentMonth.add(1, 'month');
      }

      day.add(1, 'day');

      if (day.day() === 1) {
        content.push(<Row key={day.year() + '-' + rowIndex}>{row}</Row>);
        row = [];
        rowIndex++;

        // If we finish a row with a day from another (next) month, we have to go back 1 week (:( bad design)
        if (!sameMonth) {
          day.subtract(7, 'day');
        }
      }
    }

    if (row.length > 0) {
      while (day.day() != 1) {
        row.push(
          <Cell
            inCreate={inCreate}
            day={moment(day)}
            onClick={cellClick}
            empty={true}
            key={day.unix() + '-' + rowIndex}
          />
        );
        day.add(1, 'day');
      }
      content.push(<Row key={day.year() + '-' + rowIndex}>{row}</Row>);
    }

    return content;
  };

  const getByPointDay = (day: moment.Moment): Daterange | undefined => {
    return ranges.find((r) => r.start?.isSame(day, 'day') || r.end?.isSame(day, 'day'));
  };

  const cellClick = (day: moment.Moment): void => {
    if (!inCreate) {
      // If clicks on start/end, remove the range
      const rangeToRemove = getByPointDay(day);
      if (rangeToRemove) {
        const newRanges = ranges.filter(
          (r) => !r.start?.isSame(rangeToRemove.start) && !r.end?.isSame(rangeToRemove.end)
        );
        setRanges(newRanges);
        // Else start range creation
      } else {
        const newRange = new Daterange('', moment(day));
        const newRanges = [...ranges, newRange];
        setRanges(newRanges);
        setInCreate(true);
      }
    } else {
      const newRange = ranges.find((r) => !r.end);
      if (newRange && day.isSameOrAfter(newRange.start, 'day')) {
        newRange.end = moment(day);
        const newRanges = optimizeRanges([...ranges, newRange]);
        setRanges(newRanges);
        setInCreate(false);
      }
    }
  };

  const optimizeRanges = (ranges: Daterange[]): Daterange[] => {
    const optimized: Daterange[] = [];
    ranges = ranges.sort((a: Daterange, b: Daterange) => (a.start?.isAfter(b.start) ? 1 : -1));

    let r = new Daterange();
    for (const range of ranges) {
      // first init
      if (!r.start) {
        r.id = range.id;
        r.start = range.start;
        r.end = range.end;
      }

      // End is in the next range, merge them
      if (r.end?.isBetween(range.start, range.end, 'day') || r.end?.isSame(range.start, 'day')) {
        r.end = range.end;
        if (!r.id && range.id) {
          r.id = range.id;
        }
      }

      // Start is after the current range, so it's a new range
      if (range.start?.isAfter(r.end, 'day')) {
        optimized.push(r);

        r = new Daterange(range.id, range.start, range.end);
      }
    }

    optimized.push(r);

    return optimized;
  };

  const content = getContent();

  return (
    <ModalWrapper style={{ zIndex: 10 }}>
      <Component ref={pickerRef}>
        <Header>
          <Title>{moment.unix(from).format('YYYY. MMMM') + ' - ' + moment.unix(to).format('MMMM')}</Title>
          <NavButtons>
            <IconButton
              onClick={prevMonth}
              size='xs'
              icon={faChevronLeft}
              color={theme.color.black}
              hoverColor={theme.color.primary}
            />
            <IconButton
              onClick={nextMonth}
              size='xs'
              icon={faChevronRight}
              color={theme.color.black}
              hoverColor={theme.color.primary}
            />
          </NavButtons>
        </Header>
        <Body>
          <HeaderRow>
            {days.map((d, i) => (
              <HeaderCell key={d + i}>{d}</HeaderCell>
            ))}
          </HeaderRow>
          <Rows>{content}</Rows>
        </Body>
        <Footer>
          <Button
            onClick={finish}
            color={theme.color.white}
            bgcolor={theme.color.primary}
            hoverColor={theme.color.secondary}
          >
            Rendben
          </Button>
        </Footer>
      </Component>
    </ModalWrapper>
  );
};

export default DatePicker;
