import React, { FC } from 'react';

import type Creative from '../models/Creative';

import useCreativeColumns from '../hooks/useCreativeColumns';

import FilterRow from '../components/Table/FilterRow';
import NewTable from '../components/Table/NewTable';
import { DropdownOption } from '../components/Editable/Dropdown';
import Actions from '../enums/DropdownActions';
import CampaignStatus from '../enums/CampaignStatus';

type CreativesTableProps = {
  campaignStatus: CampaignStatus | undefined;
  pageSize: number;
  creatives: Creative[];
  onlyCreativesWithErrors: boolean;
  handlePageSize: (size: number) => void;
  updateCreative: (creative: Creative) => void;
  openMatcherErrorModal: (creative: Creative) => void;
  handleActionSelected: (option: DropdownOption<Actions>, medium: Creative) => void;
  filterErrorCreatives: (val: boolean) => void;
};

const CreativesTable: FC<CreativesTableProps> = ({
  campaignStatus,
  pageSize,
  creatives,
  onlyCreativesWithErrors,
  handlePageSize,
  updateCreative,
  openMatcherErrorModal,
  handleActionSelected,
  filterErrorCreatives,
}): JSX.Element => {
  const columns = useCreativeColumns({
    campaignStatus,
    updateCreative,
    openMatcherErrorModal,
    handleActionSelected,
  });

  return (
    <NewTable<Creative>
      columns={columns}
      data={creatives}
      idKey={'id'}
      filterRow={
        <FilterRow
          onlyShowTheOnesWithError={onlyCreativesWithErrors}
          handleCheck={filterErrorCreatives}
          totalCount={creatives.length}
          pageSize={pageSize}
          name='kreatív'
          setPageSize={handlePageSize}
        />
      }
    />
  );
};

export default CreativesTable;
