import React, { FC, useState, useEffect } from 'react';

import useMatcherErrorColumns from '../../hooks/useMatcherErrorColumns';
import useAPI from '../../hooks/useAPI';

import type { MatcherErrorScreenshot } from '../Layout/MatcherErrorsModal';
import NewTable from './NewTable';
import Flex from '../Spacing/Flex';
import { H2 } from '../Typography';

type MatcherErrorsTableProps = {
  campaignId: string;
  mediumId: string | null;
  creativeId: string | null;
  mediumName: string;
  pageIndex: number;
  creativeSelection: (creativeId: string) => void;
  pageIndexChange: (nextPageNumber: number) => void;
  totalPageCount: (totalCount: number) => void;
};

const FixErrorsContainer: FC<MatcherErrorsTableProps> = ({
  campaignId,
  mediumId,
  creativeId,
  mediumName,
  pageIndex,
  creativeSelection,
  pageIndexChange,
  totalPageCount,
}): JSX.Element => {
  const [, getCampaignMatcherErrors] = useAPI(`/matchererrors/${campaignId}`, { manual: true });
  const [, updateScreenshots] = useAPI({ url: '/screenshots', method: 'PUT' }, { manual: true });

  const [screenshots, setScreenshots] = useState<MatcherErrorScreenshot[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleSetScreenshots = (screenshots: MatcherErrorScreenshot[]) => {
    setScreenshots(() => screenshots.filter((screenshot) => screenshot.creative !== null));
  };

  const fetchData = async (pageIndex: number): Promise<void> => {
    const campaignMatcherErrorsResponse = await getCampaignMatcherErrors({
      params: { offset: pageIndex, errorType: 'time', mediumId, creativeId },
    });
    if (!campaignMatcherErrorsResponse || !campaignMatcherErrorsResponse.data) return;

    const responseScreenshots = campaignMatcherErrorsResponse.data[0];
    const everythingSelectedAlready: boolean = responseScreenshots.every(
      (screenshot: MatcherErrorScreenshot) => screenshot.exclude === true
    );

    setSelectAll(everythingSelectedAlready);
    handleSetScreenshots(responseScreenshots);
    totalPageCount(campaignMatcherErrorsResponse.data[1]);
  };

  const excludeScreenshot = async (id: string) => {
    const updatedScreenshots = screenshots.map((screenshot) => {
      if (screenshot.id === id) {
        screenshot.exclude = !screenshot.exclude;
      }
      return screenshot;
    });

    await updateScreenshots({ data: updatedScreenshots });

    // Uncheck all if everythings already selected
    const everythingSelectedAlready: boolean = screenshots.every((screenshot) => screenshot.exclude === true);
    setSelectAll(everythingSelectedAlready);
    handleSetScreenshots(updatedScreenshots);
  };

  const excludeAllScreenshot = async () => {
    // Uncheck all if everythings already selected
    const everyMatchError = await getCampaignMatcherErrors({
      params: { errorType: 'time', mediumId, creativeId },
    });
    if (!everyMatchError || !everyMatchError.data) return;

    const everyScreenshot = everyMatchError.data[0];
    const everythingSelectedAlready: boolean = everyScreenshot.every(
      (screenshot: MatcherErrorScreenshot) => screenshot.exclude === true
    );

    const updatedScreenshots = everyScreenshot.map((screenshot: MatcherErrorScreenshot) => {
      screenshot.exclude = everythingSelectedAlready ? false : true;
      return screenshot;
    });

    setSelectAll(!everythingSelectedAlready);
    await updateScreenshots({ data: updatedScreenshots });
    await fetchData(pageIndex);
  };

  useEffect(() => {
    fetchData(pageIndex);
    pageIndexChange(pageIndex);
  }, [pageIndex]);

  const columnHeaders = useMatcherErrorColumns(
    selectAll,
    excludeAllScreenshot,
    mediumName,
    creativeSelection,
    excludeScreenshot
  );

  return (
    <>
      {screenshots.length > 0 ? (
        <NewTable columns={columnHeaders} data={screenshots} idKey={'id'} filterRow={<></>} />
      ) : (
        <Flex justify='center'>
          <H2>Nincs adat</H2>
        </Flex>
      )}
    </>
  );
};

export default FixErrorsContainer;
