enum Platform {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  BOTH = 'both',
}

export const PlatformNames = new Map<Platform, string>([
  [Platform.BOTH, 'Multiscreen'],
  [Platform.DESKTOP, 'Desktop'],
  [Platform.MOBILE, 'Mobil'],
]);

export default Platform;
