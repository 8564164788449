import { faArrowLeft, faArrowRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from '../../config/Theme';
import Entity from '../../interfaces/IEntity';
import { TableContext } from '../../utils/Types';
import PaginationButton from '../Button/PaginationButton';

enum Ellipsis {
  NONE,
  START,
  END,
  BOTH,
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: ${(props) => props.theme.space.small};
    width: ${(props) => props.theme.space.small};
    color: ${(props) => props.theme.color.gray3};
    margin: 0 calc(${(props) => props.theme.space.half} / 2);
  }
`;

type PaginationContainerProps<D> = {
  pageIndex?: number;
  setPageIndex?: (pageIndex: number) => void;
  pageCount?: number;
  context?: React.Context<TableContext<D>>;
};

function PaginationContainer<D extends Entity>({
  pageIndex,
  setPageIndex,
  pageCount,
}: PaginationContainerProps<D>): JSX.Element {
  if (!pageCount) return <></>;
  if (typeof pageIndex === 'undefined' || typeof setPageIndex === 'undefined') return <></>;

  // !IMPORTANT!
  // Index, as referred here, corresponds to the page index displayed on the UI, which is 1-based
  // Real pageIndex, which is stored in the table state, is 0-based!
  const index = pageIndex + 1;

  let pageStart = 0;
  let pageEnd = 0;
  let ellipsis: Ellipsis = Ellipsis.NONE;

  if (pageCount <= 5) {
    pageStart = 1;
    pageEnd = pageCount;
  } else {
    if (index <= 3) {
      pageStart = 1;
      pageEnd = 4;
      ellipsis = Ellipsis.END;
    } else if (index >= pageCount - 2) {
      pageStart = pageCount - 3;
      pageEnd = pageCount;
      ellipsis = Ellipsis.START;
    } else {
      pageStart = index - 1;
      pageEnd = index + 1;
      ellipsis = Ellipsis.BOTH;
    }
  }

  const visiblePages: number[] = [];
  for (pageStart; pageStart <= pageEnd; pageStart++) {
    visiblePages.push(pageStart);
  }

  const changePage = (page: number) => {
    if (page === index) return;
    setPageIndex(page - 1);
  };

  return (
    <Container>
      {/* Navigate to previous page */}
      <FontAwesomeIcon cursor='pointer' icon={faArrowLeft} onClick={(e) => setPageIndex(--pageIndex!)} />

      {/* Display first page if applicable */}
      {ellipsis == Ellipsis.START || ellipsis == Ellipsis.BOTH ? (
        <>
          <PaginationButton key={1} onClick={(e) => changePage(1)} isCurrentPage={false}>
            1
          </PaginationButton>
          <FontAwesomeIcon icon={faEllipsisH} onClick={(e) => changePage(1)} />
        </>
      ) : (
        ''
      )}

      {/* Display three pages, with the active one in the middle */}
      {visiblePages.map((page) => {
        return (
          <PaginationButton key={page} onClick={(e) => changePage(page)} isCurrentPage={page === index}>
            {page}
          </PaginationButton>
        );
      })}

      {/* Display last page if applicable */}
      {ellipsis == Ellipsis.END || ellipsis == Ellipsis.BOTH ? (
        <>
          <FontAwesomeIcon icon={faEllipsisH} />
          <PaginationButton key={pageCount} onClick={(e) => changePage(pageCount)} isCurrentPage={false}>
            {pageCount}
          </PaginationButton>
        </>
      ) : (
        ''
      )}

      {/* Navigate to next page */}
      <FontAwesomeIcon cursor='pointer' icon={faArrowRight} onClick={(e) => setPageIndex(++pageIndex!)} />
    </Container>
  );
}

export default PaginationContainer;
