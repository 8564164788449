import React from 'react';
import { styled } from '../../config/Theme';
import Column from './Column';
import TableBody from './TableBody';
import TableHead from './TableHead';

const Table = styled.table`
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.white};
  width: 100%;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  border: none;
  border-spacing: none;
  border-collapse: collapse;
`;

type TableProps<T> = {
  columns: Column<T>[];
  onRowClick?: (row: T) => void;
  filterRow: JSX.Element;
  idKey: keyof T;
  data: T[];
  cursor?: string;
};

function NewTable<T>({ columns, onRowClick, filterRow, idKey, data, cursor }: TableProps<T>) {
  return (
    <Table>
      <TableHead columns={columns} filterRow={filterRow} />
      <TableBody columns={columns} rows={data} idKey={idKey} onRowClick={onRowClick} cursor={cursor} />
    </Table>
  );
}

export default NewTable;
